<template>
	<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption d-flex justify-space-between"
				 style="height: fit-content; width: 100%">
		<span>{{ `${label}${required ? "*" : ""}` }}</span>
	</v-subheader>
</template>

<script>
export default {
	props: {
		label: String,
		required: Boolean
	}
};
</script>
