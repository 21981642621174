<template>
	<v-progress-circular v-if="loading" style="margin-left: 18px" size="16" width="2" class="my-3 ml-0" color="primary" indeterminate/>
	<div v-else-if="name || meta.namespace">
		<v-subheader v-if="label" class="pa-0 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<div class="d-flex">
			<div @click.stop="download" :style="{ cursor: id ? 'pointer' : 'default' }" class="d-flex" :class="`py-${py}`">
				<frp-icon src="ico_bod-file" :color="colors.primary.darken1" class="mr-3"></frp-icon>
				<span v-if="!noText" class="text-body-2" style="color: var(--v-primary-darken1); max-width: 530px">
					{{ name || $t(`aliases.documents.${meta.namespace}`) }}
				</span>
			</div>

			<v-spacer></v-spacer>
			<v-btn v-if="deletable && !uploading" icon color="primary" class="ml-3" @click="$emit('delete', name || id)">
				<frp-icon src="ico_bod-delete" :color="colors.primary.base"></frp-icon>
			</v-btn>
			<v-progress-circular v-if="uploading" style="margin-left: 18px" size="16" width="2" color="primary" indeterminate/>
			<div v-if="$slots['append-outer']" :class="$vuetify.breakpoint.lgAndUp ? 'ml-11' : 'ml-6'">
				<slot name="append-outer"></slot>
			</div>
		</div>
	</div>
</template>

<script>
import { BodController } from "Api/bod";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import { saveAs } from "file-saver";
import AbortService from "Services/abortService";
import AlertHelper from "Store/modules/alerts/helpers/alertHelper";

const abortService = new AbortService();
const bodController = new BodController(abortService);

export default {
	mixins: [colorsMixin],
	props: {
		deletable: Boolean,
		id: String,
		name: String,
		label: String,
		uploading: Boolean,
		noText: Boolean,
		required: Boolean,
		py: {
			type: [String, Number],
			default: 2
		}
	},
	data() {
		return {
			meta: {},
			loading: false
		};
	},
	methods: {
		async download() {
			if(!this.id) return;

			try {
				const file = await bodController.getFile(this.id);
				saveAs(file, this.name);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	},
	created() {
		abortService.initialize();
	},
	components: {
		FrpIcon
	}
};
</script>

<style scoped>

</style>
