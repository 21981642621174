import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "employee-promises";

export const getterTypes = {
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	fetch: "fetch"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	SET_BITRIX_PROMISES: "SET_BITRIX_PROMISES"
};

const employeePromisesTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default employeePromisesTypes;
