import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { ApiHrGetEmployeesParameters } from "@/api/hr/types/apiHrGetEmployeesParameters";
import { ApiHrEmployees } from "@/api/hr/types/apiHrEmployees";
import { ApiHrStandard } from "@/api/hr/types/apiHrStandard";
import { ApiHrVacation } from "@/api/hr/types/apiHrVacation";
import { ApiHrDepartmentsItem } from "@/api/hr/types/apiHrDepartmentsItem";
import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";
import { mocks } from "@/api/client/mock";
import { ApiHrDiscipline } from "@/api/hr/types/apiHrDiscipline";
import { ApiHrFeedback } from "@/api/hr/types/apiHrFeedback";
import { ApiHrPromise } from "@/api/hr/types/apiHrPromise";

export class HrController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getEmployees = async (parameters: ApiHrGetEmployeesParameters) => {
		return await this.client.get<ApiHrEmployees>(prepareUrl(urls.hr.employee.getEmployees, parameters));
	};
	
	getEmployeeProfile = async (id: string) => {
		return await this.client.get<ApiHrEmployee>(urls.hr.employee.getProfile.replace(urlTemplateParts.id, id));
	};
	
	getBitrixPromises = async (id: string) => {
		return await this.client.get<ApiHrPromise>(urls.hr.employee.getBitrixPromises.replace(urlTemplateParts.id, id));
	};
	
	getEmployeeStandard = async (id: string) => {
		// return mocks.standardAwareness;
		return await this.client.get<ApiHrStandard>(urls.hr.employee.getStandard.replace(urlTemplateParts.id, id));
	};
	
	getEmployeeDiscipline = async (id: string) => {
		return await this.client.get<ApiHrDiscipline>(urls.hr.employee.getDiscipline.replace(urlTemplateParts.id, id));
	};
	
	getEmployeePromise = async (id: string) => {
		// return await this.client.get<ApiHrPromise>(urls.hr.employee.getPromise.replace(urlTemplateParts.id, id));
	};
	
	getEmployeeExpectation = async (id: string) => {
		// return await this.client.get<ApiHrExpectation>(urls.hr.employee.getExpectation.replace(urlTemplateParts.id, id));
	};
	
	getEmployeeFeedback = async (id: string) => {
		return mocks.feedback;
		// return await this.client.get<ApiHrFeedback>(urls.hr.employee.getFeedback.replace(urlTemplateParts.id, id));
	};
	
	getEmployeeVacation = async (id: string) => {
		return await this.client.get<ApiHrVacation>(urls.hr.employee.getVacation.replace(urlTemplateParts.id, id));
	};
	
	getColleagues = async (id: string) => {
		return await this.client.get<ApiHrEmployee[]>(urls.hr.employee.getColleagues.replace(urlTemplateParts.id, id));
	};
	
	getSubordinates = async (id: string) => {
		return await this.client.get<ApiHrEmployee[]>(urls.hr.employee.getSubordinates.replace(urlTemplateParts.id, id));
	};
	
	getDepartments = async () => {
		// return mocks.departments;
		return await this.client.get<ApiHrDepartmentsItem[]>(urls.hr.department.getDepartments);
	};
	
	getPermissions = async (id: string) => {
		return [];
	};
	
	getEmployeePermissions = async (employeeId: string) => {
		return await this.client.get<string[]>(prepareUrl(urls.hr.employee.getPermissions, { employeeId }).replace(urlTemplateParts.id,
			employeeId));
	};
	
	getCurrentUser = async () => {
		return await this.client.get<ApiHrEmployee>(urls.hr.user.getCurrentUser);
	};
}
