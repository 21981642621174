export default class BodAcceptorBase {
	title: string;
	type: string;
	acceptedAt: number;
	declinedAt: number;
	declineReason: string;

	constructor(
		title: string = "",
		type: string = "",
		acceptedAt: number = 0,
		declinedAt: number = 0,
		declineReason: string = ""
	)
	{
		this.title = title;
		this.type = type;
		this.acceptedAt = acceptedAt;
		this.declinedAt = declinedAt;
		this.declineReason = declineReason;
	}
}
