import { parse } from "date-fns";
import i18n from "@/plugins/i18n";
import { checkINN, checkSnils, checkOgrn, checkOgrnip } from "ru-validation-codes";
import isKpp from "is-kpp-js";
import { isDateWithSecondsFormatValid } from "@/utils/formats";
import { formatDate } from "@/utils/dates";
import validator from "is-my-date-valid";
import {
	validateBankAccountNumber, validateBankIdentificationCode, validateCorrespondentAccount,
	validateLegalEntityMaskedInn,
	validateMaskedKpp,
	validateMaskedOgrn,
	validateMaskedOgrnip,
	validatePersonMaskedInn
} from "@/utils/validator";
import { isPhoneValid } from "@/utils/phone";
import PasswordValidator from "password-validator";
import { checkSnilsFormat } from "@/utils/checkSnilsFormat";

const passwordValidator = new PasswordValidator();
passwordValidator.is().min(8, "Не менее 8 символов").is().max(100, "Не более 100 символов");

const validateDateTime = validator({ format: isDateWithSecondsFormatValid });

export const requiredRule = () => {
	return (v: string) => v !== "" && !/^[\s]+$/.test(v) && !!v || i18n.t("validation.required");
};

export const requiredAutocompleteValueRule = (requiredKey: string) => {
	return (v: any) => v !== null && !!v[requiredKey] || i18n.t("validation.required");
};

export const requiredMultipleAutocompleteRule = () => {
	return (v: any) => v !== null && !!v.length || i18n.t("validation.required");
};

export const prepareInnRule = () => {
	return (v: any) => !v || checkINN(v) || i18n.t("validation.inn");
};

export const prepareLegalEntityInnRule = () => {
	return (v: string | any[]) => !v || (checkINN(v) && v.length === 10) || i18n.t("validation.inn");
};

export const prepareLegalEntityMaskedInnValidationRule = () => {
	return (v: string) => !v || validateLegalEntityMaskedInn(v) || i18n.t("validation.inn");
};

export const preparePersonMaskedInnValidationRule = () => {
	return (v: string) => !v || validatePersonMaskedInn(v) || i18n.t("validation.inn");
};

export const prepareMaskedKppValidationRule = () => {
	return (v: string) => !v || validateMaskedKpp(v) || i18n.t("validation.kpp");
};

export const prepareSnilsRule = () => {
	return (v: any) => !v || checkSnils(v) || i18n.t("validation.snils");
};

export const checkSnilsFormatRule = () => {
	return (v: any) => !v || checkSnilsFormat(v) || i18n.t("validation.snilsFormat");
};

export const prepareOgrnRule = () => {
	return (v: any) => !v || checkOgrn(v) || i18n.t("validation.ogrn");
};

export const prepareMaskedOgrnValidationRule = () => {
	return (v: string) => !v || validateMaskedOgrn(v) || i18n.t("validation.ogrn");
};

export const prepareMaskedOgrnipValidationRule = () => {
	return (v: string) => !v || validateMaskedOgrnip(v) || i18n.t("validation.ogrnip");
};

export const prepareOgrnipRule = () => {
	return (v: any) => !v || checkOgrnip(v) || i18n.t("validation.ogrnip");
};

export const prepareKppRule = () => {
	return (v: any) => !v || isKpp(v) || i18n.t("validation.kpp");
};

export const prepareMaxLengthRule = ({ maxLength }: { maxLength: number }) => {
	return (v: string | any[]) => !v || v.length <= maxLength || `${i18n.t("validation.maxLength", { number: maxLength })}`;
};

export const prepareMinLengthRule = ({ minLength }: { minLength: number }) => {
	return (v: string | any[]) => !v || v.length >= minLength || `${i18n.t("validation.minLength", { number: minLength })}`;
};

export const validDate = () => {
	return (v: string) => !v || validateDateTime(v) || i18n.t("validation.invalidDate");
};

export const prepareMaxNumbersRule = ({ getMax }: { getMax: any }) => {
	return (v: string | number) => v === "" || v <= getMax() || i18n.t("validation.maxNumber", { number: getMax() });
};

export const prepareMinNumbersRule = ({ getMin }: { getMin: any }) => {
	return (v: string | number) => v === "" || v >= getMin() || i18n.t("validation.minNumber", { number: getMin() });
};


export const prepareMaxDateRule = ({ getMax, format }: { getMax: any, format: string }) => {
	return (v: string) => !getMax() || !v || parse(v, format, new Date()) <= getMax() ||
		i18n.t("validation.maxDate", { number: formatDate(getMax(), format) });
};

export const prepareMinDateRule = ({ getMin, format }: { getMin: any, format: string }) => {
	return (v: string) => !getMin() || !v || parse(v, format, new Date()) >= getMin() ||
		i18n.t("validation.minDate", { number: formatDate(getMin(), format) });
};


export const prepareEmailRule = () => {
	return (v: string) => !v || /.+@.+\..+/i.test(v) || i18n.t("validation.validEmail");
};

export const prepareUniqRule = ({ getValue, message }: { getValue: any, message: string }) => {
	return (v: any) => !v || getValue() !== v || message;
};

export const prepareConfirmedEmailRule = ({ getEmail }: { getEmail: any }) => {
	return (v: any) => !v || getEmail() === v || i18n.t("validation.validConfirmedEmail");
};

export const preparePasswordRule = () => {
	return (v: string) => {
		let result = passwordValidator.validate(v, { details: true }) as { message: string }[];
		return result.length ? result[0].message : true;
	};
};

export const prepareConfirmedPasswordRule = (getPassword: Function) => {
	return (v: string) => !v || !getPassword() || getPassword() === v || "Пароли не совпадают";
};

export const onlyIntegerNumbersRule = () => {
	return (v: string) => !v || /^[-\d]+$/.test(v) || i18n.t("validation.validOnlyIntegerNumbers");
};

export const onlyNumbersRule = () => {
	return (v: string) => !v || /^[-\d\.]+$/.test(v) || i18n.t("validation.validOnlyNumbers");
};

export const phoneNumberRule = ({ getCountry }: { getCountry: any }) => {
	return (v: string) => !v || (isPhoneValid({ value: v, country: getCountry() }) && /^[-\d\-\s+]+$/.test(v)) || i18n.t("validation.validPhone");
};

export const onlyLatinRule = () => {
	return (v: string) => !v || /^[a-zA-Z\s]+$/.test(v) || i18n.t("validation.validOnlyLatin");
};

/* Временные проверки */
export const passwordRule = () => {
	return (v: string | string[]) => !v || v.includes("test") || i18n.t("validation.validPassword");
};

export const checkReportingUrl = () => {
	const regexReporting = new RegExp('^\/projects\/project\/[0-9]{1,}\/reporting\/');
	return regexReporting.test(location.pathname);
}

export const checkReportingFinalUrl = () => {
	const regexReportingFinal = new RegExp('^\/projects\/project\/[0-9]{1,}\/reportingfinal');
	return regexReportingFinal.test(location.pathname);
}

export const prepareBankAccountRule = () => {
	return (v: string | any[]) => !v || v.length === 20 || i18n.t("validation.bankAccount");
};

export const prepareBankAccountNumberValidationRule = (getBik: () => string) => {
	return (v: string) => !v || validateBankAccountNumber(v, getBik()) || i18n.t("validation.bankAccountNumber");
};

export const prepareBankIdentificationCodeValidationRule = () => {
	return (v: string) => !v || validateBankIdentificationCode(v) || i18n.t("validation.bankIdentificationCode");
};

export const prepareCorrespondentAccountValidationRule = (getBik: () => string) => {
	return (v: string) => !v || validateCorrespondentAccount(v, getBik()) || i18n.t("validation.correspondentAccount");
};

export const maxLengths = {
	extensionPhoneNumber: 6,
	bankAccountNumber: 20,
	bik: 9,
	search: 50,
	short: 50,
	middle: 100,
	upperMiddle: 250,
	long: 500,
	text: 1000
};
