<template>
	<div class="d-flex align-center">
		<frp-icon class="mr-1" :src="icon" :color="color"></frp-icon>
		<span>{{ $t(`aliases.changeDocumentType.${value}`) }}</span>
	</div>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import { ChangeType } from "Store/bod/modules/requests/types/changeType";

export default {
	components: { FrpIcon },
	mixins: [colorsMixin],
	props: {
		value: {
			type: String,
			required: true
		}
	},
	computed: {
		color() {
			switch (this.value) {
				case ChangeType.Create:
				case ChangeType.Edit:
					return this.colors.blue.base;
				case ChangeType.UnPublish:
					return this.colors.secondary.lighten2;
				case ChangeType.Rollback:
					return this.colors.warning.darken1;
			}
		},
		icon() {
			switch (this.value) {
				case ChangeType.Create:
					return "ico_document-create";
				case ChangeType.Edit:
					return "ico_document-update";
				case ChangeType.UnPublish:
					return "ico_document-unpublish";
				case ChangeType.Rollback:
					return "ico_document-rollback";
			}
		}
	}
};
</script>
