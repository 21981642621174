import "reflect-metadata";
import Vue from "vue";
import App from "@/BodApp.vue";
import router from "@/router/bod";
import store from "@/store/bod";
import { i18n, vuetify } from "@/plugins";
import { sync } from "vuex-router-sync";
import "@/assets/main.css";
import configureApi from "@/api/index";

configureApi();

sync(store, router);
Vue.config.productionTip = false;

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount("#app");
