export default class ApiCreateRollbackDocumentRequestRequest {
	responsibleUserId: string;
	description: string;

	constructor(
		responsibleUserId: string = "",
		description: string = ""
	)
	{
		this.responsibleUserId = responsibleUserId;
		this.description = description;
	}
}
