import { format, addMinutes, parse, parseISO, min, max, isThisQuarter, addYears } from "date-fns";
import { ru } from "date-fns/locale";
import {
	dateFormat,
	dateTimeFormat,
	friendlyDateFormat,
	dayOfWeek,
	timeFormat,
	friendlyDateFormatWithoutYear,
	isoDate, isoDateFormat
} from "@/utils/formats";
import { capitalizeFirstLetter } from "@/utils/formatting";
import { utcToZonedTime } from "date-fns-tz";
import Quarter from "@/types/loan/quarter";

export const convertIsoToNumber = (value: any) => {
	if(!value) return value;
	
	return parseISO(value).getTime();
};

export const formatDateInterval = (start: Date | number, end: Date | number) => {
	return `с ${formatDate(start, dateFormat)} по ${formatDate(end, dateFormat)}`;
};

export const formatDate = (date: Date | number, dateFormat: string) => {
	return date && format(date, dateFormat, { locale: ru });
};

export const eachDateOfInterval = ({ start, end, interval }: { start: any, end: any, interval: number }) => {
	const dates = [];
	
	let currentDate = new Date(start);
	let endTime = new Date(end).getTime();
	
	while (currentDate.getTime() <= endTime) {
		dates.push(currentDate);
		currentDate = addMinutes(currentDate, interval);
	}
	return dates;
};

export const parseDateToTime = (str: string, format: string) => {
	return parse(str, format, new Date()).getTime();
};


export const formatInterval = (start: Date | number, end: Date | number) => {
	let timeInterval = `${formatDate(start, timeFormat)} - ${formatDate(end, timeFormat)}`;
	return `${formatDate(start, dateFormat)} ${timeInterval}`;
};

export const parseInterval = (strInterval: string) => {
	let segments = strInterval.split(" ");
	let [date, startTime] = segments;
	let [endTime] = segments.reverse();
	
	return {
		start: parseDateToTime(`${date} ${startTime}`, dateTimeFormat),
		end: parseDateToTime(`${date} ${endTime}`, dateTimeFormat)
	};
};

export const formatIntervalWeek = (start: Date | number, end: Date | number) => {
	return `${formatDate(start, friendlyDateFormatWithoutYear)} - ${formatDate(end, friendlyDateFormat)}`;
};

export const formatDateToDayOfWeek = (date: Date | number) => {
	return date && capitalizeFirstLetter(format(date, dayOfWeek, { locale: ru }));
};

export const removeTime = (date: any) => {
	return new Date(date.setHours(0, 0, 0, 0));
};

export const findFirstAndLastDates = (dates: (number | Date)[]) => {
	const firstDate = min(dates);
	const lastDate = max(dates);
	
	return [firstDate, lastDate];
};

export const convertToZonedIso = (date?: Date | number, offset: string = timezoneOffsets.DEFAULT) => {
	if(!date)
		return "";
	
	return formatDate(date, isoDate) + offset;
};

export const convertToZonedTimestamp = (date?: string, timezone: string = timezoneNames.GMT) => {
	if(!date)
		return date;
	
	return utcToZonedTime(date, timezone).getTime();
};

export const convertToTimestamp = (date?: string) => {
	if(!date)
		return date;
	
	return parseISO(date).getTime();
};

export const parseDate = (date: string, format: string = isoDateFormat) => {
	if(!date)
		return 0;
	
	return parse(date, format, 0);
};

export const limitQuartersByMaxYear = (quarters: Quarter[], maxYear: number): Quarter[] => {
	const currentQuarter = quarters.find(x => isThisQuarter(x.startDate));
	const maxQuarterDate = addYears(currentQuarter!.startDate, maxYear);
	
	return quarters.filter(x => x.startDate <= maxQuarterDate.getTime());
};

export const enum timezoneNames {
	GMT = "Etc/GMT",
	MOSCOW = "Europe/Moscow"
}

export const enum timezoneOffsets {
	DEFAULT = "+00:00",
	MOSCOW = "+03:00"
}
