<template>
	<v-chip :color="backgroundColor" :style="`color: ${color}`" class="bod-chip">
		<slot></slot>
	</v-chip>
</template>

<script>
export default {
	props: {
		color: String,
		backgroundColor: String
	}
};
</script>

<style scoped>
</style>
