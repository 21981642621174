import DocumentsState from "@/store/bod/modules/documents/types/documentsState";
import ApiGetDocumentsParameters from "@/api/bod/types/apiGetDocumentsParameters";

import { addProfile, createMap, createMapper, forMember, ignore, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToZonedIso, convertToTimestamp, parseDate } from "@/utils/dates";
import DocumentListItem from "@/store/bod/modules/documents/types/documentListItem";
import ApiDocumentListItem from "@/api/bod/types/apiDocumentListItem";

const mapper = createMapper({
	strategyInitializer: classes()
});

const documentsProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiDocumentListItem, DocumentListItem,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.createdAt, mapFrom(x => convertToTimestamp(x.createdAt))),
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.section, mapFrom(x => x.section)),
		forMember(d => d.financeSchemes, mapFrom(x => x.financeSchemes)),
		forMember(d => d.responsibleDepartment, mapFrom(x => x.responsibleDepartment)),
		forMember(d => d.file, mapFrom(x => x.file)),
		forMember(d => d.status, mapFrom(x => x.status)),
		forMember(d => d.linkedToFinanceSchemes, mapFrom(x => x.linkedToFinanceSchemes))
	);

	createMap(mapper, DocumentsState, ApiGetDocumentsParameters,
		forMember(d => d.take, mapFrom(x => x.paging.pageSize)),
		forMember(d => d.skip, mapFrom(x => x.paging.pageSize * (x.paging.page - 1))),
		forMember(d => d.text, mapFrom(x => x.filter.text)),
		forMember(d => d.financeSchemeIds, mapFrom(x => x.filter.financeSchemeIds)),
		forMember(d => d.responsibleDepartmentIds, mapFrom(x => x.filter.departmentIds)),
		forMember(d => d.responsibleUserIds, mapFrom(x => x.filter.responsibleUserIds)),
		forMember(d => d.sectionIds, mapFrom(x => x.filter.sectionIds)),
		forMember(d => d.initiatorIds, mapFrom(x => x.filter.initiatorIds)),
		forMember(d => d.activeOnly, mapFrom(x => x.filter.activeOnly)),
		forMember(d => d.isWeb, mapFrom(x => x.filter.isWeb))
	);
};

addProfile(mapper, documentsProfile);

export default mapper;

