<template>
	<bod-content-layout :title="$t('titles.publicDocuments')">
		<template #title-append>
			<frp-btn :to="{ name: RouteNames.DOCUMENT_CREATE }" color="blue" dark elevation="0">
				{{ $t("buttons.addDocument") }}
			</frp-btn>
		</template>

		<v-card elevation="0"
				class="loan-card px-6 py-9"
				style="border-bottom-left-radius: 0; border-bottom-right-radius: 0">
			<div class="d-flex flex-wrap" style="gap: 16px">
				<frp-text-field hide-details
								style="width: 240px"
								v-model="internalFilterValues.text"
								:placeholder="$t('fields.documentSearch.placeholder')">
				</frp-text-field>

				<bod-autocomplete item-text="title"
								  return-object
								  @firstclick="fetchFinanceSchemes"
								  hide-details
								  multiple
								  :loading="isFinanceSchemesLoading"
								  style="width: 240px"
								  :items="financeSchemes"
								  v-model="filterFinanceSchemeIds"
								  color="blue"
								  :placeholder="$t('fields.financeSchemesSearch.placeholder')">
				</bod-autocomplete>

				<bod-autocomplete item-text="title"
								  return-object
								  @firstclick="fetchDepartments"
								  multiple
								  :loading="isDepartmentsLoading"
								  style="width: 240px"
								  :items="departments"
								  v-model="filterDepartmentIds"
								  color="blue"
								  :placeholder="$t('fields.departmentSearch.placeholder')"
								  hide-details>
				</bod-autocomplete>

				<bod-autocomplete item-text="title"
								  return-object
								  @firstclick="fetchSections"
								  multiple
								  :loading="isSectionsLoading"
								  style="width: 240px"
								  :items="sections"
								  v-model="filterSectionIds"
								  color="blue"
								  :placeholder="$t('fields.documentSectionSearch.placeholder')"
								  hide-details>
				</bod-autocomplete>

				<bod-autocomplete item-text="fullName"
								  return-object
								  @firstclick="fetchResponsibleUsers"
								  multiple
								  :loading="isUsersLoading"
								  style="width: 240px"
								  :items="users"
								  v-model="filterResponsibleUserIds"
								  color="blue"
								  :placeholder="$t('fields.supervisorSearch.placeholder')"
								  hide-details>
				</bod-autocomplete>

				<frp-checkbox class="mb-0 mt-1"
							  color="blue"
							  v-model="internalFilterValues.activeOnly"
							  label-class="grey--text pl-1"
							  :text="$t('fields.activeOnly.label')">
				</frp-checkbox>

				<frp-checkbox class="mb-0 mt-1"
							  color="blue"
							  v-model="internalFilterValues.isWeb"
							  label-class="grey--text pl-1"
							  :text="$t('fields.isWeb.label')">
				</frp-checkbox>
			</div>

			<div class="d-flex flex-wrap justify-end mt-4" style="gap: 12px">
				<frp-btn @click="resetFilter"
						 :disabled="isFilterEmpty"
						 color="primary"
						 outlined
						 elevation="0">
					{{ $t("buttons.clearFilter") }}
				</frp-btn>

				<frp-btn @click="applyFilter"
						 :disabled="!isFilterChanged"
						 no-margin
						 color="blue"
						 dark
						 elevation="0">
					{{ $t("buttons.search") }}
				</frp-btn>
			</div>
		</v-card>

		<v-card elevation="0"
				class="loan-card pa-0"
				style="border-top-left-radius: 0; border-top-right-radius: 0;">
			<v-row>
				<v-col class="pb-0" style="border-top: 1px solid var(--v-grey-lighten4)">
					<v-data-table :headers="headers"
								  :loading-text="$t('tables.loading')"
								  :loading="isItemsLoading"
								  hide-default-footer
								  item-key="id"
								  :items="formattedItems"
								  :items-per-page="-1"
								  :mobile-breakpoint="mobileBreakpoint"
								  :options="options"
								  class="loan-table bod-table clickable-rows d-flex flex-column">
						<template #item="{ item }">
							<tr class="text-body-2"
								@click="openDocument(item, $event)"
								@mouseup.middle.prevent="openDocumentInNewTab(item.id)"
								@mousedown.middle.prevent>
								<td v-for="column in headers">
									<template v-if="column.value === 'file'">
										<div v-if="item.file && item.file.id" class="d-flex flex-column">
											<bod-file style="width: 100%"
													  class="text-break"
													  :name="item.file.title"
													  :id="item.file.id">
											</bod-file>
											<span class="grey--text text-body-2">{{ filesize(item.file.size) }}</span>
										</div>
									</template>
									
									<template v-else-if="column.value === 'share'">
										<frp-btn v-if="item.status === DocumentStatusTypeEnum.Published" @click.stop="copyLink(item.id)" color="blue" icon>
											<frp-icon :color="colors.blue.base" src="ico_copy"></frp-icon>
										</frp-btn>
									</template>

									<span v-else>{{ item[column.value] }}</span>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card>

		<template #footer>
			<bod-footer>
				<frp-pagination v-if="!isItemsLoading"
								v-model="page"
								active-color="blue"
								:length="Math.ceil(paging.total / paging.pageSize)">
				</frp-pagination>
			</bod-footer>
		</template>
	</bod-content-layout>
</template>

<script>
import BodFile from "Components/common/BodFile";
import FrpPagination from "Components/common/FrpPagination";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import FrpIcon from "Components/icon/FrpIcon";
import { assign, cloneDeep, isEqual } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import { RouteNames } from "Router/bod/routes";
import { DocumentStatusTypeEnum } from "Store/bod/modules/document/types/documentStatusTypeEnum";
import { namespace } from "Store/bod/modules/documents";
import { actionTypes, getterTypes, mutationTypes } from "Store/bod/modules/documents/types";
import DocumentsFilter from "Store/bod/modules/documents/types/documentsFilter";
import { openRouteInNewTab } from "Utils/router";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { filesize } from "filesize";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpAlerts from "Components/alerts/FrpAlerts";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";
import BodContentLayout from "Components/layouts/BodContentLayout";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	data() {
		return {
			DocumentStatusTypeEnum,
			filesize,
			RouteNames,
			namespace,
			headers: [
				{
					text: this.$t("tables.documentSection"),
					value: "section",
					class: "text-caption",
					sortable: false,
					width: "17%"
				},
				{
					text: this.$t("tables.documentTitle"),
					value: "title",
					class: "text-caption",
					sortable: false,
					width: "17%"
				},
				{
					text: this.$t("tables.financingScheme"),
					value: "financeSchemes",
					class: "text-caption",
					sortable: false,
					width: "17%"
				},
				{
					text: this.$t("tables.responsibleDepartment"),
					value: "department",
					class: "text-caption",
					sortable: false,
					width: "19%"
				},
				{
					text: this.$t("tables.documentOnDisk"),
					value: "file",
					class: "text-caption",
					sortable: false,
					width: "17%"
				},
				{
					text: this.$t("tables.share"),
					value: "share",
					class: "text-caption",
					sortable: false,
					width: "15%"
				}
			],
			internalFilterValues: {
				text: "",
				financeSchemeIds: [],
				departmentIds: [],
				responsibleUserIds: [],
				sectionIds: [],
				initiatorIds: [],
				activeOnly: false,
				isWeb: false
			}
		};
	},
	computed: {
		...mapState({
			isFinanceSchemesLoading: state => state.isFinanceSchemesLoading,
			isSectionsLoading: state => state.isSectionsLoading,
			isUsersLoading: state => state.isUsersLoading,
			isDepartmentsLoading: state => state.isDepartmentsLoading,
			departments: state => state.departments,
			users: state => state.users,
			sections: state => state.sections,
			financeSchemes: state => state.financeSchemes
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty
		}),
		// В шаблоне используются объекты, в стейте нужны id
		filterFinanceSchemeIds: {
			get() {
				return this.internalFilterValues.financeSchemeIds.map(x => this.financeSchemes.find(y => y.id === x));
			},
			set(value)
			{
				if(value)
					this.internalFilterValues.financeSchemeIds = value.map(x => x.id);
			}
		},
		filterDepartmentIds: {
			get() {
				return this.internalFilterValues.departmentIds.map(x => this.departments.find(y => y.id === x));
			},
			set(value)
			{
				if(value)
					this.internalFilterValues.departmentIds = value.map(x => x.id);
			}
		},
		filterResponsibleUserIds: {
			get() {
				return this.internalFilterValues.responsibleUserIds.map(x => this.users.find(y => y.id === x));
			},
			set(value)
			{
				if(value)
					this.internalFilterValues.responsibleUserIds = value.map(x => x.id);
			}
		},
		filterSectionIds: {
			get() {
				return this.internalFilterValues.sectionIds.map(x => this.sections.find(y => y.id === x));
			},
			set(value)
			{
				if(value)
					this.internalFilterValues.sectionIds = value.map(x => x.id);
			}
		},
		filter() {
			return {
				text: this.internalFilterValues.text,
				financeSchemeIds: this.internalFilterValues.financeSchemeIds,
				departmentIds: this.internalFilterValues.departmentIds,
				responsibleUserIds: this.internalFilterValues.responsibleUserIds,
				sectionIds: this.internalFilterValues.sectionIds,
				initiatorIds: this.internalFilterValues.initiatorIds,
				activeOnly: this.internalFilterValues.activeOnly,
				isWeb: this.internalFilterValues.isWeb
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? [] : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return isEqual(new DocumentsFilter(), this.filterValues);
		}
	},
	methods: {
		...mapMutations({
			setFilterText: mutationTypes.SET_FILTER_TEXT,
			setFilterFinanceSchemeIds: mutationTypes.SET_FILTER_FINANCE_SCHEMES_IDS,
			setFilterDepartmentIds: mutationTypes.SET_FILTER_DEPARTMENT_IDS,
			setFilterResponsibleUserIds: mutationTypes.SET_FILTER_RESPONSIBLE_USER_IDS,
			setFilterSectionIds: mutationTypes.SET_FILTER_SECTION_IDS,
			setFilterInitiatorIds: mutationTypes.SET_FILTER_INITIATOR_IDS,
			setFilterActiveOnly: mutationTypes.SET_FILTER_ACTIVE_ONLY,
			setFilterIsWeb: mutationTypes.SET_FILTER_IS_WEB,
			resetFilter: mutationTypes.RESET_FILTER
		}),
		...mapActions({
			copyLink: actionTypes.copyLink,
			fetchResponsibleUsers: actionTypes.fetchResponsibleUsers,
			fetchDepartments: actionTypes.fetchDepartments,
			fetchFinanceSchemes: actionTypes.fetchFinanceSchemes,
			fetchSections: actionTypes.fetchSections
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		applyFilter() {
			this.setFilterText(this.internalFilterValues.text);
			this.setFilterFinanceSchemeIds(this.internalFilterValues.financeSchemeIds || []);
			this.setFilterDepartmentIds(this.internalFilterValues.departmentIds || []);
			this.setFilterResponsibleUserIds(this.internalFilterValues.responsibleUserIds || []);
			this.setFilterSectionIds(this.internalFilterValues.sectionIds || []);
			this.setFilterInitiatorIds(this.internalFilterValues.initiatorIds);
			this.setFilterActiveOnly(this.internalFilterValues.activeOnly);
			this.setFilterIsWeb(this.internalFilterValues.isWeb);
		},
		async openDocument({ id }, { ctrlKey }) {
			if(ctrlKey)
				this.openDocumentInNewTab(id);
			else
				await this.$router.push({ name: RouteNames.DOCUMENT, params: { documentId: id } });
		},
		openDocumentInNewTab(id) {
			openRouteInNewTab(this.$router, { name: RouteNames.DOCUMENT, params: { documentId: id } });
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		BodAutocomplete,
		BodFile,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		FrpAutocomplete,
		BodMain,
		FrpAlerts,
		FrpAlert,
		BodFooter,
		BodContentLayout
	}
};
</script>
