export default class ApiCreateDocumentChangeRequestRequest {
	responsibleUserId: string;
	editableFileId: string;
	nonEditableFileId: string;
	changeWarrant: string;
	changeWarrantFileId: string;
	description: string;

	constructor(
		responsibleUserId: string = "",
		editableFileId: string = "",
		nonEditableFileId: string = "",
		changeWarrant: string = "",
		changeWarrantFileId: string = "",
		description: string = ""
	)
	{
		this.responsibleUserId = responsibleUserId;
		this.editableFileId = editableFileId;
		this.nonEditableFileId = nonEditableFileId;
		this.changeWarrant = changeWarrant;
		this.changeWarrantFileId = changeWarrantFileId;
		this.description = description;
	}
}
