export default class ApiUpdateRequest {
	changeWarrant: string;
	description: string;
	responsibleUserId: string;
	webLink: string;

	constructor(
		changeWarrant: string = "",
		description: string = "",
		responsibleUserId: string = "",
		webLink: string = ""
	)
	{
		this.changeWarrant = changeWarrant;
		this.description = description;
		this.responsibleUserId = responsibleUserId;
		this.webLink = webLink;
	}
}
