export default class ApiGetChangeRequestsParameters {
	take: number;
	skip: number;
	text: string;
	sectionIds: string[];
	initiatorIds: string[];
	statuses: string[];

	constructor(
		take: number,
		skip: number,
		text: string,
		sectionIds: string[],
		initiatorIds: string[],
		statuses: string[]
	)
	{
		this.take = take;
		this.skip = skip;
		this.text = text;
		this.sectionIds = sectionIds;
		this.initiatorIds = initiatorIds;
		this.statuses = statuses;
	}
}
