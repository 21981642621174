import { render, staticRenderFns } from "./BodFileField.vue?vue&type=template&id=0ca8b265"
import script from "./BodFileField.vue?vue&type=script&lang=js"
export * from "./BodFileField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports