import { formatFullNameWithInitials } from "@/utils/formatting";
import ApiBodUser from "@/api/bod/types/apiBodUser";

// Добавление поля fullName в объект user
// Если в users есть пользователи с такими же ФИО, то к ФИО добавляется email
export const getUserWithUniqueFullName = (user: ApiBodUser, users: ApiBodUser[]) => {
	return {
		...user,
		fullName: users.some(y => formatFullNameWithInitials(user) === formatFullNameWithInitials(y) && user.id !== y.id) ?
			`${formatFullNameWithInitials(user)} (${user.email})` :
			formatFullNameWithInitials(user)
	};
};
