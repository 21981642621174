import SubscribersManager from "@/store/manager/subscribersManager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/bod/modules/requests/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import { Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { BodController } from "@/api/bod";
import { RouteNames } from "@/router/bod/routes";
import router from "@/router/bod";
import routeTypes from "@/store/shared/route/types";
import mapper from "@/store/bod/modules/requests/mapper";
import RequestsState from "@/store/bod/modules/requests/types/requestsState";
import ApiGetChangeRequestsParameters from "@/api/bod/types/apiGetChangeRequestsParameters";
import ApiChangeRequestsListItem from "@/api/bod/types/apiChangeRequestsListItem";
import ChangeRequestsListItem from "@/store/bod/modules/requests/types/changeRequestsListItem";
import RequestsFilter from "@/store/bod/modules/requests/types/requestsFilter";
import RequestsRouteQuery from "@/store/bod/modules/requests/types/requestsRouteQuery";
import RequestsRouteQueryService from "@/store/bod/modules/requests/services/requestsRouteQueryService";
import { formatFullNameWithInitials } from "@/utils/formatting";
import { plainToInstance } from "class-transformer";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import { getUserWithUniqueFullName } from "@/store/bod/modules/document/helpers/getUserWithUniqueFullName";
import { ChangeRequestStatusType } from "@/store/bod/modules/document/types/changeRequestStatusType";
import { cloneDeep } from "lodash";

const abortService = new AbortService();
const bodController = new BodController(abortService);

const defaultRouteQuery = new RequestsRouteQuery(1, "", [], [], [ChangeRequestStatusType.Accepted]);

export const defaultFilter = plainToInstance(RequestsFilter, { statuses: [ChangeRequestStatusType.Accepted] });

const routeQueryService = new RequestsRouteQueryService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<RequestsState>()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new RequestsState(
			new ListingModel<ChangeRequestsListItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "createdAt",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: defaultRouteQuery.page,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			new RequestsFilter(),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<RequestsState>;

const getters = <GetterTree<RequestsState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			let item = {
				...x,
				section: x.section.title,
				initiator: formatFullNameWithInitials(x.initiator)
			};

			return item;
		});
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<RequestsState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<RequestsState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_TEXT):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_SECTION_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_INITIATOR_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_STATUSES):
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
			await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
	}
};

const actions = <ActionTree<RequestsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);

		await dispatch(actionTypes.updateListingItems);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			let { items, totalCount } = await bodController.getChangeRequests(mapper.map(state,
				RequestsState,
				ApiGetChangeRequestsParameters));

			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.map(x, ApiChangeRequestsListItem, ChangeRequestsListItem)));
			commit(mutationTypes.SET_PAGING_TOTAL, totalCount);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit, dispatch }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);

		if(routeQuery.initiatorIds.length)
			await dispatch(actionTypes.fetchInitiatorUsers);
		if(routeQuery.sectionIds.length)
			await dispatch(actionTypes.fetchSections);

		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_FILTER_TEXT, routeQuery.text);
		commit(mutationTypes.SET_FILTER_SECTION_IDS, routeQuery.sectionIds);
		commit(mutationTypes.SET_FILTER_INITIATOR_IDS, routeQuery.initiatorIds);
		commit(mutationTypes.SET_FILTER_STATUSES, routeQuery.statuses);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.REQUESTS,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.REQUESTS,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchInitiatorUsers]({ commit, state }) {
		if(state.users.length > 0)
			return;

		commit(mutationTypes.SET_IS_USERS_LOADING, true);

		try {
			const users = await bodController.getInitiatorUsers();

			commit(mutationTypes.SET_USERS, users.map(x => plainToInstance(ApiBodUser, getUserWithUniqueFullName(x, users))));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_USERS_LOADING, false);
		}
	},
	async [actionTypes.fetchSections]({ commit, state }) {
		if(state.sections.length > 0)
			return;

		commit(mutationTypes.SET_IS_SECTIONS_LOADING, true);

		try {
			const sections = await bodController.getSections();

			commit(mutationTypes.SET_SECTIONS, sections);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_SECTIONS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<RequestsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_FILTER_TEXT](state, value) {
		state.filter.text = value;
	},
	[mutationTypes.SET_FILTER_SECTION_IDS](state, value) {
		state.filter.sectionIds = value;
	},
	[mutationTypes.SET_FILTER_INITIATOR_IDS](state, value) {
		state.filter.initiatorIds = value;
	},
	[mutationTypes.SET_FILTER_STATUSES](state, value) {
		state.filter.statuses = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = cloneDeep(defaultFilter);
	},
	[mutationTypes.SET_IS_USERS_LOADING](state, value) {
		state.isUsersLoading = value;
	},
	[mutationTypes.SET_IS_SECTIONS_LOADING](state, value) {
		state.isSectionsLoading = value;
	},
	[mutationTypes.SET_SECTIONS](state, value) {
		state.sections = value;
	},
	[mutationTypes.SET_USERS](state, value) {
		state.users = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const requestsModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default requestsModule;
