import ApiBodUser from "@/api/bod/types/apiBodUser";

export default class UpdateRequest {
	changeWarrant: string;
	description: string;
	responsibleUser: ApiBodUser;
	webLink: string;

	constructor(
		changeWarrant: string = "",
		description: string = "",
		responsibleUser: ApiBodUser = new ApiBodUser(),
		webLink: string = ""
	)
	{
		this.changeWarrant = changeWarrant;
		this.description = description;
		this.responsibleUser = responsibleUser;
		this.webLink = webLink;
	}
}
