import { jsonEqual } from "Utils/jsonEqual";

const getObjectsDiff = (obj1, obj2) => {
	let diffs = {};
	let key;

	const arraysMatch = (arr1, arr2) => {
		if(arr1.length !== arr2.length) return false;
		for (let i = 0; i < arr1.length; i++) {
			if(!jsonEqual(arr1[i], arr2[i])) return false;
		}
		return true;
	};


	const compare = (item1, item2, key) => {
		const type1 = Object.prototype.toString.call(item1);
		const type2 = Object.prototype.toString.call(item2);

		if(type1 !== type2) {
			diffs[key] = item2;
			return;
		}

		if(type1 === "[object Object]") {
			if(!jsonEqual(item1, item2))
				diffs[key] = item2;

			return;
		}

		if(type1 === "[object Array]") {
			if(!arraysMatch(item1, item2)) {
				diffs[key] = item2;
			}
			return;
		}

		if(item1 !== item2) {
			diffs[key] = item2;
		}
	};

	for (key in obj1) {
		if(obj1.hasOwnProperty(key)) {
			compare(obj1[key], obj2[key], key);
		}
	}

	for (key in obj2) {
		if(obj2.hasOwnProperty(key)) {
			if(!obj1[key] && obj1[key] !== obj2[key]) {
				diffs[key] = obj2[key];
			}
		}
	}

	return diffs;
};

export default getObjectsDiff;
