export default class ApiCreateDocumentUnpublishRequestRequest {
	responsibleUserId: string;
	changeWarrant: string;
	description: string;

	constructor(
		responsibleUserId: string = "",
		changeWarrant: string = "",
		description: string = ""
	)
	{
		this.responsibleUserId = responsibleUserId;
		this.changeWarrant = changeWarrant;
		this.description = description;
	}
}
