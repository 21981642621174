import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "employee";

export const getterTypes = {
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	fetchDepartments: "fetchDepartments",
	fetchPermissions: "fetchPermissions"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	SET_IS_DEPARTMENTS_LOADING: "SET_IS_DEPARTMENTS_LOADING",
	SET_IS_PERMISSIONS_LOADING: "SET_IS_PERMISSIONS_LOADING",
	SET_PERMISSIONS: "SET_PERMISSIONS",
	SET_DEPARTMENTS: "SET_DEPARTMENTS"
};

const employeeTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default employeeTypes;
