<template>
	<div class="d-flex flex-column" style="height: 100%; width: 100%">
		<bod-main>
			<div class="d-flex align-center mb-3">
				<span class="bod-title">{{ title }}</span>
				<v-spacer></v-spacer>
				<slot name="title-append"></slot>
			</div>

			<slot></slot>
		</bod-main>

		<slot name="footer">
			<bod-footer></bod-footer>
		</slot>
	</div>
</template>

<script>
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";

export default {
	components: { BodMain, BodFooter },
	props: {
		title: String
	}
};
</script>

<style scoped lang="scss">
.bod-title {
	font-size: 18px;
	font-family: "Ubuntu", sans-serif;
}
</style>
