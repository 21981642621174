<template>
	<v-snackbar v-model="isShown"
				elevation="0"
				:color="backgroundColor"
				:timeout="timeout"
				:absolute="absolute"
				:right="right">
		<div class="d-flex align-center white--text">
			<frp-icon v-if="icon" :src="icon" :color="colors.white.base" class="mr-3"></frp-icon>
			{{ text }}
		</div>
		<template v-slot:action="{ attrs }">
			<frp-btn icon
					 v-if="closable"
					 v-bind="attrs"
					 @click="close">
				<template>
					<v-icon :color="colors.white.base">mdi-close</v-icon>
				</template>
			</frp-btn>
		</template>
	</v-snackbar>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpIcon from "Components/icon/FrpIcon";
import alertMixin from "Mixins/alertMixin";
import colorsMixin from "Mixins/colorsMixin";


export default {
	mixins: [colorsMixin, alertMixin],
	props: {
		text: String,
		timeout: Number,
		closable: {
			default: true
		},
		absolute: {
			default: false
		},
		right: {
			type: Boolean,
			default: false
		},
		type: {
			type: String
		},
		icon: String
	},
	data() {
		return {
			isShown: true
		};
	},
	watch: {
		isShown(newValue) {
			if(!newValue) {
				this.close();
			}
		}
	},
	methods: {
		close() {
			this.$emit("close");
		}
	},
	components: {
		FrpBtn,
		FrpIcon
	}
};
</script>

<style lang="less">
.v-snack {
	z-index: 9999 !important;
}

.v-snack__wrapper {
	min-width: unset;
}
</style>
