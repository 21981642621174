import { ApiHrPeriod } from "@/api/hr/types/apiHrPeriod";
import { ApiHrStandard } from "@/api/hr/types/apiHrStandard";
import { ApiHrDiscipline } from "@/api/hr/types/apiHrDiscipline";
import { ApiHrFeedback } from "@/api/hr/types/apiHrFeedback";
import { ApiHrFeedbackGroup } from "@/api/hr/types/apiHrFeedbackGroup";
import { ApiHrDepartmentsItem } from "@/api/hr/types/apiHrDepartmentsItem";

export const mocks = {
	standardAwareness: {
		period: { year: 2023, quarter: 3 } as ApiHrPeriod,
		correctAnswersRate: 78,
		rewardRate: 4
	} as ApiHrStandard,
	discipline: {
		period: { year: 2023, quarter: 3 } as ApiHrPeriod,
		totalCount: 78,
		overdueCount: 4,
		discipline: 80
	} as ApiHrDiscipline,
	feedback: {
		period: { year: 2023, quarter: 3 } as ApiHrPeriod,
		groups: [
			{
				title: "Самооценка",
				color: "#F3923E44",
				values: [
					{ label: "Желание развиваться", value: 64 },
					{ label: "Целеустремленность", value: 87 },
					{ label: "Интерес к работе", value: 87 },
					{ label: "Инициативность", value: 65 },
					{ label: "Межличностное понимание", value: 87 },
					{ label: "Самоорганизация", value: 77 },
					{ label: "Командная работа", value: 87 }
				]
			},
			{
				title: "Коллеги",
				color: "#3E83f344",
				values: [
					{ label: "Желание развиваться", value: 77 },
					{ label: "Целеустремленность", value: 77 },
					{ label: "Интерес к работе", value: 77 },
					{ label: "Инициативность", value: 77 },
					{ label: "Межличностное понимание", value: 77 },
					{ label: "Самоорганизация", value: 77 },
					{ label: "Командная работа", value: 77 }
				]
			},
			{
				title: "Подчиненные",
				color: "#0A8F1C44",
				values: [
					{ label: "Желание развиваться", value: 99 },
					{ label: "Целеустремленность", value: 99 },
					{ label: "Интерес к работе", value: 76 },
					{ label: "Инициативность", value: 99 },
					{ label: "Межличностное понимание", value: 86 },
					{ label: "Самоорганизация", value: 99 },
					{ label: "Командная работа", value: 52 }
				]
			}
		] as ApiHrFeedbackGroup[]
	} as ApiHrFeedback,
	departments: [
		{
			id: "1",
			name: "Отдел 1",
			departments: [
				{
					id: "2",
					name: "Отдел 2",
					parentId: "1",
					departments: [
						{
							id: "7",
							name: "Отдел 7",
							parentId: "2",
							departments: [
								{
									id: "9",
									name: "Отдел 9",
									parentId: "7"
								},
								{
									id: "10",
									name: "Отдел 10",
									parentId: "7"
								}
							]
						},
						{
							id: "8",
							name: "Отдел 8",
							parentId: "2"
						}
					]
				},
				{
					id: "3",
					name: "Отдел 3",
					parentId: "1"
				}
			]
		},
		{
			id: "4",
			name: "Отдел 4",
			departments: [
				{
					id: "5",
					name: "Отдел 5",
					parentId: "4"
				},
				{
					id: "6",
					name: "Отдел 6",
					parentId: "4"
				}
			]
		}
	] as ApiHrDepartmentsItem[]
};
