import { IsString, IsInt, Min, Max, Length, IsArray, IsBoolean } from "class-validator";

export default class DocumentsRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;

	@IsString()
	@Length(0, 100)
	text: string;

	@IsArray()
	financeSchemeIds: string[];

	@IsArray()
	responsibleUserIds: string[];

	@IsArray()
	departmentIds: string[];

	@IsArray()
	sectionIds: string[];

	@IsArray()
	initiatorIds: string[];

	@IsBoolean()
	activeOnly: boolean;

	@IsBoolean()
	isWeb: boolean;

	constructor(
		page: number,
		text: string,
		financeSchemeIds: string[],
		departmentIds: string[],
		responsibleUserIds: string[],
		sectionIds: string[],
		initiatorIds: string[],
		activeOnly: boolean,
		isWeb: boolean
	)
	{
		this.page = page;
		this.text = text;
		this.financeSchemeIds = financeSchemeIds;
		this.departmentIds = departmentIds;
		this.responsibleUserIds = responsibleUserIds;
		this.sectionIds = sectionIds;
		this.initiatorIds = initiatorIds;
		this.activeOnly = activeOnly;
		this.isWeb = isWeb;
	}
}
