import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { ApiProjectInfo } from "@/api/tranches/types/apiProjectInfo";
import { ApiTrancheSchedule } from "@/api/tranches/types/apiTrancheSchedule";
import { ApiTrancheScheduleTranche } from "@/api/tranches/types/apiTrancheScheduleTranche";
import { ApiTrancheOrderCondition } from "@/api/tranches/types/apiTrancheOrderCondition";
import { FileTypes } from "@/constants/fileTypes";
import NotDefinedException from "@/exceptions/notDefinedException";
import contentDispositionParser from "content-disposition-parser";

export class TranchesController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getPermissions = async (): Promise<string[]> => {
		return await this.client.get<string[]>(urls.tranches.permissions.get);
	};
	
	getProjectInfo = async (applicationNumber: string): Promise<ApiProjectInfo> => {
		const url = urls.tranches.project.getProjectInfo.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.get<ApiProjectInfo>(url, {});
	};
	
	getTrancheSchedule = async (applicationNumber: string): Promise<ApiTrancheSchedule> => {
		const url = urls.tranches.trancheSchedule.getTrancheSchedule.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.get<ApiTrancheSchedule>(url, {});
	};
	
	updateTrancheSchedule = async (applicationNumber: string, tranches: ApiTrancheScheduleTranche[]): Promise<void> => {
		const url = urls.tranches.trancheSchedule.updateTrancheSchedule.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.post(url, tranches);
	};
	
	updateTrancheScheduleOrderConditions = async (applicationNumber: string, orderConditions: ApiTrancheOrderCondition[]): Promise<void> => {
		const url = urls.tranches.trancheSchedule.updateTrancheScheduleOrderConditions.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.post(url, orderConditions);
	};
	
	sendTrancheScheduleForApproveByManager = async (applicationNumber: string): Promise<void> => {
		const url = urls.tranches.trancheSchedule.sendTrancheScheduleForApproveByManager.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.post(url, {});
	};
	
	withdrawTrancheSchedule = async (applicationNumber: string): Promise<void> => {
		const url = urls.tranches.trancheSchedule.withdrawTrancheSchedule.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.post(url, {});
	};
	
	rejectTrancheSchedule = async (applicationNumber: string): Promise<void> => {
		const url = urls.tranches.trancheSchedule.rejectTrancheSchedule.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.post(url, {});
	};
	
	acceptTrancheSchedule = async (applicationNumber: string): Promise<void> => {
		const url = urls.tranches.trancheSchedule.acceptTrancheSchedule.replace(urlTemplateParts.id, applicationNumber);
		return await this.client.post(url, {});
	};
	
	orderTranchePaymentApplication = async (applicationNumber: string, trancheNumber: string): Promise<void> => {
		const url = urls.tranches.trancheSchedule.orderTranchePaymentApplication.replace(urlTemplateParts.id, applicationNumber).replace(urlTemplateParts.subId, trancheNumber);
		return await this.client.post(url, {});
	};
	
	generateTrancheSchedule = async (applicationNumber: string) => {
		const url = urls.tranches.trancheSchedule.generateInformationalLetter.replace(urlTemplateParts.id, applicationNumber);
		const { data, responseHeaders } = await this.client.get(url, { responseType: "blob" }, true);
		
		if(!data || !data.size || data?.type !== FileTypes.DOCX)
			throw new NotDefinedException(data);
		
		const { "content-disposition": contentDisposition } = responseHeaders;
		const { filename } = contentDispositionParser(contentDisposition);
		
		return { data, filename };
	};
	
	generateTranchePaymentApplication = async (applicationNumber: string, trancheNumber: string) => {
		const url = urls.tranches.trancheSchedule.generatePaymentRequest.replace(urlTemplateParts.id, applicationNumber).replace(urlTemplateParts.subId, trancheNumber);
		const { data, responseHeaders } = await this.client.get(url, { responseType: "blob" }, true);
		
		if(!data || !data.size || data?.type !== FileTypes.DOCX)
			throw new NotDefinedException(data);
		
		const { "content-disposition": contentDisposition } = responseHeaders;
		const { filename } = contentDispositionParser(contentDisposition);
		
		return { data, filename };
	};
}
