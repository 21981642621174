import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "bank-account-application-documents";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	selectableDocumentStatuses: "selectableDocumentStatuses"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	getDocuments: "getDocuments",
	setStatusForDocument: "setStatusForDocument",
	setAcceptedStatusForBankAccountApplicationDocument: "setAcceptedStatusForBankAccountApplicationDocument",
	setDeclinedStatusForBankAccountApplicationDocument: "setDeclinedStatusForBankAccountApplicationDocument",
	setNotRequiredStatusForBankAccountApplicationDocument: "setNotRequiredStatusForBankAccountApplicationDocument",
	uploadDocumentFileInfo: "uploadDocumentFileInfo",
	downloadFile: "downloadFile"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_IS_DOCUMENTS_LOADING: "SET_IS_DOCUMENTS_LOADING",
	SET_DOCUMENT_FILE_INFO_ITEMS: "SET_DOCUMENT_FILE_INFO_ITEMS",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_STATUS: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_STATUS",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_STORED_FILE_ID: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_STORED_FILE_ID",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_EXPLANATION: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_EXPLANATION",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_STATUS_CHANGING: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_STATUS_CHANGING",
	RESET_DECLINE_DOCUMENT_STATUS_EXPLANATION: "RESET_DECLINE_DOCUMENT_STATUS_EXPLANATION",
	SET_DECLINE_DOCUMENT_STATUS_EXPLANATION: "SET_DECLINE_DOCUMENT_STATUS_EXPLANATION",
	RESET_DOCUMENT_FILE_META: "RESET_DOCUMENT_FILE_META",
	SET_DOCUMENT_FILE_META_IS_LOADING: "SET_DOCUMENT_FILE_META_IS_LOADING",
	SET_DOCUMENT_FILE_META: "SET_DOCUMENT_FILE_META",
	SET_IS_SIGNED_DOCUMENT_FILE_INFO_UPLOADING: "SET_IS_SIGNED_DOCUMENT_FILE_INFO_UPLOADING",
	SET_IS_SIGNED: "SET_IS_SIGNED"
};

const bankAccountApplicationDocumentsTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default bankAccountApplicationDocumentsTypes;
