import ApiBodUser from "@/api/bod/types/apiBodUser";
import { Type } from "class-transformer";
import BodAcceptorBase from "@/store/bod/modules/document/types/bodAcceptorBase";

export default class BodPersonAcceptor extends BodAcceptorBase {
	@Type(() => ApiBodUser)
	user: ApiBodUser;

	constructor(
		title: string = "",
		type: string = "",
		acceptedAt: number = 0,
		declinedAt: number = 0,
		declineReason: string = "",
		user: ApiBodUser = new ApiBodUser()
	)
	{
		super(title, type, acceptedAt, declinedAt, declineReason);

		this.user = user;
	}
}
