<template>
	<bod-content-layout :title="title">
		<v-card elevation="0" class="loan-card pa-7 pb-6 my-4">
			<v-form :readonly="isDocumentFormSaving || isFormReadonly"
					:disabled="isFormDisabled"
					v-if="isInitialized"
					ref="documentForm"
					v-model="isDocumentFormValid">
				<frp-form-card-blocks>
					<bod-form-card-block>
						<template #title>
							<div class="d-flex flex-column">
								<div style="max-width: 950px">
									<span v-if="isCreateDocumentMode">{{ $t("titles.newDocument") }}</span>
									<span v-else>{{ document.name }}</span>
								</div>

								<div v-if="isAcceptRequestMode || isCreateRequestMode" class="d-flex align-center">
									<frp-btn plain
											 style="padding: 0 !important"
											 text
											 :to="{ name: RouteNames.DOCUMENT, params: { documentId: document.id } }"
											 class="text-none"
											 :color="colors.blue.base">
										<span style="font-size: 20px" class="font-weight-regular">{{ $t("buttons.goTo") }}</span>
										<frp-icon class="ml-2 mt-1" src="ico_open-in-new" :color="colors.blue.base"></frp-icon>
									</frp-btn>
								</div>
							</div>
						</template>

						<template v-if="!isCreateDocumentMode" #side-content>
							<bod-chip v-if="document.status === DocumentStatusTypeEnum.UnPublished && !isAcceptRequestMode"
									  :background-color="colors.secondary.lighten4"
									  :color="colors.secondary.lighten2">
								{{ $t("common.unPublished") }}
							</bod-chip>

							<div v-else class="d-flex justify-start align-center flex-wrap">
								<frp-btn @click="copyLink"
										 v-if="document.status === DocumentStatusTypeEnum.Published"
										 style="padding: 0 !important"
										 class="mr-4 elevation-0"
										 text
										 plain
										 color="primary">
									<span class="text-none font-weight-regular" style="font-size: 20px">
										{{ $t("buttons.shareDocument") }}</span>
									<frp-icon class="ml-4" src="ico_copy" :color="colors.blue.base"></frp-icon>
								</frp-btn>

								<div v-if="!isCreateRequestMode && !isAcceptRequestMode"
									 class="d-flex justify-start align-center flex-wrap"
									 style="column-gap: 16px; row-gap: 12px">
									<frp-btn :block="$vuetify.breakpoint.xsOnly"
											 v-if="documentPermissions.includes(DocumentPermissions.CREATE_ROLLBACK_REQUEST)"
											 class="elevation-0"
											 color="primary"
											 :to="{ name: RouteNames.DOCUMENT_ROLLBACK, params: { documentId: $route.params.documentId } }"
											 outlined>
										{{ $t("buttons.rollback") }}
									</frp-btn>
									<frp-btn :block="$vuetify.breakpoint.xsOnly"
											 v-if="documentPermissions.includes(DocumentPermissions.CREATE_UNPUBLISH_REQUEST)"
											 class="elevation-0"
											 no-margin
											 :to="{ name: RouteNames.DOCUMENT_UNPUBLISH, params: { documentId: $route.params.documentId } }"
											 outlined>
										{{ $t("buttons.unpublish") }}
									</frp-btn>
									<frp-btn :block="$vuetify.breakpoint.xsOnly"
											 v-if="documentPermissions.includes(DocumentPermissions.CREATE_CHANGE_REQUEST)"
											 class="elevation-0"
											 no-margin
											 :color="colors.blue.base"
											 :to="{ name: RouteNames.DOCUMENT_UPDATE, params: { documentId: $route.params.documentId } }">
										<span class="white--text">{{ $t("buttons.createChangeRequest") }}</span>
									</frp-btn>
								</div>
							</div>
						</template>

						<bod-form-subtitle>{{ $t("subheaders.mainInfo") }}</bod-form-subtitle>
						<frp-form-card-block-row>
							<frp-form-card-block-col cols="12" sm="6" md="5" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
								<frp-text-field v-if="!isCreateDocumentMode"
												:label="$t('fields.createdDate.label')"
												:value="formatDate(document.createdAt, friendlyDateFormatWithSeconds)"
												disabled>
								</frp-text-field>

								<frp-textarea v-else
											  :label="$t('fields.documentTitle.label')"
											  required
											  v-model="name"
											  :placeholder="$t('fields.documentTitle.placeholder')">
								</frp-textarea>
							</frp-form-card-block-col>

							<frp-form-card-block-col offset-md="1" cols="12" sm="6" md="5" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
								<bod-autocomplete :label="$t('fields.documentSection.label')"
												  required
												  return-object
												  @firstclick="fetchSections"
												  :loading="isSectionsLoading"
												  color="blue"
												  item-text="title"
												  :disabled="!isUpdateDocumentEnabled || (!isCreateDocumentMode && !documentPermissions.includes(DocumentPermissions.EDIT_SECTION))"
												  v-model="documentSection"
												  :items="sections"
												  :placeholder="$t('fields.documentSection.placeholder')">
								</bod-autocomplete>
							</frp-form-card-block-col>
						</frp-form-card-block-row>

						<bod-form-subtitle>{{ $t("subheaders.additionalInfo") }}</bod-form-subtitle>

						<frp-form-card-block-row>
							<frp-form-card-block-col cols="12" sm="6" md="5" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
								<frp-checkbox class="mb-4 mt-0"
											  color="blue"
											  :disabled="!isUpdateDocumentEnabled || (!isCreateDocumentMode && !documentPermissions.includes(DocumentPermissions.EDIT_FINANCE_SCHEMES))"
											  v-model="documentLinkedToFinanceSchemes"
											  label-class="grey--text pl-1"
											  :text="$t('fields.linkedToFinanceSchemes.label')">
								</frp-checkbox>

								<bod-autocomplete :label="$t('fields.financingScheme.label')"
												  return-object
												  color="blue"
												  item-text="title"
												  @firstclick="fetchFinanceSchemes"
												  :loading="isFinanceSchemesLoading"
												  multiple
												  :disabled="!isUpdateDocumentEnabled || !documentLinkedToFinanceSchemes || (!isCreateDocumentMode && !documentPermissions.includes(DocumentPermissions.EDIT_FINANCE_SCHEMES))"
												  v-model="documentFinanceSchemes"
												  :items="financeSchemes"
												  :placeholder="$t('fields.financingScheme.placeholder')">
								</bod-autocomplete>

								<bod-autocomplete :label="$t('fields.department.label')"
												  color="blue"
												  item-text="title"
												  @firstclick="fetchDepartments"
												  :loading="isDepartmentsLoading"
												  required
												  return-object
												  :disabled="!isUpdateDocumentEnabled || (!isCreateDocumentMode && !documentPermissions.includes(DocumentPermissions.EDIT_RESPONSIBLE_DEPARTMENT))"
												  v-model="documentResponsibleDepartment"
												  :items="departments"
												  :placeholder="$t('fields.department.placeholder')">
								</bod-autocomplete>
								
								<frp-text-field :label="$t('fields.documentChangeWarrant.label')"
												v-if="isCreateDocumentMode"
												required
												v-model="documentChangeWarrant"
												:placeholder="$t('fields.documentChangeWarrant.placeholder')">
								</frp-text-field>
								
								<bod-file-field :label="$t('fields.changeWarrantFile.label')"
												ref="changeWarrantFile"
												clearable
												:meta="documentChangeWarrantFileMeta"
												v-if="isCreateDocumentMode"
												@change="handleAddDocumentChangeWarrantFile"
												:placeholder="$t('fields.changeWarrantFile.placeholder')">
								</bod-file-field>
							</frp-form-card-block-col>

							<frp-form-card-block-col offset-md="1" cols="12" sm="6" md="5" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
								<div class="d-flex align-center mb-4 flex-wrap" style="row-gap: 4px">
									<frp-checkbox class="mt-0"
												  color="blue"
												  :disabled="!isUpdateDocumentEnabled || (!isCreateDocumentMode && !documentPermissions.includes(DocumentPermissions.EDIT_IS_WEB))"
												  v-model="documentIsWeb"
												  label-class="grey--text pl-1 mr-2"
												  :text="$t('fields.isWeb.label')">
									</frp-checkbox>
									<a class="mt-1 bod-link" :href="documentRequirementsLink" target="_blank">
										({{ $t("buttons.gotToDocumentRequirements") }})
									</a>
								</div>

								<frp-text-field :label="$t('fields.webLink.label')"
												:required="documentIsWeb && (isCreateDocumentMode || documentPermissions.includes(DocumentPermissions.EDIT_WEB_LINK))"
												:disabled="!isCreateDocumentMode && !documentPermissions.includes(DocumentPermissions.EDIT_WEB_LINK)"
												:placeholder="$t('fields.webLink.placeholder')"
												v-model="documentWebLink">
								</frp-text-field>

								<bod-autocomplete :label="$t('fields.supervisor.label')"
												  color="blue"
												  item-text="fullName"
												  @firstclick="handleFetchUsers"
												  required
												  return-object
												  :loading="isUsersLoading"
												  :disabled="!isUpdateDocumentEnabled || (!isCreateDocumentMode && !documentPermissions.includes(DocumentPermissions.EDIT_RESPONSIBLE_USER))"
												  v-model="documentResponsibleUser"
												  :items="users"
												  :placeholder="$t('fields.supervisor.placeholder')">
								</bod-autocomplete>
							</frp-form-card-block-col>
						</frp-form-card-block-row>

						<bod-form-subtitle v-if="isCreateDocumentMode || document.editableFile?.id || document.nonEditableFile?.id">
							{{ $t("subheaders.document") }}
						</bod-form-subtitle>

						<frp-form-card-block-row v-if="isCreateDocumentMode">
							<frp-form-card-block-col cols="12" sm="6" md="5" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
								<bod-dropzone max-size="25"
											  @file:add="handleAddDocumentFile({ isEditable: true, value: $event })"
											  @file:delete="setDocumentEditableFile(new FileMeta())"
											  ref="documentEditableFile"
											  class="bod-dropzone"
											  :label="$t('fields.documentEditableFile.label')"
											  type="editable">
								</bod-dropzone>
							</frp-form-card-block-col>

							<frp-form-card-block-col offset-md="1" cols="12" sm="6" md="5" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
								<bod-dropzone max-size="25"
											  @file:add="handleAddDocumentFile({ isEditable: false, value: $event })"
											  @file:delete="setDocumentNonEditableFile(new FileMeta())"
											  :file="nonEditableFile"
											  ref="documentNonEditableFile"
											  class="bod-dropzone"
											  :formats="['pdf']"
											  :label="$t('fields.documentPdfFile.label')"
											  type="pdf">
								</bod-dropzone>
							</frp-form-card-block-col>
						</frp-form-card-block-row>

						<frp-form-card-block-row v-else-if="document.editableFile?.id || document.nonEditableFile?.id">
							<frp-form-card-block-col v-if="document.editableFile?.id"
													 cols="12"
													 sm="6"
													 md="5"
													 :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
								<bod-file style="width: 100%"
										  class="text-break"
										  :label="$t('fields.documentEditableFile.label')"
										  :name="document.editableFile.title"
										  :id="document.editableFile.id">
								</bod-file>
							</frp-form-card-block-col>

							<frp-form-card-block-col v-if="document.nonEditableFile?.id"
													 :offset-md="document.editableFile?.id ? 1 : 0"
													 cols="12"
													 sm="6"
													 md="5"
													 :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
								<bod-file style="width: 100%"
										  class="text-break"
										  :label="$t('fields.documentPdfFile.label')"
										  :name="document.nonEditableFile.title"
										  :id="document.nonEditableFile.id">
								</bod-file>
							</frp-form-card-block-col>
						</frp-form-card-block-row>
					</bod-form-card-block>
				</frp-form-card-blocks>

				<div class="d-flex mt-6 mx-n3" v-if="isUpdateDocumentEnabled">
					<v-spacer></v-spacer>

					<frp-btn elevation="0"
							 @click="isDocumentUnsavedChangesDialogEnabled = true"
							 :disabled="!isStateContainsUnsavedChanges(DocumentSnapshotKeys.DOCUMENT)">
						{{ $t("buttons.cancel") }}
					</frp-btn>

					<frp-btn elevation="0"
							 :loading="isDocumentFormSaving || isFormLoadingState"
							 :disabled="!isStateContainsUnsavedChanges(DocumentSnapshotKeys.DOCUMENT) || !isDocumentFormValid || (!editableFile.id && !nonEditableFile.id)"
							 dark
							 @click="handleDocumentSubmit"
							 color="blue">
						<span v-if="isCreateDocumentMode">{{ $t("buttons.sendNewDocumentRequest") }}</span>
						<span v-else>{{ $t("buttons.save") }}</span>
					</frp-btn>

					<frp-confirm-dialog v-if="isDocumentUnsavedChangesDialogEnabled"
										@submit="onCancelFormSubmit(DocumentSnapshotKeys.DOCUMENT)"
										@cancel="isDocumentUnsavedChangesDialogEnabled = false"
										:title="$t('dialogs.confirmOperationWithUnsavedChanges.title')"
										:description="$t('dialogs.confirmOperationWithUnsavedChanges.description')">
					</frp-confirm-dialog>
				</div>
			</v-form>
			<bod-document-loader v-else></bod-document-loader>
		</v-card>

		<template v-if="!isCreateDocumentMode && !isCreateRequestMode && !isAcceptRequestMode">
			<span class="text-subtitle-1 font-weight-medium">{{ $t("subheaders.changeHistory") }}</span>

			<v-card elevation="0" class="loan-card pa-0 my-4">
				<v-row>
					<v-col class="pb-0" style="border-top: 1px solid var(--v-grey-lighten4)">
						<v-data-table :headers="headers"
									  :loading-text="$t('tables.loading')"
									  :loading="isItemsLoading"
									  hide-default-footer
									  item-key="id"
									  :items="items"
									  :items-per-page="-1"
									  :mobile-breakpoint="mobileBreakpoint"
									  :options="options"
									  class="loan-table bod-table clickable-rows d-flex flex-column">
							<template #item="{ item }">
								<tr class="text-body-2"
									@click="openRequest(item, $event)"
									@mouseup.middle.prevent="openRequestInNewTab(item.id)"
									@mousedown.middle.prevent>
									<td v-for="column in headers">
										<span v-if="column.value === 'type'">
											{{ $t(`aliases.changeDocumentType.${item.type}`) }}
										</span>

										<span v-else-if="column.value === 'createdAt'">
											{{ `${formatDate(item.createdAt, friendlyDateFormatWithSeconds)}` }}
										</span>

										<span v-else-if="column.value === 'initiator'">
											{{ formatFullNameWithInitials(item.initiator) }}
										</span>

										<span v-else-if="column.value === 'changeWarrantFile'">
											<bod-file v-if="item.changeWarrantFile?.id"
													  no-text
													  py="0"
													  :name="item.changeWarrantFile.title"
													  :id="item.changeWarrantFile.id">
											</bod-file>
										</span>

										<span v-else-if="column.value === 'status'">
											{{ $t(`aliases.changeRequestStatusType.${item.status}`) }}
										</span>

										<span v-else>{{ item[column.value] }}</span>
									</td>
								</tr>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-card>
		</template>

		<v-card v-if="(isCreateRequestMode || isAcceptRequestMode) && isInitialized" elevation="0" class="loan-card pa-7 pb-6 mb-4 mt-6">
			<v-row>
				<v-col class="pa-0 mb-1">
					<bod-form-subtitle>{{ $t("subheaders.changeDocumentRequest") }}</bod-form-subtitle>
				</v-col>
			</v-row>

			<v-row class="mb-7" style="column-gap: 186px; row-gap: 12px" v-if="request.acceptors.length">
				<v-col cols="auto" v-for="(acceptor, i) in request.acceptors" :key="i" class="pa-0">
					<frp-label :label="acceptor.title"></frp-label>
					<bod-accept-status :acceptor-title="getAcceptorTitle(acceptor)"
									   :declined-at="acceptor.declinedAt"
									   :accepted-at="acceptor.acceptedAt"
									   :decline-reason="acceptor.declineReason">
					</bod-accept-status>
				</v-col>
			</v-row>

			<v-form :readonly="isRequestFormSaving || isFormReadonly"
					:disabled="isFormDisabled"
					v-if="isInitialized"
					ref="requestForm"
					v-model="isRequestFormValid">
				<frp-form-card-blocks>
					<bod-form-card-block>
						<frp-form-card-block-row>
							<frp-form-card-block-col cols="12" sm="6" md="5" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
								<frp-text-field v-if="isAcceptRequestMode && request.status === ChangeRequestStatusType.Declined"
												:label="$t('fields.declineReason.label')"
												:value="request.declineReason"
												disabled>
								</frp-text-field>

								<frp-text-field :label="$t('fields.changeWarrant.label')"
												v-if="(request.type !== ChangeType.Create && request.type !== ChangeType.Rollback) && !isRollbackPage"
												required
												:disabled="!isUpdateRequestEnabled || (!isCreateRequestMode && !requestPermissions.includes(RequestPermissions.EDIT_CHANGEWARRANT))"
												v-model="requestChangeWarrant"
												:placeholder="$t('fields.changeWarrant.placeholder')">
								</frp-text-field>

								<bod-file-field :label="$t('fields.changeWarrantFile.label')"
												ref="changeWarrantFile"
												clearable
												:meta="changeWarrantFileMeta"
												:text="changeWarrantFile.title"
												v-if="isUpdatePage"
												:disabled="isAcceptRequestMode"
												@change="handleAddChangeWarrantFile"
												:placeholder="$t('fields.changeWarrantFile.placeholder')">
								</bod-file-field>

								<bod-file v-else-if="request.type === ChangeType.Edit"
										  style="width: 100%"
										  class="text-break mb-2"
										  :label="$t('fields.changeWarrantFile.label')"
										  :name="changeWarrantFile.title"
										  :id="changeWarrantFile.id">
								</bod-file>

								<frp-text-field v-if="isAcceptRequestMode || isRollbackPage"
												:label="$t('fields.initiator.label')"
												:value="request.initiator.fullName"
												disabled>
								</frp-text-field>

								<bod-autocomplete :label="$t('fields.supervisor.label')"
												  color="blue"
												  item-text="fullName"
												  @firstclick="fetchUsers"
												  :required="!isCreateUnpublishRequestPageWithWeb"
												  return-object
												  :disabled="!isUpdateRequestEnabled || ((!isCreateRequestMode || isCreateUnpublishRequestPageWithWeb) && !requestPermissions.includes(RequestPermissions.EDIT_RESPONSIBLE_USER))"
												  :loading="isUsersLoading"
												  v-model="requestResponsibleUser"
												  :items="users"
												  :placeholder="$t('fields.supervisor.placeholder')">
								</bod-autocomplete>
							</frp-form-card-block-col>

							<frp-form-card-block-col offset-md="1" cols="12" sm="6" md="5" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
								<frp-text-field :label="$t('fields.changeRequestType.label')"
												required
												disabled
												:value="changeRequestType"
												:placeholder="$t('fields.changeRequestType.placeholder')">
								</frp-text-field>

								<frp-textarea :label="$t('fields.changeRequestDescription.label')"
											  required
											  v-if="isDescriptionVisible"
											  :disabled="!isUpdateRequestEnabled || (!isCreateRequestMode && !requestPermissions.includes(RequestPermissions.EDIT_DESCRIPTION))"
											  v-model="requestDescription"
											  :placeholder="$t('fields.changeRequestDescription.placeholder')">
								</frp-textarea>

								<frp-text-field :label="$t('fields.webLink.label')"
												:required="savedDocumentIsWeb"
												disabled
												v-if="isAcceptRequestMode && [ChangeType.Create].includes(request.type)"
												:placeholder="$t('fields.webLink.placeholder')"
												v-model="requestWebLink">
								</frp-text-field>
							</frp-form-card-block-col>
						</frp-form-card-block-row>

						<bod-form-subtitle
							v-if="!isUnpublishPage && !isRollbackPage && (!isAcceptRequestMode || (request.type !== ChangeType.UnPublish && request.type !== ChangeType.Rollback))">
							{{ $t("subheaders.document") }}
						</bod-form-subtitle>

						<frp-form-card-block-row
							v-if="isCreateRequestMode && !isUnpublishPage && !isRollbackPage">
							<frp-form-card-block-col cols="12" sm="6" md="5" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
								<bod-dropzone max-size="25"
											  @file:add="handleAddRequestFile({ isEditable: true, value: $event })"
											  @file:delete="setRequestEditableFile(new FileMeta())"
											  ref="requestEditableFile"
											  class="bod-dropzone"
											  :label="$t('fields.documentEditableFile.label')"
											  type="editable">
								</bod-dropzone>
							</frp-form-card-block-col>

							<frp-form-card-block-col offset-md="1" cols="12" sm="6" md="5" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
								<bod-dropzone max-size="25"
											  @file:add="handleAddRequestFile({ isEditable: false, value: $event })"
											  @file:delete="setRequestNonEditableFile(new FileMeta())"
											  ref="requestNonEditableFile"
											  class="bod-dropzone"
											  :formats="['pdf']"
											  :label="$t('fields.documentPdfFile.label')"
											  type="pdf">
								</bod-dropzone>
							</frp-form-card-block-col>
						</frp-form-card-block-row>

						<frp-form-card-block-row v-else-if="request.editableFile.id || request.nonEditableFile.id">
							<frp-form-card-block-col v-if="request.editableFile.id"
													 cols="12"
													 sm="6"
													 md="5"
													 :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
								<bod-file style="width: 100%"
										  class="text-break"
										  :label="$t('fields.documentEditableFile.label')"
										  :name="request.editableFile.title"
										  :id="request.editableFile.id">
								</bod-file>
							</frp-form-card-block-col>

							<frp-form-card-block-col v-if="request.nonEditableFile.id"
													 :offset-md="request.editableFile?.id ? 1 : 0"
													 cols="12"
													 sm="6"
													 md="5"
													 :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
								<bod-file style="width: 100%"
										  class="text-break"
										  :label="$t('fields.documentPdfFile.label')"
										  :name="request.nonEditableFile.title"
										  :id="request.nonEditableFile.id">
								</bod-file>
							</frp-form-card-block-col>
						</frp-form-card-block-row>
					</bod-form-card-block>
				</frp-form-card-blocks>

				<div class="d-flex mt-6 mx-n3"
					 :class="{ 'flex-column': $vuetify.breakpoint.smAndDown, 'align-end': $vuetify.breakpoint.smOnly }"
					 style="gap: 16px">
					<v-spacer></v-spacer>
					<div class="d-flex" style="gap: 16px" :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }">
						<frp-btn elevation="0"
								 v-if="isUpdateRequestEnabled"
								 :block="$vuetify.breakpoint.xsOnly"
								 @click="isRequestUnsavedChangesDialogEnabled = true"
								 :disabled="!isStateContainsUnsavedChanges(DocumentSnapshotKeys.REQUEST)">
							{{ $t("buttons.cancel") }}
						</frp-btn>

						<frp-btn elevation="0"
								 v-if="isUpdateRequestEnabled"
								 style="margin-left: 0 !important"
								 :block="$vuetify.breakpoint.xsOnly"
								 :loading="isRequestFormSaving || isFormLoadingState"
								 :disabled="isSaveRequestDisabled"
								 dark
								 @click="handleRequestSubmit"
								 color="blue">
							<span v-if="isCreateRequestMode">{{ $t("buttons.sendChangeDocumentRequest") }}</span>
							<span v-else>{{ $t("buttons.save") }}</span>
						</frp-btn>
					</div>
					<div class="d-flex"
						 style="gap: 16px"
						 :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
						 v-if="isAcceptRequestMode && !isAcceptButtonsHidden">
						<frp-btn elevation="0"
								 v-if="requestPermissions.includes(RequestPermissions.DECLINE)"
								 :block="$vuetify.breakpoint.xsOnly"
								 color="secondary"
								 dark
								 @click="isDeclineDialogOpened = true">
							{{ $t("buttons.rejectRequest") }}
						</frp-btn>

						<frp-btn elevation="0"
								 v-if="requestPermissions.includes(RequestPermissions.ACCEPT)"
								 style="margin-left: 0 !important"
								 :block="$vuetify.breakpoint.xsOnly"
								 :loading="isAccepting"
								 dark
								 @click="acceptRequest"
								 color="blue">
							<span>{{ $t("buttons.acceptRequest") }}</span>
						</frp-btn>
					</div>

					<frp-confirm-dialog v-if="isRequestUnsavedChangesDialogEnabled"
										@submit="onCancelFormSubmit(DocumentSnapshotKeys.REQUEST)"
										@cancel="isRequestUnsavedChangesDialogEnabled = false"
										:title="$t('dialogs.confirmOperationWithUnsavedChanges.title')"
										:description="$t('dialogs.confirmOperationWithUnsavedChanges.description')">
					</frp-confirm-dialog>
				</div>
			</v-form>
		</v-card>

		<frp-dialog max-width="568" v-model="isDeclineDialogOpened" :title="$t('dialogs.declineRequest.title')">
			<template #content="{ onIntersect }">
				<frp-textarea :label="$t('fields.declineReason.label')"
							  required
							  :placeholder="$t('fields.declineReason.placeholder')"
							  v-model="declineReason">
				</frp-textarea>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="isDeclineDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 :loading="isRejecting"
						 :disabled="!declineReason"
						 color="blue"
						 dark
						 @click="rejectRequest">
					{{ $t("buttons.rejectRequest") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</bod-content-layout>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import BodAcceptStatus from "Components/common/BodAcceptStatus";
import BodChip from "Components/common/BodChip";
import BodFile from "Components/common/BodFile";
import FrpConfirmDialog from "Components/dialogs/FrpConfirmDialog";
import FrpDialog from "Components/dialogs/FrpDialog";
import BodDropzone from "Components/dropzone/BodDropzone";
import LoanDropzone from "Components/dropzone/LoanDropzone";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import BodFileField from "Components/fields/BodFileField";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpFileField from "Components/fields/FrpFileField";
import FrpLabel from "Components/fields/FrpLabel";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpTextField from "Components/fields/FrpTextField";
import FrpFormActions from "Components/form/FrpFormActions";
import FrpIcon from "Components/icon/FrpIcon";
import BodContentLayout from "Components/layouts/BodContentLayout";
import BodFormCardBlock from "Components/markup/BodFormCardBlock";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpFormCard from "Components/markup/FrpFormCard";
import BodFormSubtitle from "Components/typography/BodFormSubtitle";
import FrpActions from "Components/buttons/FrpActions";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import { listMixin } from "Mixins/listMixin";
import { RouteNames } from "Router/bod/routes";
import { getUserWithUniqueFullName } from "Store/bod/modules/document/helpers/getUserWithUniqueFullName";
import { AcceptorType } from "Store/bod/modules/document/types/acceptorType";
import { ChangeRequestStatusType } from "Store/bod/modules/document/types/changeRequestStatusType";
import { DocumentPermissions } from "Store/bod/modules/document/types/documentPermissions";
import { DocumentSnapshotKeys } from "Store/bod/modules/document/types/documentSnapshotKeys";
import { DocumentStatusTypeEnum } from "Store/bod/modules/document/types/documentStatusTypeEnum";
import { RequestPermissions } from "Store/bod/modules/document/types/requestPermissions";
import { ChangeType } from "Store/bod/modules/requests/types/changeType";
import FileMeta from "Store/shared/storage/types/fileMeta";
import { formatDate } from "Utils/dates";
import { friendlyDateFormatWithSeconds } from "Utils/formats";
import { formatFullNameWithInitials } from "Utils/formatting";
import { openRouteInNewTab } from "Utils/router";
import BodDocumentLoader from "Views/bod/document/BodDocumentLoader";
import authorizationMixin from "Mixins/authorizationMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { getterTypes, actionTypes, mutationTypes, namespace } from "Store/bod/modules/document/types";
import { createNamespacedHelpers } from "vuex";
import { cloneDeep } from "lodash";

const { mapState, mapMutations, mapActions, mapGetters } = createNamespacedHelpers(namespace);

export default {
	metaInfo() {
		return { title: this.title };
	},
	mixins: [formMixin, storeModuleBasedPage, authorizationMixin, listMixin, colorsMixin],
	data() {
		return {
			AcceptorType,
			ChangeType,
			DocumentPermissions,
			RequestPermissions,
			ChangeRequestStatusType,
			DocumentSnapshotKeys,
			FileMeta,
			RouteNames,
			DocumentStatusTypeEnum,
			namespace,
			formatFullNameWithInitials,
			formatDate,
			friendlyDateFormatWithSeconds,
			isDocumentUnsavedChangesDialogEnabled: false,
			isRequestUnsavedChangesDialogEnabled: false,
			isDocumentFormValid: false,
			isRequestFormValid: false,
			isDeclineDialogOpened: false,
			documentRequirementsLink: process.env.VUE_APP_BOD_DOCUMENT_REQUIREMENTS_LINK,
			headers: [
				{
					text: this.$t("tables.createdDate"),
					value: "createdAt",
					class: "text-caption",
					sortable: false,
					width: "17%"
				},
				{
					text: this.$t("tables.initiator"),
					value: "initiator",
					class: "text-caption",
					sortable: false,
					width: "17%"
				},
				{
					text: this.$t("tables.requestType"),
					value: "type",
					class: "text-caption",
					sortable: false,
					width: "17%"
				},
				{
					text: this.$t("tables.changeWarrant"),
					value: "changeWarrant",
					class: "text-caption",
					sortable: false,
					width: "17%"
				},
				{
					text: "",
					value: "changeWarrantFile",
					class: "text-caption",
					sortable: false,
					width: "1%"
				},
				{
					text: "",
					value: "space",
					class: "text-caption",
					sortable: false,
					width: "17%"
				},
				{
					text: this.$t("tables.status"),
					value: "status",
					class: "text-caption",
					sortable: false,
					width: "17%"
				}
			]
		};
	},
	computed: {
		...mapState({
			state: state => state,
			isInitialized: state => state.isInitialized,
			isUsersLoading: state => state.isUsersLoading,
			isDepartmentsLoading: state => state.isDepartmentsLoading,
			isSectionsLoading: state => state.isSectionsLoading,
			isFinanceSchemesLoading: state => state.isFinanceSchemesLoading,
			isAccepting: state => state.isAccepting,
			isRejecting: state => state.isRejecting,
			isAcceptButtonsHidden: state => state.isAcceptButtonsHidden,
			departments: state => state.departments,
			sections: state => state.sections,
			financeSchemes: state => state.financeSchemes,
			document: state => state.document,
			request: state => state.request,
			editableFile: state => state.editableFile,
			nonEditableFile: state => state.nonEditableFile,
			requestEditableFile: state => state.requestEditableFile,
			requestNonEditableFile: state => state.requestNonEditableFile,
			changeWarrantFile: state => state.changeWarrantFile,
			changeWarrantFileMeta: state => state.changeWarrantFileMeta,
			documentChangeWarrantFileMeta: state => state.documentChangeWarrantFileMeta,
			isDocumentFormSaving: state => state.isDocumentFormSaving,
			isRequestFormSaving: state => state.isRequestFormSaving,
			documentPermissions: state => state.documentPermissions,
			requestPermissions: state => state.requestPermissions,
			savedDocumentIsWeb: state => state.savedDocumentIsWeb
		}),
		...mapGetters({
			users: getterTypes.users,
			isCreateUnpublishRequestPageWithWeb: getterTypes.isCreateUnpublishRequestPageWithWeb
		}),
		title() {
			switch (this.$route.name) {
				case RouteNames.DOCUMENT:
					return this.$t("titles.document");
				case RouteNames.DOCUMENT_CREATE:
					return this.$t("titles.createNewDocumentRequest");
				case RouteNames.DOCUMENT_UPDATE:
					return this.$t("titles.createUpdateDocumentRequest");
				case RouteNames.DOCUMENT_UNPUBLISH:
					return this.$t("titles.createUnpublishDocumentRequest");
				case RouteNames.DOCUMENT_ROLLBACK:
					return this.$t("titles.createRollbackDocumentRequest");
				case RouteNames.REQUEST:
					return this.$t("titles.requestAccepting");
			}
		},
		changeRequestType() {
			switch (this.$route.name) {
				case RouteNames.DOCUMENT_UPDATE:
					return this.$t("aliases.changeDocumentRequestType.Edit");
				case RouteNames.DOCUMENT_UNPUBLISH:
					return this.$t("aliases.changeDocumentRequestType.UnPublish");
				case RouteNames.DOCUMENT_ROLLBACK:
					return this.$t("aliases.changeDocumentRequestType.Rollback");
				case RouteNames.REQUEST:
					return this.$t(`aliases.changeDocumentRequestType.${this.request.type}`);
			}
		},
		isCreateDocumentMode() {
			return this.$route.name === RouteNames.DOCUMENT_CREATE;
		},
		isCreateRequestMode() {
			return [RouteNames.DOCUMENT_UNPUBLISH, RouteNames.DOCUMENT_UPDATE, RouteNames.DOCUMENT_ROLLBACK].includes(this.$route.name);
		},
		isAcceptRequestMode() {
			return this.$route.name === RouteNames.REQUEST;
		},
		isUpdateDocumentEnabled() {
			const permissions = [DocumentPermissions.EDIT_RESPONSIBLE_USER, DocumentPermissions.EDIT_RESPONSIBLE_DEPARTMENT,
				DocumentPermissions.EDIT_FINANCE_SCHEMES, DocumentPermissions.EDIT_SECTION, DocumentPermissions.EDIT_WEB_LINK,
				DocumentPermissions.EDIT_IS_WEB];
			return this.isCreateDocumentMode || (!this.isCreateRequestMode && this.documentPermissions.some(x => permissions.includes(x)));
		},
		isUpdateRequestEnabled() {
			const permissions = [RequestPermissions.EDIT_CHANGEWARRANT, RequestPermissions.EDIT_DESCRIPTION,
				RequestPermissions.EDIT_RESPONSIBLE_USER];
			return this.isCreateRequestMode || this.requestPermissions.some(x => permissions.includes(x));
		},
		isSaveRequestDisabled() {
			return (!this.isStateContainsUnsavedChanges(DocumentSnapshotKeys.REQUEST) && !this.isRollbackPage) ||
				!this.isRequestFormValid ||
				(!this.requestEditableFile.id && !this.requestNonEditableFile.id && !this.isUnpublishPage && !this.isRollbackPage);
		},
		isDescriptionVisible() {
			return !this.isUnpublishPage &&
				(!this.isAcceptRequestMode || this.request.type === ChangeType.Edit || this.request.type === ChangeType.Rollback);
		},
		isUpdatePage() {
			return RouteNames.DOCUMENT_UPDATE === this.$route.name;
		},
		isUnpublishPage() {
			return RouteNames.DOCUMENT_UNPUBLISH === this.$route.name;
		},
		isRollbackPage() {
			return RouteNames.DOCUMENT_ROLLBACK === this.$route.name;
		},
		declineReason: {
			get() {
				return this.state.declineReason;
			},
			set(value) {
				this.setDeclineReason(value);
			}
		},
		name: {
			get() {
				return this.document.name;
			},
			set(value) {
				this.setDocumentName(value);
			}
		},
		documentSection: {
			get() {
				return cloneDeep(this.document.section);
			},
			set(value) {
				this.setDocumentSection(value);
			}
		},
		documentFinanceSchemes: {
			get() {
				return cloneDeep(this.document.financeSchemes);
			},
			set(value) {
				this.setDocumentFinanceSchemes(value);
			}
		},
		documentResponsibleDepartment: {
			get() {
				return cloneDeep(this.document.responsibleDepartment);
			},
			set(value) {
				this.setDocumentResponsibleDepartment(value);
			}
		},
		documentResponsibleUser: {
			get() {
				return this.document.responsibleUser && getUserWithUniqueFullName(this.document.responsibleUser, this.users);
			},
			set(value) {
				const cloned = cloneDeep(value);

				if(!cloned)
					return cloned;

				// Удаление поля fullName, которое не должно храниться в стейте из-за снапшота и нужно только для отображения в автокомплите
				delete cloned.fullName;
				return this.setDocumentResponsibleUser(cloned);
			}
		},
		documentLinkedToFinanceSchemes: {
			get() {
				return this.document.linkedToFinanceSchemes;
			},
			set(value) {
				this.setDocumentLinkedToFinanceSchemes(value);
			}
		},
		documentIsWeb: {
			get() {
				return this.document.isWeb;
			},
			set(value) {
				this.setDocumentIsWeb(value);
			}
		},
		documentWebLink: {
			get() {
				return this.document.webLink;
			},
			set(value) {
				this.setDocumentWebLink(value);
			}
		},
		requestResponsibleUser: {
			get() {
				return this.request.responsibleUser && getUserWithUniqueFullName(this.request.responsibleUser, this.users);
			},
			set(value) {
				const cloned = cloneDeep(value);

				if(!cloned)
					return cloned;

				delete cloned.fullName;
				return this.setRequestResponsibleUser(cloned);
			}
		},
		documentChangeWarrant: {
			get() {
				return this.document.changeWarrant;
			},
			set(value) {
				this.setDocumentChangeWarrant(value);
			}
		},
		requestChangeWarrant: {
			get() {
				return this.request.changeWarrant;
			},
			set(value) {
				this.setRequestChangeWarrant(value);
			}
		},
		requestDescription: {
			get() {
				return this.request.description;
			},
			set(value) {
				this.setRequestDescription(value);
			}
		},
		requestWebLink: {
			get() {
				return this.request.webLink;
			},
			set(value) {
				this.setRequestWebLink(value);
			}
		}
	},
	methods: {
		...mapActions({
			createNewDocumentRequest: actionTypes.createNewDocumentRequest,
			updateDocument: actionTypes.updateDocument,
			createDocumentUnpublishRequest: actionTypes.createDocumentUnpublishRequest,
			createRollbackDocumentRequest: actionTypes.createRollbackDocumentRequest,
			createDocumentUpdateRequest: actionTypes.createDocumentUpdateRequest,
			updateRequest: actionTypes.updateRequest,
			copyLink: actionTypes.copyLink,
			fetchUsers: actionTypes.fetchUsers,
			fetchDepartments: actionTypes.fetchDepartments,
			fetchSections: actionTypes.fetchSections,
			fetchFinanceSchemes: actionTypes.fetchFinanceSchemes,
			acceptRequest: actionTypes.acceptRequest,
			rejectRequest: actionTypes.rejectRequest,
			rollback: actionTypes.cancelChanges,
			handleAddDocumentFile: actionTypes.handleAddDocumentFile,
			handleAddRequestFile: actionTypes.handleAddRequestFile,
			handleAddChangeWarrantFile: actionTypes.handleAddChangeWarrantFile,
			handleAddDocumentChangeWarrantFile: actionTypes.handleAddDocumentChangeWarrantFile
		}),
		...mapMutations({
			setDeclineReason: mutationTypes.SET_DECLINE_REASON,
			setDocumentName: mutationTypes.SET_DOCUMENT_NAME,
			setDocumentSection: mutationTypes.SET_DOCUMENT_SECTION,
			setDocumentFinanceSchemes: mutationTypes.SET_DOCUMENT_FINANCE_SCHEMES,
			setDocumentResponsibleDepartment: mutationTypes.SET_DOCUMENT_RESPONSIBLE_DEPARTMENT,
			setDocumentResponsibleUser: mutationTypes.SET_DOCUMENT_RESPONSIBLE_USER,
			setDocumentEditableFile: mutationTypes.SET_DOCUMENT_EDITABLE_FILE,
			setDocumentNonEditableFile: mutationTypes.SET_DOCUMENT_NON_EDITABLE_FILE,
			setDocumentLinkedToFinanceSchemes: mutationTypes.SET_DOCUMENT_LINKED_TO_FINANCE_SCHEMES,
			setDocumentIsWeb: mutationTypes.SET_DOCUMENT_IS_WEB,
			setDocumentWebLink: mutationTypes.SET_DOCUMENT_WEB_LINK,
			setDocumentChangeWarrant: mutationTypes.SET_DOCUMENT_CHANGE_WARRANT,
			setRequestEditableFile: mutationTypes.SET_REQUEST_EDITABLE_FILE,
			setRequestNonEditableFile: mutationTypes.SET_REQUEST_NON_EDITABLE_FILE,
			setRequestResponsibleUser: mutationTypes.SET_REQUEST_RESPONSIBLE_USER,
			setRequestChangeWarrant: mutationTypes.SET_REQUEST_CHANGE_WARRANT,
			setRequestDescription: mutationTypes.SET_REQUEST_DESCRIPTION,
			setRequestWebLink: mutationTypes.SET_REQUEST_WEB_LINK
		}),
		async openRequest({ id }, { ctrlKey }) {
			if(ctrlKey)
				this.openRequestInNewTab(id);
			else
				await this.$router.push({ name: RouteNames.REQUEST, params: { requestId: id } });
		},
		openRequestInNewTab(id) {
			openRouteInNewTab(this.$router, { name: RouteNames.REQUEST, params: { requestId: id } });
		},
		async onCancelFormSubmit(key) {
			await this.rollback(key);

			if(key === DocumentSnapshotKeys.DOCUMENT) {
				this.isDocumentUnsavedChangesDialogEnabled = false;

				this.$refs.documentNonEditableFile?.clear();
				this.$refs.documentEditableFile?.clear();
				this.$refs.changeWarrantFile?.clear();

				this.$nextTick(() => {
					if(!this.isResetValidationDisabled)
						this.$refs.documentForm.resetValidation();
				});
			} else {
				this.isRequestUnsavedChangesDialogEnabled = false;

				this.$refs.requestNonEditableFile?.clear();
				this.$refs.requestEditableFile?.clear();
				this.$refs.changeWarrantFile?.clear();

				this.$nextTick(() => {
					if(!this.isResetValidationDisabled)
						this.$refs.requestForm.resetValidation();
				});
			}
		},
		handleDocumentSubmit() {
			if(this.$refs.documentForm.validate()) {
				switch (this.$route.name) {
					case RouteNames.DOCUMENT_CREATE:
						this.createNewDocumentRequest();
						break;
					case RouteNames.DOCUMENT:
					case RouteNames.REQUEST:
						this.updateDocument();
						break;
				}
			}
		},
		handleRequestSubmit() {
			if(this.$refs.requestForm.validate()) {
				switch (this.$route.name) {
					case RouteNames.DOCUMENT_UNPUBLISH:
						this.createDocumentUnpublishRequest();
						break;
					case RouteNames.DOCUMENT_ROLLBACK:
						this.createRollbackDocumentRequest();
						break;
					case RouteNames.DOCUMENT_UPDATE:
						this.createDocumentUpdateRequest();
						break;
					case RouteNames.REQUEST:
						this.updateRequest();
						break;
				}
			}
		},
		handleFetchUsers() {
			if(!this.users.length)
				this.fetchUsers();
		},
		getAcceptorTitle(acceptor) {
			switch (acceptor.type) {
				case AcceptorType.Person:
					return formatFullNameWithInitials(acceptor.user);
				case AcceptorType.Department:
					return formatFullNameWithInitials(acceptor.acceptedBy.id ? acceptor.acceptedBy : acceptor.declinedBy);
			}
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		BodFileField,
		FrpFileField,
		BodAcceptStatus,
		FrpLabel,
		FrpDialog,
		FrpCheckbox,
		BodAutocomplete,
		BodChip,
		FrpIcon,
		BodFile,
		FrpConfirmDialog,
		BodDropzone,
		FrpFormActions,
		FrpBtn,
		LoanDropzone,
		FrpAutocomplete,
		FrpTextarea,
		BodFormSubtitle,
		BodFormCardBlock,
		FrpTextField,
		FrpFormCardBlockCol,
		FrpFormCardBlock,
		BodContentLayout,
		FrpFormCardBlockRow,
		FrpFormCardBlocks,
		FrpFormItem,
		FrpFormCard,
		FrpActions,
		BodDocumentLoader
	}
};
</script>
