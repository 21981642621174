import { addProfile, createMap, createMapper, forMember, ignore, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToTimestamp } from "@/utils/dates";
import ApiChangeRequestsListItem from "@/api/bod/types/apiChangeRequestsListItem";
import ChangeRequestsListItem from "@/store/bod/modules/requests/types/changeRequestsListItem";
import ApiGetChangeRequestsParameters from "@/api/bod/types/apiGetChangeRequestsParameters";
import RequestsState from "@/store/bod/modules/requests/types/requestsState";
import { RequestFilterStatusType } from "@/store/bod/modules/requests/types/requestFilterStatusType";

const mapper = createMapper({
	strategyInitializer: classes()
});

const requestsProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiChangeRequestsListItem, ChangeRequestsListItem,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.createdAt, mapFrom(x => convertToTimestamp(x.createdAt))),
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.section, mapFrom(x => x.section)),
		forMember(d => d.financeSchemes, mapFrom(x => x.financeSchemes)),
		forMember(d => d.responsibleDepartment, mapFrom(x => x.responsibleDepartment)),
		forMember(d => d.fileModel, mapFrom(x => x.fileModel)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.changeWarrant, mapFrom(x => x.changeWarrant)),
		forMember(d => d.changeWarrantFile, mapFrom(x => x.changeWarrantFile)),
		forMember(d => d.type, mapFrom(x => x.type)),
		forMember(d => d.status, mapFrom(x => x.status)),
		forMember(d => d.initiator, mapFrom(x => x.initiator))
	);

	createMap(mapper, RequestsState, ApiGetChangeRequestsParameters,
		forMember(d => d.take, mapFrom(x => x.paging.pageSize)),
		forMember(d => d.skip, mapFrom(x => x.paging.pageSize * (x.paging.page - 1))),
		forMember(d => d.text, mapFrom(x => x.filter.text)),
		forMember(d => d.sectionIds, mapFrom(x => x.filter.sectionIds)),
		forMember(d => d.initiatorIds, mapFrom(x => x.filter.initiatorIds)),
		forMember(d => d.statuses, mapFrom(x => x.filter.statuses.filter(y => y !== RequestFilterStatusType.Empty)))
	);
};

addProfile(mapper, requestsProfile);

export default mapper;

