import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "agreement";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	version: "version",
	titleDocuments: "titleDocuments"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch",
	saveVersion: "saveVersion",
	saveDraft: "saveDraft",
	resetDraft: "resetDraft",
	saveChanges: "saveChanges"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	VERSION_UPDATED_EVENT: "VERSION_UPDATED_EVENT",
	VERSION_SELECTED_EVENT: "VERSION_SELECTED_EVENT",
	SET_DRAFT: "SET_DRAFT",
	SET_DRAFT_TITLE_DOCUMENT: "SET_DRAFT_TITLE_DOCUMENT",
	SET_DRAFT_DOCUMENT_NUMBER: "SET_DRAFT_DOCUMENT_NUMBER",
	SET_DRAFT_START_DATE: "SET_DRAFT_START_DATE",
	SET_DRAFT_CHANGE_REASON: "SET_DRAFT_CHANGE_REASON",
	SET_DRAFT_COMMENT: "SET_DRAFT_COMMENT",
	SET_AGREEMENT: "SET_AGREEMENT",
	SET_MODE: "SET_MODE",
	SET_AGREEMENT_MODE: "SET_AGREEMENT_MODE",
	SET_IS_VERSION_ACTIVATING: "SET_IS_VERSION_ACTIVATING",
	WRONG_TRANCHES_AMOUNT_EVENT: "WRONG_TRANCHES_AMOUNT_EVENT",
	WRONG_SCHEDULE_AMOUNT_EVENT: "WRONG_SCHEDULE_AMOUNT_EVENT",
	WRONG_ASSIGNMENT_SCHEDULE_AMOUNT_EVENT: "WRONG_ASSIGNMENT_SCHEDULE_AMOUNT_EVENT"
};

const agreementTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default agreementTypes;
