import ApiDepartment from "@/api/bod/types/apiDepartment";
import { Type } from "class-transformer";
import BodAcceptorBase from "@/store/bod/modules/document/types/bodAcceptorBase";
import ApiBodUser from "@/api/bod/types/apiBodUser";

export default class BodDepartmentAcceptor extends BodAcceptorBase {
	@Type(() => ApiDepartment)
	department: ApiDepartment;

	acceptedBy: ApiBodUser;
	declinedBy: ApiBodUser;

	constructor(
		title: string = "",
		type: string = "",
		acceptedAt: number = 0,
		declinedAt: number = 0,
		declineReason: string = "",
		department: ApiDepartment = new ApiDepartment(),
		acceptedBy: ApiBodUser = new ApiBodUser(),
		declinedBy: ApiBodUser = new ApiBodUser()
	)
	{
		super(title, type, acceptedAt, declinedAt, declineReason);

		this.department = department;
		this.acceptedBy = acceptedBy;
		this.declinedBy = declinedBy;
	}
}
