<template>
	<v-btn v-on="$listeners"
		   :dark="dark"
		   :to="to"
		   :light="light"
		   :color="color"
		   :outlined="outlined"
		   :height="buttonHeight"
		   class="frp-btn"
		   :disabled="disabled"
		   :loading="loading"
		   :plain="plain"
		   :block="block"
		   :href="href"
		   :text="text"
		   :elevation="elevation"
		   :small="small"
		   :x-small="xSmall"
		   :fab="fab"
		   :icon="icon"
		   :class="[withoutPadding ? 'px-0' : 'px-4', noMargin ? 'no-margin-btn' : '']">
		<slot></slot>
	</v-btn>
</template>

<script>
export default {
	props: {
		dark: Boolean,
		light: Boolean,
		to: Object,
		disabled: Boolean,
		outlined: {
			type: Boolean,
			default: false
		},
		loading: Boolean,
		color: {
			default: "secondary"
		},
		dense: {
			type: Boolean,
			default: false
		},
		maxDense: {
			type: Boolean,
			default: false
		},
		plain: {
			type: Boolean,
			default: false
		},
		block: {
			type: Boolean,
			default: false
		},
		text: {
			type: Boolean,
			default: false
		},
		elevation: {
			type: [String, Number],
			default: undefined
		},
		small: {
			type: Boolean,
			default: false
		},
		xSmall: {
			type: Boolean,
			default: false
		},
		icon: {
			type: Boolean,
			default: false
		},
		fab: {
			type: Boolean,
			default: false
		},
		withoutPadding: {
			type: Boolean,
			default: false
		},
		height: {
			type: [String, Number],
			default: undefined
		},
		href: {
			type: String,
			default: undefined
		},
		noMargin: Boolean
	},
	computed: {
		buttonHeight() {
			if(this.height) {
				return `${this.height}px`;
			} else {
				if(this.dense)
					return "32px";
				else if(this.maxDense)
					return "28px";
				else
					return "40px";
			}
		}
	}
};
</script>

<style scoped lang="less">
.frp-btn {
  & + & {
	margin-left: 16px !important;
  }

  & + &.no-margin-btn {
	margin-left: 0 !important;
  }
}
</style>
