<template>
	<div>
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ `${label}${required ? "*" : ""}` }}</span>
		</v-subheader>
		<v-textarea outlined
					:readonly="readonly"
					ref="input"
					:background-color="colors.white.base"
					@input="$emit('update:value', $event)"
					:type="type"
					v-on="$listeners"
					:value="value"
					dense
					:rows="rows"
					:placeholder="placeholder"
					auto-grow
					no-resize
					:hint="hint"
					v-bind="$attrs"
					class="frp-text-field text-body-2"
					:disabled="disabled"
					:hide-details="hideDetails"
					:rules="[...defaultRules, ...rules]"
					:counter="counter">
		</v-textarea>
	</div>
</template>

<script>
import { requiredRule } from "@/utils/validation";
import colorsMixin from "Mixins/colorsMixin";

export default {
	inheritAttrs: false,
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		rows: {
			type: [Number, String],
			default: 3
		},
		readonly: Boolean,
		value: String,
		type: String,
		label: String,
		hint: String,
		placeholder: String,
		disabled: Boolean,
		hideDetails: Boolean,
		counter: {
			type: [Number, String],
			default: undefined
		},
		required: {
			type: Boolean,
			default: false
		},
		rules: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		defaultRules() {
			if(this.required)
				return [requiredRule()];
			else
				return [];
		}
	}
};
</script>

<style scoped>

</style>
