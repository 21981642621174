import SubscribersManager from "@/store/manager/subscribersManager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/bod/modules/documents/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import { Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import DocumentsState from "@/store/bod/modules/documents/types/documentsState";
import DocumentsFilter from "@/store/bod/modules/documents/types/documentsFilter";
import DocumentListItem from "@/store/bod/modules/documents/types/documentListItem";
import DocumentsRouteQuery from "@/store/bod/modules/documents/types/documentsRouteQuery";
import DocumentsRouteQueryService from "@/store/bod/modules/documents/services/documentsRouteQueryService";
import { BodController } from "@/api/bod";
import { RouteNames } from "@/router/bod/routes";
import router from "@/router/bod";
import routeTypes from "@/store/shared/route/types";
import mapper from "@/store/bod/modules/documents/mapper";
import ApiGetDocumentsParameters from "@/api/bod/types/apiGetDocumentsParameters";
import ApiDocumentListItem from "@/api/bod/types/apiDocumentListItem";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { i18n } from "@/plugins";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import { plainToInstance } from "class-transformer";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import { getUserWithUniqueFullName } from "@/store/bod/modules/document/helpers/getUserWithUniqueFullName";

const abortService = new AbortService();
const bodController = new BodController(abortService);

const defaultRouteQuery = new DocumentsRouteQuery(1, "", [], [], [], [], [], true, false);

const routeQueryService = new DocumentsRouteQueryService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<DocumentsState>()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new DocumentsState(
			new ListingModel<DocumentListItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "createdAt",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: defaultRouteQuery.page,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			new DocumentsFilter(),
			routeMixin.state(),
			false
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<DocumentsState>;

const getters = <GetterTree<DocumentsState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			let item = {
				...x,
				section: x.section.title,
				financeSchemes: x.linkedToFinanceSchemes ? x.financeSchemes.map(y => y.title).join(", ") : "--",
				department: x.responsibleDepartment.title
			};

			return item;
		});
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<DocumentsState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<DocumentsState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_TEXT):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_FINANCE_SCHEMES_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_DEPARTMENT_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_RESPONSIBLE_USER_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_SECTION_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_INITIATOR_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ACTIVE_ONLY):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_IS_WEB):
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
			await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
	}
};

const actions = <ActionTree<DocumentsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);

		await dispatch(actionTypes.updateListingItems);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			let { items, totalCount } = await bodController.getDocuments(mapper.map(state, DocumentsState, ApiGetDocumentsParameters));

			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.map(x, ApiDocumentListItem, DocumentListItem)));
			commit(mutationTypes.SET_PAGING_TOTAL, totalCount);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit, dispatch }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);

		if(routeQuery.departmentIds.length)
			await dispatch(actionTypes.fetchDepartments);
		if(routeQuery.responsibleUserIds.length)
			await dispatch(actionTypes.fetchResponsibleUsers);
		if(routeQuery.sectionIds.length)
			await dispatch(actionTypes.fetchSections);
		if(routeQuery.financeSchemeIds.length)
			await dispatch(actionTypes.fetchFinanceSchemes);

		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_FILTER_TEXT, routeQuery.text);
		commit(mutationTypes.SET_FILTER_FINANCE_SCHEMES_IDS, routeQuery.financeSchemeIds);
		commit(mutationTypes.SET_FILTER_DEPARTMENT_IDS, routeQuery.departmentIds);
		commit(mutationTypes.SET_FILTER_RESPONSIBLE_USER_IDS, routeQuery.responsibleUserIds);
		commit(mutationTypes.SET_FILTER_SECTION_IDS, routeQuery.sectionIds);
		commit(mutationTypes.SET_FILTER_INITIATOR_IDS, routeQuery.initiatorIds);
		commit(mutationTypes.SET_FILTER_ACTIVE_ONLY, routeQuery.activeOnly);
		commit(mutationTypes.SET_FILTER_IS_WEB, routeQuery.isWeb);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.DOCUMENTS,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.DOCUMENTS,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchResponsibleUsers]({ commit, state }) {
		if(state.users.length > 0)
			return;

		commit(mutationTypes.SET_IS_USERS_LOADING, true);

		try {
			const users = await bodController.getResponsibleUsers();

			commit(mutationTypes.SET_USERS, users.map(x => plainToInstance(ApiBodUser, getUserWithUniqueFullName(x, users))));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_USERS_LOADING, false);
		}
	},
	async [actionTypes.fetchDepartments]({ commit, state }) {
		if(state.departments.length > 0)
			return;

		commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, true);

		try {
			const departments = await bodController.getDepartments();

			commit(mutationTypes.SET_DEPARTMENTS, departments);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, false);
		}
	},
	async [actionTypes.fetchSections]({ commit, state }) {
		if(state.sections.length > 0)
			return;

		commit(mutationTypes.SET_IS_SECTIONS_LOADING, true);

		try {
			const sections = await bodController.getSections();

			commit(mutationTypes.SET_SECTIONS, sections);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_SECTIONS_LOADING, false);
		}
	},
	async [actionTypes.fetchFinanceSchemes]({ commit, state }) {
		if(state.financeSchemes.length > 0)
			return;

		commit(mutationTypes.SET_IS_FINANCE_SCHEMES_LOADING, true);

		try {
			const financeSchemes = await bodController.getFinanceSchemes();

			commit(mutationTypes.SET_FINANCE_SCHEMES, financeSchemes);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FINANCE_SCHEMES_LOADING, false);
		}
	},
	async [actionTypes.copyLink]({ commit }, id) {
		try {
			const { link } = await bodController.getDocumentLink(id);

			if(!link) {
				alertService.addError(AlertKeys.COPY_LINK_ERROR);
				return;
			}

			navigator.clipboard.writeText(link);

			alertService.addInfo(AlertKeys.SUCCESS_COPIED_LINK);
		} catch (error) {
			console.error(error);
			alertService.addError(AlertKeys.COPY_LINK_ERROR);
		}
	}
};

const mutations = <MutationTree<DocumentsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_FILTER_TEXT](state, value) {
		state.filter.text = value;
	},
	[mutationTypes.SET_FILTER_FINANCE_SCHEMES_IDS](state, value) {
		state.filter.financeSchemeIds = value;
	},
	[mutationTypes.SET_FILTER_DEPARTMENT_IDS](state, value) {
		state.filter.departmentIds = value;
	},
	[mutationTypes.SET_FILTER_RESPONSIBLE_USER_IDS](state, value) {
		state.filter.responsibleUserIds = value;
	},
	[mutationTypes.SET_FILTER_SECTION_IDS](state, value) {
		state.filter.sectionIds = value;
	},
	[mutationTypes.SET_FILTER_INITIATOR_IDS](state, value) {
		state.filter.initiatorIds = value;
	},
	[mutationTypes.SET_FILTER_ACTIVE_ONLY](state, value) {
		state.filter.activeOnly = value;
	},
	[mutationTypes.SET_FILTER_IS_WEB](state, value) {
		state.filter.isWeb = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = new DocumentsFilter();
	},
	[mutationTypes.SET_IS_USERS_LOADING](state, value) {
		state.isUsersLoading = value;
	},
	[mutationTypes.SET_IS_DEPARTMENTS_LOADING](state, value) {
		state.isDepartmentsLoading = value;
	},
	[mutationTypes.SET_IS_SECTIONS_LOADING](state, value) {
		state.isSectionsLoading = value;
	},
	[mutationTypes.SET_IS_FINANCE_SCHEMES_LOADING](state, value) {
		state.isFinanceSchemesLoading = value;
	},
	[mutationTypes.SET_DEPARTMENTS](state, value) {
		state.departments = value;
	},
	[mutationTypes.SET_USERS](state, value) {
		state.users = value;
	},
	[mutationTypes.SET_SECTIONS](state, value) {
		state.sections = value;
	},
	[mutationTypes.SET_FINANCE_SCHEMES](state, value) {
		state.financeSchemes = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const documentsModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default documentsModule;
