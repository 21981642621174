export default class ApiGetDocumentsParameters {
	take: number;
	skip: number;
	text: string;
	financeSchemeIds: string[];
	responsibleDepartmentIds: string[];
	responsibleUserIds: string[];
	sectionIds: string[];
	initiatorIds: string[];
	activeOnly: boolean;
	isWeb: boolean;

	constructor(
		take: number,
		skip: number,
		text: string,
		financeSchemeIds: string[],
		responsibleDepartmentIds: string[],
		responsibleUserIds: string[],
		sectionIds: string[],
		initiatorIds: string[],
		activeOnly: boolean,
		isWeb: boolean
	)
	{
		this.take = take;
		this.skip = skip;
		this.text = text;
		this.financeSchemeIds = financeSchemeIds;
		this.responsibleDepartmentIds = responsibleDepartmentIds;
		this.responsibleUserIds = responsibleUserIds;
		this.sectionIds = sectionIds;
		this.initiatorIds = initiatorIds;
		this.activeOnly = activeOnly;
		this.isWeb = isWeb;
	}
}
