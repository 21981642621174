<template>
	<div>
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<v-file-input outlined
					  :accept="isArray(accept) ? accept.join(', ') : accept"
					  :hint="hint"
					  :placeholder="text ? '' : (disabled ? '' : placeholder)"
					  :loading="meta.isLoading"
					  :clearable="clearable"
					  ref="input"
					  :background-color="colors.white.base"
					  v-model="value"
					  :type="type"
					  :error-messages="errors"
					  v-on="$listeners"
					  :rules="[...defaultRules, ...rules]"
					  dense
					  :prefix="!value ? text : ''"
					  :persistent-hint="persistentHint"
					  v-bind="$attrs"
					  class="frp-text-field text-body-2"
					  :disabled="disabled"
					  :hide-details="hideDetails">
			<template #append-outer v-if="$slots['append-outer']">
				<slot name="append-outer"></slot>
			</template>
		</v-file-input>
	</div>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import { isArray } from "lodash";
import colorsMixin from "Mixins/colorsMixin";
import FileMeta from "Store/shared/storage/types/fileMeta";
import { requiredRule } from "Utils/validation";

export default {
	inheritAttrs: false,
	mixins: [colorsMixin],
	data() {
		return {
			isArray,
			internalValue: null
		};
	},
	props: {
		persistentHint: {
			type: Boolean,
			default: false
		},
		hint: {
			type: String,
			default: ""
		},
		text: String,
		meta: {
			type: FileMeta,
			required: true
		},
		placeholder: String,
		namespace: String,
		persisted: {
			type: Boolean,
			default: false
		},
		errors: {
			type: Array,
			default: () => []
		},
		rules: {
			type: Array,
			default: () => []
		},
		type: String,
		label: String,
		disabled: Boolean,
		clearable: {
			type: Boolean,
			default: false
		},
		hideDetails: Boolean,
		required: Boolean,
		icon: String,
		appendIcon: String,
		accept: [String, Array]
	},
	computed: {
		defaultRules() {
			if(this.required)
				return [requiredRule()];
			else
				return [];
		},
		value: {
			get() {
				return this.internalValue;
			},
			set(value) {
				this.internalValue = value;

				if(!value)
					this.$emit("change", value);

				if(value instanceof File)
				{
					const reader = new FileReader();

					reader.onload = e => {
						this.$emit("onload", e.target.result);
					};
					reader.readAsDataURL(value);

					this.$emit("change", value);
				}
			}
		}
	},
	methods: {
		clear() {
			this.value = null;
		}
	},
	components: {
		FrpIcon
	}
};
</script>
