import { IsArray, IsBoolean, IsInt, IsString } from "class-validator";

export default class DocumentsFilter {
	@IsString()
	text: string;

	@IsArray()
	financeSchemeIds: string[];

	@IsArray()
	departmentIds: string[];

	@IsArray()
	responsibleUserIds: string[];

	@IsArray()
	sectionIds: string[];

	@IsArray()
	initiatorIds: string[];

	@IsBoolean()
	activeOnly: boolean;

	@IsBoolean()
	isWeb: boolean;

	constructor(
		text: string = "",
		financeSchemeIds: string[] = [],
		departmentIds: string[] = [],
		responsibleUserIds: string[] = [],
		sectionIds: string[] = [],
		initiatorIds: string[] = [],
		activeOnly: boolean = true,
		isWeb: boolean = false
	)
	{
		this.text = text;
		this.financeSchemeIds = financeSchemeIds;
		this.departmentIds = departmentIds;
		this.responsibleUserIds = responsibleUserIds;
		this.sectionIds = sectionIds;
		this.initiatorIds = initiatorIds;
		this.activeOnly = activeOnly;
		this.isWeb = isWeb;
	}
}
