export default class ApiCreateDocumentRequest {
	name: string;
	sectionId: string;
	financeSchemeIds: string[];
	responsibleDepartmentId: string;
	responsibleUserId: string;
	editableFileId: string;
	nonEditableFileId: string;
	linkedToFinanceSchemes: boolean;
	changeWarrant: string;
	changeWarrantFileId: string;
	isWeb: boolean;
	webLink: string;

	constructor(
		name: string = "",
		sectionId: string = "",
		financeSchemeIds: string[] = [],
		responsibleDepartmentId: string = "",
		responsibleUserId: string = "",
		editableFileId: string = "",
		nonEditableFileId: string = "",
		linkedToFinanceSchemes: boolean = false,
		changeWarrant: string = "",
		changeWarrantFileId: string = "",
		isWeb: boolean = false,
		webLink: string = ""
	)
	{
		this.name = name;
		this.sectionId = sectionId;
		this.financeSchemeIds = financeSchemeIds;
		this.responsibleDepartmentId = responsibleDepartmentId;
		this.responsibleUserId = responsibleUserId;
		this.editableFileId = editableFileId;
		this.nonEditableFileId = nonEditableFileId;
		this.linkedToFinanceSchemes = linkedToFinanceSchemes;
		this.changeWarrant = changeWarrant;
		this.changeWarrantFileId = changeWarrantFileId;
		this.isWeb = isWeb;
		this.webLink = webLink;
	}
}
