import { addProfile, createMap, createMapper, forMember, ignore, mapFrom, fromValue, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToTimestamp } from "@/utils/dates";
import Document from "@/store/bod/modules/document/types/document";
import ApiChangeRequest from "@/api/bod/types/apiChangeRequest";
import ApiCreateDocumentChangeRequestRequest from "@/api/bod/types/apiCreateDocumentChangeRequestRequest";
import ApiDocument from "@/api/bod/types/apiDocument";
import ApiCreateDocumentRequest from "@/api/bod/types/apiCreateDocumentRequest";
import { formatFullNameWithInitials } from "@/utils/formatting";
import ChangeRequest from "@/store/bod/modules/document/types/changeRequest";
import UpdateDocumentRequest from "@/store/bod/modules/document/types/updateDocumentRequest";
import ApiUpdateDocumentRequest from "@/api/bod/types/apiUpdateDocumentRequest";
import UpdateRequest from "@/store/bod/modules/document/types/updateRequest";
import ApiUpdateRequest from "@/api/bod/types/apiUpdateRequest";
import ApiCreateDocumentUnpublishRequestRequest from "@/api/bod/types/apiCreateDocumentUnpublishRequestRequest";
import ApiCreateRollbackDocumentRequestRequest from "@/api/bod/types/apiCreateRollbackDocumentRequestRequest";
import BodPersonAcceptor from "@/store/bod/modules/document/types/bodPersonAcceptor";
import ApiBodPersonAcceptor from "@/api/bod/types/apiBodPersonAcceptor";
import ApiBodDepartmentAcceptor from "@/api/bod/types/apiBodDepartmentAcceptor";
import BodDepartmentAcceptor from "@/store/bod/modules/document/types/bodDepartmentAcceptor";
import { AcceptorType } from "@/store/bod/modules/document/types/acceptorType";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";

const mapper = createMapper({
	strategyInitializer: classes()
});

const documentProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiDocument, Document,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.createdAt, mapFrom(x => convertToTimestamp(x.createdAt))),
		forMember(d => d.name, mapFrom(x => x.title)),
		forMember(d => d.status, mapFrom(x => x.status)),
		forMember(d => d.section, mapFrom(x => x.section)),
		forMember(d => d.financeSchemes, mapFrom(x => x.financeSchemes)),
		forMember(d => d.responsibleDepartment, mapFrom(x => x.responsibleDepartment)),
		forMember(d => d.responsibleUser, mapFrom(x => x.responsibleUser)),
		forMember(d => d.editableFile, mapFrom(x => x.editableFile)),
		forMember(d => d.nonEditableFile, mapFrom(x => x.nonEditableFile)),
		forMember(d => d.linkedToFinanceSchemes, mapFrom(x => x.linkedToFinanceSchemes)),
		forMember(d => d.isWeb, mapFrom(x => x.isWeb)),
		forMember(d => d.webLink, mapFrom(x => x.webLink))
	);

	createMap(mapper, Document, ApiCreateDocumentRequest,
		forMember(d => d.name, mapFrom(x => x.name)),
		forMember(d => d.sectionId, mapFrom(x => x.section.id)),
		forMember(d => d.financeSchemeIds, mapFrom(x => x.financeSchemes.map(y => y.id))),
		forMember(d => d.responsibleDepartmentId, mapFrom(x => x.responsibleDepartment.id)),
		forMember(d => d.responsibleUserId, mapFrom(x => x.responsibleUser.id)),
		forMember(d => d.editableFileId, mapFrom(x => x.editableFile.id)),
		forMember(d => d.nonEditableFileId, mapFrom(x => x.nonEditableFile.id)),
		forMember(d => d.isWeb, mapFrom(x => x.isWeb)),
		forMember(d => d.changeWarrant, mapFrom(x => x.changeWarrant)),
		forMember(d => d.changeWarrantFileId, mapFrom(x => x.changeWarrantFileId)),
		forMember(d => d.linkedToFinanceSchemes, mapFrom(x => x.linkedToFinanceSchemes)),
		forMember(d => d.webLink, mapFrom(x => x.webLink))
	);

	createMap(mapper, ApiChangeRequest, ChangeRequest,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.document, mapFrom(x => mapper.map(x.document, ApiDocument, Document))),
		forMember(d => d.createdAt, mapFrom(x => convertToTimestamp(x.createdAt))),
		forMember(d => d.status, mapFrom(x => x.status)),
		forMember(d => d.type, mapFrom(x => x.type)),
		forMember(d => d.responsibleUser, mapFrom(x => x.responsibleUser)),
		forMember(d => d.initiator, mapFrom(x => ({ ...x.initiator, fullName: formatFullNameWithInitials(x.initiator) }))),
		forMember(d => d.editableFile, mapFrom(x => x.editableFile)),
		forMember(d => d.nonEditableFile, mapFrom(x => x.nonEditableFile)),
		forMember(d => d.changeWarrant, mapFrom(x => x.changeWarrant)),
		forMember(d => d.changeWarrantFile, mapFrom(x => x.changeWarrantFile)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.webLink, mapFrom(x => x.webLink)),
		forMember(d => d.declineReason, mapFrom(x => x.declineReason)),
		forMember(d => d.acceptors,
			mapFrom(x => x.acceptors.map(y => y.type === AcceptorType.Person ?
				mapper.map(y, ApiBodPersonAcceptor, BodPersonAcceptor) :
				mapper.map(y, ApiBodDepartmentAcceptor, BodDepartmentAcceptor))))
	);

	createMap(mapper, ChangeRequest, ApiCreateDocumentChangeRequestRequest,
		forMember(d => d.responsibleUserId, mapFrom(x => x.responsibleUser.id)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.changeWarrant, mapFrom(x => x.changeWarrant)),
		forMember(d => d.changeWarrantFileId, mapFrom(x => x.changeWarrantFile.id)),
		forMember(d => d.editableFileId, mapFrom(x => x.editableFile.id)),
		forMember(d => d.nonEditableFileId, mapFrom(x => x.nonEditableFile.id))
	);

	createMap(mapper, ChangeRequest, ApiCreateDocumentUnpublishRequestRequest,
		forMember(d => d.responsibleUserId, mapFrom(x => x.responsibleUser.id)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.changeWarrant, mapFrom(x => x.changeWarrant))
	);

	createMap(mapper, ChangeRequest, ApiCreateRollbackDocumentRequestRequest,
		forMember(d => d.responsibleUserId, mapFrom(x => x.responsibleUser.id)),
		forMember(d => d.description, mapFrom(x => x.description))
	);

	createMap(mapper, UpdateDocumentRequest, ApiUpdateDocumentRequest,
		forMember(d => d.sectionId, mapFrom(x => x.section?.id)),
		forMember(d => d.financeSchemeIds, mapFrom(x => x.financeSchemes && x.financeSchemes.map(y => y.id))),
		forMember(d => d.responsibleDepartmentId, mapFrom(x => x.responsibleDepartment?.id)),
		forMember(d => d.responsibleUserId, mapFrom(x => x.responsibleUser?.id)),
		forMember(d => d.linkedToFinanceSchemes, mapFrom(x => x.linkedToFinanceSchemes)),
		forMember(d => d.isWeb, mapFrom(x => x.isWeb)),
		forMember(d => d.webLink, mapFrom(x => x.webLink))
	);

	createMap(mapper, UpdateRequest, ApiUpdateRequest,
		forMember(d => d.changeWarrant, mapFrom(x => x.changeWarrant)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.responsibleUserId, mapFrom(x => x.responsibleUser?.id)),
		forMember(d => d.webLink, mapFrom(x => x.webLink))
	);

	createMap(mapper, ApiBodPersonAcceptor, BodPersonAcceptor,
		forMember(d => d.type, mapFrom(x => x.type)),
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.acceptedAt, mapFrom(x => convertToTimestamp(x.acceptedAt))),
		forMember(d => d.declinedAt, mapFrom(x => convertToTimestamp(x.declinedAt))),
		forMember(d => d.declineReason, mapFrom(x => x.declineReason)),
		forMember(d => d.user, mapFrom(x => x.user))
	);

	createMap(mapper, ApiBodDepartmentAcceptor, BodDepartmentAcceptor,
		forMember(d => d.type, mapFrom(x => x.type)),
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.acceptedAt, mapFrom(x => convertToTimestamp(x.acceptedAt))),
		forMember(d => d.declinedAt, mapFrom(x => convertToTimestamp(x.declinedAt))),
		forMember(d => d.declineReason, mapFrom(x => x.declineReason)),
		forMember(d => d.department, mapFrom(x => x.department)),
		forMember(d => d.acceptedBy, mapFrom(x => x.acceptedBy)),
		forMember(d => d.declinedBy, mapFrom(x => x.declinedBy))
	);

	createMap(mapper, ApiFileMeta, FileMeta,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.name, mapFrom(x => convertToTimestamp(x.name))),
		forMember(d => d.mimeType, mapFrom(x => convertToTimestamp(x.mimeType))),
		forMember(d => d.namespace, mapFrom(x => x.namespace)),
		forMember(d => d.signature, mapFrom(x => x.signature)),
		forMember(d => d.isLoading, fromValue(false))
	);
};

addProfile(mapper, documentProfile);

export default mapper;

