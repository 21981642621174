export enum Permissions {
	GLOBAL_COUNTERPARTIES_READ = "global:counterparties:read",
	
	GLOBAL_COUNTERPARTY_READ = "global:counterparty:read",
	OWN_COUNTERPARTY_READ = "own:counterparty:read",
	GLOBAL_COUNTERPARTY_CREATE = "global:counterparty:create",
	GLOBAL_COUNTERPARTY_UPDATE = "global:counterparty:update",
	
	GLOBAL_COUNTERPARTY_HEADS_READ = "global:counterparty-heads:read",
	OWN_COUNTERPARTY_HEADS_READ = "own:counterparty-heads:read",
	
	GLOBAL_COUNTERPARTY_HEAD_READ = "global:counterparty-head:read",
	OWN_COUNTERPARTY_HEAD_READ = "own:counterparty-head:read",
	
	GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_UPDATE = "global:counterparty-legal-entity-head-snils:update",
	
	GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_READ = "global:counterparty-legal-entity-head-snils-file:read",
	OWN_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_READ = "own:counterparty-legal-entity-head-snils-file:read",
	GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_CREATE = "global:counterparty-legal-entity-head-snils-file:create",
	OWN_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_CREATE = "own:counterparty-legal-entity-head-snils-file:create",
	GLOBAL_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_UPDATE = "global:counterparty-legal-entity-head-snils-file:update",
	OWN_COUNTERPARTY_LEGAL_ENTITY_HEAD_SNILS_FILE_UPDATE = "own:counterparty-legal-entity-head-snils-file:update",
	
	GLOBAL_COUNTERPARTY_EMPLOYEES_READ = "global:counterparty-employees:read",
	OWN_COUNTERPARTY_EMPLOYEES_READ = "own:counterparty-employees:read",
	
	GLOBAL_COUNTERPARTY_EMPLOYEE_READ = "global:counterparty-employee:read",
	OWN_COUNTERPARTY_EMPLOYEE_READ = "own:counterparty-employee:read",
	GLOBAL_COUNTERPARTY_EMPLOYEE_CREATE = "global:counterparty-employee:create",
	OWN_COUNTERPARTY_EMPLOYEE_CREATE = "own:counterparty-employee:create",
	GLOBAL_COUNTERPARTY_EMPLOYEE_UPDATE = "global:counterparty-employee:update",
	OWN_COUNTERPARTY_EMPLOYEE_UPDATE = "own:counterparty-employee:update",
	
	GLOBAL_COUNTERPARTY_FNS_READ = "global:counterparty-fns:read",
	GLOBAL_COUNTERPARTY_FNS_DELETE = "global:counterparty-fns:delete",
	GLOBAL_COUNTERPARTY_FNS_UPDATE = "global:counterparty-fns:update",
	GLOBAL_COUNTERPARTY_FNS_FORCE_UPDATE = "global:counterparty-fns:force-update",
	
	GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_READ = "global:counterparty-master-account:read",
	GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_CREATE = "global:counterparty-master-account:create",
	GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_UPDATE = "global:counterparty-master-account:update",
	
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUSTS_READ = "global:counterparty-employee-trusts:read",
	OWN_COUNTERPARTY_EMPLOYEE_TRUSTS_READ = "own:counterparty-employee-trusts:read",
	
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_READ = "global:counterparty-employee-trust:read",
	OWN_COUNTERPARTY_EMPLOYEE_TRUST_READ = "own:counterparty-employee-trust:read",
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_CREATE = "global:counterparty-employee-trust:create",
	OWN_COUNTERPARTY_EMPLOYEE_TRUST_CREATE = "own:counterparty-employee-trust:create",
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_ACCEPT = "global:counterparty-employee-trust:accept",
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_DECLINE = "global:counterparty-employee-trust:decline",
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_CANCEL = "global:counterparty-employee-trust:cancel",
	
	GLOBAL_COUNTERPARTY_EMPLOYEE_STATUS_UPDATE = "global:counterparty-employee-status:update",
	OWN_COUNTERPARTY_EMPLOYEE_STATUS_UPDATE = "own:counterparty-employee-status:update",
	
	GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_RECOGNIZE = "global:counterparty-employee-snils:recognize",
	
	GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_READ = "global:counterparty-employee-snils-file:read",
	OWN_COUNTERPARTY_EMPLOYEE_SNILS_FILE_READ = "own:counterparty-employee-snils-file:read",
	GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_CREATE = "global:counterparty-employee-snils-file:create",
	OWN_COUNTERPARTY_EMPLOYEE_SNILS_FILE_CREATE = "own:counterparty-employee-snils-file:create",
	GLOBAL_COUNTERPARTY_EMPLOYEE_SNILS_FILE_UPDATE = "global:counterparty-employee-snils-file:update",
	OWN_COUNTERPARTY_EMPLOYEE_SNILS_FILE_UPDATE = "own:counterparty-employee-snils-file:update",
	
	GLOBAL_COUNTERPARTY_EMPLOYEE_PROFILE_READ = "global:counterparty-employee-profile:read",
	OWN_COUNTERPARTY_EMPLOYEE_PROFILE_READ = "own:counterparty-employee-profile:read",
	
	GLOBAL_OFFICE_READ = "global:office:read",
	GLOBAL_OFFICE_UPDATE = "global:office:update",
	
	GLOBAL_OFFICE_EMPLOYEES_READ = "global:office-employees:read",
	
	GLOBAL_OFFICE_EMPLOYEE_READ = "global:office-employee:read",
	GLOBAL_OFFICE_EMPLOYEE_CREATE = "global:office-employee:create",
	GLOBAL_OFFICE_EMPLOYEE_UPDATE = "global:office-employee:update",
	
	GLOBAL_OFFICE_EMPLOYEE_PROFILE_READ = "global:office-employee-profile:read",
	
	GLOBAL_OFFICE_EMPLOYEE_STATUS_UPDATE = "global:office-employee-status:update",
	
	GLOBAL_ACCOUNTS_READ = "global:accounts:read",
	
	GLOBAL_ACCOUNT_READ = "global:account:read",
	GLOBAL_ACCOUNT_RESET = "global:account:reset",
	
	GLOBAL_ACCOUNT_PASSWORD_RESET = "global:account-password:reset",
	GLOBAL_ACCOUNT_PASSWORD_UPDATE = "global:account-password:update",
	
	GLOBAL_ACCOUNT_STATUS_UPDATE = "global:account-status:update",
	
	GLOBAL_ACCOUNT_EMAIL_UPDATE = "global:account-email:update",
	
	GLOBAL_ACCOUNT_PROFILES_READ = "global:account-profiles:read",
	
	GLOBAL_ACCOUNT_PROFILE_READ = "global:account-profile:read",
	
	GLOBAL_ACCOUNT_PROFILE_STATUS_UPDATE = "global:account-profile-status:update",
	
	GLOBAL_MASTER_ACCOUNT_APPLICATIONS_READ = "global:master-account-applications:read",
	
	GLOBAL_MASTER_ACCOUNT_APPLICATION_READ = "global:master-account-application:read",
	
	GLOBAL_MASTER_ACCOUNT_APPLICATION_STATUS_UPDATE = "global:master-account-application-status:update",
	
	GLOBAL_EMPLOYEE_JOIN_APPLICATIONS_READ = "global:employee-join-applications:read",
	OWN_EMPLOYEE_JOIN_APPLICATIONS_READ = "own:employee-join-applications:read",
	
	GLOBAL_EMPLOYEE_JOIN_APPLICATION_READ = "global:employee-join-application:read",
	OWN_EMPLOYEE_JOIN_APPLICATION_READ = "own:employee-join-application:read",
	OWN_EMPLOYEE_JOIN_APPLICATION_CREATE = "own:employee-join-application:create",
	
	GLOBAL_EMPLOYEE_JOIN_APPLICATION_STATUS_UPDATE = "global:employee-join-application-status:update",
	OWN_EMPLOYEE_JOIN_APPLICATION_STATUS_UPDATE = "own:employee-join-application-status:update",
	
	LOAN_READ = "common-api:read",
	LOAN_UPDATE = "common-api:update",
	LOAN_CREATE = "common-api:create",
	LOAN_DELETE = "common-api:delete",
	
	BAR_ACCOUNT_DELETE = "account:delete",
	BAR_ACCOUNT_CREATE = "account:create",
	BAR_ACCOUNT_UPDATE = "account:update",
	BAR_ACCOUNT_READ = "account:read",
	
	BAR_ACCOUNT_RESPONSIBLE_READ = "account-responsible:read",
	
	BAR_ACCOUNT_CONTROL_DELETE = "account-control:delete",
	BAR_ACCOUNT_CONTROL_CREATE = "account-control:create",
	BAR_ACCOUNT_CONTROL_UPDATE = "account-control:update",
	BAR_ACCOUNT_CONTROL_READ = "account-control:read",
	
	BAR_ACCOUNT_REGISTRY_DELETE = "account-registry:delete",
	BAR_ACCOUNT_REGISTRY_CREATE = "account-registry:create",
	BAR_ACCOUNT_REGISTRY_UPDATE = "account-registry:update",
	BAR_ACCOUNT_REGISTRY_READ = "account-registry:read",
	
	BAR_APPLICATION_DELETE = "application:delete",
	BAR_APPLICATION_CREATE = "application:create",
	BAR_APPLICATION_UPDATE = "application:update",
	BAR_APPLICATION_READ = "application:read",
	
	BAR_APPLICATION_CREATE_DELETE = "application-create:delete",
	BAR_APPLICATION_CREATE_CREATE = "application-create:create",
	BAR_APPLICATION_CREATE_UPDATE = "application-create:update",
	BAR_APPLICATION_CREATE_READ = "application-create:read",
	
	BAR_APPLICATION_MESSAGES_DELETE = "application-messages:delete",
	BAR_APPLICATION_MESSAGES_CREATE = "application-messages:create",
	BAR_APPLICATION_MESSAGES_UPDATE = "application-messages:update",
	BAR_APPLICATION_MESSAGES_READ = "application-messages:read",
	
	BAR_APPLICATION_GENERATE_DELETE = "application-generate:delete",
	BAR_APPLICATION_GENERATE_CREATE = "application-generate:create",
	BAR_APPLICATION_GENERATE_UPDATE = "application-generate:update",
	BAR_APPLICATION_GENERATE_READ = "application-generate:read",
	
	BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_EDIT_DELETE = "application-legal-department-request-edit:delete",
	BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_EDIT_CREATE = "application-legal-department-request-edit:create",
	BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_EDIT_UPDATE = "application-legal-department-request-edit:update",
	BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_EDIT_READ = "application-legal-department-request-edit:read",
	
	BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_SEND_DELETE = "application-legal-department-request-send:delete",
	BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_SEND_CREATE = "application-legal-department-request-send:create",
	BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_SEND_UPDATE = "application-legal-department-request-send:update",
	BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_SEND_READ = "application-legal-department-request-send:read",
	
	BAR_APPLICATION_DOWNLOAD_DOCUMENTS_DELETE = "application-download-documents:delete",
	BAR_APPLICATION_DOWNLOAD_DOCUMENTS_CREATE = "application-download-documents:create",
	BAR_APPLICATION_DOWNLOAD_DOCUMENTS_UPDATE = "application-download-documents:update",
	BAR_APPLICATION_DOWNLOAD_DOCUMENTS_READ = "application-download-documents:read",
	
	BAR_APPLICATION_UPLOAD_DOCUMENTS_DELETE = "application-upload-documents:delete",
	BAR_APPLICATION_UPLOAD_DOCUMENTS_CREATE = "application-upload-documents:create",
	BAR_APPLICATION_UPLOAD_DOCUMENTS_UPDATE = "application-upload-documents:update",
	BAR_APPLICATION_UPLOAD_DOCUMENTS_READ = "application-upload-documents:read",
	
	BAR_APPLICATION_EDIT_DOCUMENTS_DELETE = "application-edit-documents:delete",
	BAR_APPLICATION_EDIT_DOCUMENTS_CREATE = "application-edit-documents:create",
	BAR_APPLICATION_EDIT_DOCUMENTS_UPDATE = "application-edit-documents:update",
	BAR_APPLICATION_EDIT_DOCUMENTS_READ = "application-edit-documents:read",
	
	BAR_APPLICATION_REGISTRY_DELETE = "application-registry:delete",
	BAR_APPLICATION_REGISTRY_CREATE = "application-registry:create",
	BAR_APPLICATION_REGISTRY_UPDATE = "application-registry:update",
	BAR_APPLICATION_REGISTRY_READ = "application-registry:read",
	
	BAR_ADMINISTRATOR_DELETE = "administrator:delete",
	BAR_ADMINISTRATOR_CREATE = "administrator:create",
	BAR_ADMINISTRATOR_UPDATE = "administrator:update",
	BAR_ADMINISTRATOR_READ = "administrator:read",
	
	TRANCHES_TRANCHE_SCHEDULE_READ = "tranche:read",
	TRANCHES_TRANCHE_SCHEDULE_CREATE = "tranche:create",
	TRANCHES_TRANCHE_SCHEDULE_SEND = "tranche:send",
	TRANCHES_TRANCHE_SCHEDULE_WITHDRAW = "tranche:withdraw",
	TRANCHES_TRANCHE_SCHEDULE_REJECT = "tranche:reject",
	TRANCHES_TRANCHE_SCHEDULE_ACCEPT = "tranche:accept",
	TRANCHES_TRANCHE_SCHEDULE_ORDER = "tranche:order",
	TRANCHES_TRANCHE_SCHEDULE_ORDER_CONDITIONS_UPDATE = "tranche:update",
	TRANCHES_TRANCHE_SCHEDULE_GENERATE_SCHEDULE_FILE = "tranche:schedule-file",
	TRANCHES_TRANCHE_SCHEDULE_GENERATE_PAYMENT_ORDER_FILE = "tranche:payment-order-file",
	
	KPI_KPI_CREATE = "kpi:can-create",
	KPI_KPI_UPDATE = "kpi:can-edit",
	KPI_KPI_DELETE = "kpi:can-delete",
	KPI_KPI_READ = "kpi:can-view",
	KPI_DELEGACY_READ = "delegacy:can-manage-delegacy",
	KPI_REGISTRY_EXPORT_PDF = "registry:can-export-pdf",
	KPI_REGISTRY_READ = "registry:can-view-registry",
	KPI_REPORT_READ = "report:can-view",
	KPI_REPORT_CREATE = "report:can-report",
	KPI_REPORT_PECULARITY_UPDATE = "report:can-edit-pecularity",
	KPI_REPORT_UPDATE = "report:can-edit-report",
	KPI_REPORT_LABOR_RATE_UPDATE = "report:can-edit-labor-rate",
	KPI_REPORT_EVALUATION_REPORT_READ = "report:can-view-evaluation-report",
	
	HR_COLLEAGUE_READ = "employee:can-view-colleagues",
	HR_SUBORDINATE_READ = "employee:can-view-subordinaries",
	HR_KPI_READ = "employee:can-view-kpi",
	HR_FEEDBACK_READ = "employee:can-view-360",
	HR_DISCIPLINE_READ = "employee:can-view-discipline",
	HR_PROMISE_READ = "employee:can-view-promises",
	HR_EXPECTATION_READ = "employee:can-view-expectations",
	HR_EXPECTATION_CREATE = "employee:can-create-expectations",
	HR_STANDARD_READ = "employee:can-view-testingresults"
}
