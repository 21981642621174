import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import DocumentsFilter from "@/store/bod/modules/documents/types/documentsFilter";
import DocumentListItem from "@/store/bod/modules/documents/types/documentListItem";
import ApiDepartment from "@/api/bod/types/apiDepartment";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import ApiSection from "@/api/bod/types/apiSection";
import ApiBodUser from "@/api/bod/types/apiBodUser";

export default class DocumentsState implements IPageState {
	listing: ListingModel<DocumentListItem>;
	sorting: SortingModel<String>;
	paging: PagingModel;
	search: SearchModel;
	filter: DocumentsFilter;
	route: RouteState;
	isInitialized: boolean;
	isUsersLoading: boolean;
	isDepartmentsLoading: boolean;
	isSectionsLoading: boolean;
	isFinanceSchemesLoading: boolean;
	departments: ApiDepartment[];
	users: ApiBodUser[];
	sections: ApiSection[];
	financeSchemes: ApiFinanceScheme[];

	constructor(
		listing: ListingModel<DocumentListItem>,
		sorting: SortingModel<String>,
		paging: PagingModel,
		search: SearchModel,
		filter: DocumentsFilter,
		route: RouteState,
		isInitialized: boolean = false,
		isUsersLoading: boolean = false,
		isDepartmentsLoading: boolean = false,
		isSectionsLoading: boolean = false,
		isFinanceSchemesLoading: boolean = false,
		departments: ApiDepartment[] = [],
		users: ApiBodUser[] = [],
		sections: ApiSection[] = [],
		financeSchemes: ApiFinanceScheme[] = []
	)
	{
		this.listing = listing;
		this.sorting = sorting;
		this.paging = paging;
		this.search = search;
		this.filter = filter;
		this.route = route;
		this.isInitialized = isInitialized;
		this.isUsersLoading = isUsersLoading;
		this.isDepartmentsLoading = isDepartmentsLoading;
		this.isSectionsLoading = isSectionsLoading;
		this.isFinanceSchemesLoading = isFinanceSchemesLoading;
		this.departments = departments;
		this.users = users;
		this.sections = sections;
		this.financeSchemes = financeSchemes;
	}
}
