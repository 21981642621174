<template>
	<span class="text-subtitle-1 font-weight-medium mb-4 d-inline-block">
		<slot>{{ title }}</slot>
	</span>
</template>

<script>
export default {
	props: {
		title: String
	}
};
</script>
