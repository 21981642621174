<template>
	<div class="mb-6">
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: 16px">{{ label }}</v-subheader>
		<vue-dropzone :name="type"
					  id="dropzone"
					  v-if="!files.length"
					  :options="dropzoneOptions"
					  :useCustomSlot="true"
					  @vdropzone-file-added="handleFileAdded"
					  class="white rounded-lg pa-2 d-flex align-center flex-wrap justify-center frp-dropzone">
			<div class="d-flex align-center">
				<frp-icon src="ico_upload" :color="colors.grey.base" class="mr-2 mb-1"></frp-icon>
				<span class="grey--text">{{ title }}</span>
			</div>
			<div class="text-overline grey--text mt-1">{{ $t("fields.dropzone.description.maxFileWeight", { maxSize }) }}</div>
		</vue-dropzone>
		<div v-else>
			<v-row class="mt-1">
				<v-col class="d-flex align-start flex-column py-0">
					<div v-for="{ name } in files" :key="name" class="d-flex align-center" style="width: 100%">
						<bod-file deletable
								  :uploading="isLoading"
								  style="width: 100%"
								  class="text-break"
								  :name="name"
								  @delete="onDelete">
						</bod-file>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import { i18n } from "@/plugins";
import { BodController } from "Api/bod";
import ApiCreateBodFile from "Api/bod/types/apiCreateBodFile";
import BodFile from "Components/common/BodFile";
import AbortService from "Services/abortService";
import AlertHelper from "Store/modules/alerts/helpers/alertHelper";
import VueDropzone from "vue2-dropzone";
import colorsMixin from "Mixins/colorsMixin";
import FrpIcon from "Components/icon/FrpIcon";
import FrpTextField from "Components/fields/FrpTextField";

const DEFAULT_URL = "http://localhost";

const abortService = new AbortService();
const bodController = new BodController(abortService);

export default {
	mixins: [colorsMixin],
	props: {
		type: {
			type: String,
			required: true
		},
		maxSize: [Number, String],
		formats: Array,
		file: [File, Object],
		label: String,
		title: {
			type: String,
			default: i18n.t("fields.dropzone.title.uploadFile")
		}
	},
	data() {
		return {
			dropzoneOptions: {
				autoProcessQueue: false,
				url: DEFAULT_URL,
				maxFilesize: this.maxSize,
				acceptedFiles: "",
				maxFiles: 1
			},
			files: [],
			isLoading: false
		};
	},
	computed: {
		filename() {
			return this.file.name;
		}
	},
	methods: {
		async handleFileAdded(file) {
			this.isLoading = true;
			try {
				const { name, type } = file;
				this.files.push({ name });

				const res = await bodController.createTemperFile(new ApiCreateBodFile(file, name, type));

				this.$emit("file:add", res, type);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isLoading = false;
			}
		},
		onDelete(name) {
			this.files = this.files.filter(x => x.name !== name);
			this.$emit("file:delete", name);
		},
		setAcceptedFiles() {
			if(!this.formats)
				return;

			const res = [];
			this.formats.forEach(x => {
				switch (x) {
					case "bd":
						res.push(".bd*");
						break;
					case "pdf":
						res.push("application/pdf");
						break;
					case "doc":
						res.push("application/msword");
						break;
					case "docx":
						res.push("application/vnd.openxmlformats-officedocument.wordprocessingml.document");
						break;
				}
			});
			this.dropzoneOptions.acceptedFiles = res.join(",");
		},
		clear() {
			this.files = [];
		}
	},
	created() {
		abortService.initialize();
	},
	watch: {
		formats: {
			handler(formats) {
				this.setAcceptedFiles(formats);
			},
			immediate: true
		}
	},
	components: {
		BodFile,
		VueDropzone,
		FrpIcon,
		FrpTextField
	}
};
</script>
