import ApiBodUser from "@/api/bod/types/apiBodUser";

export default class UserState {
	user: ApiBodUser;
	isInitialized: boolean;
	isUserLoading: boolean;

	constructor(user: ApiBodUser = new ApiBodUser(), isInitialized: boolean = false, isUserLoading: boolean = false) {
		this.user = user;
		this.isInitialized = isInitialized;
		this.isUserLoading = isUserLoading;
	}
}
