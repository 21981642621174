import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import ApiDepartment from "@/api/bod/types/apiDepartment";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import ApiSection from "@/api/bod/types/apiSection";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import ChangeRequestsListItem from "@/store/bod/modules/requests/types/changeRequestsListItem";
import RequestsFilter from "@/store/bod/modules/requests/types/requestsFilter";

export default class RequestsState implements IPageState {
	listing: ListingModel<ChangeRequestsListItem>;
	sorting: SortingModel<String>;
	paging: PagingModel;
	search: SearchModel;
	filter: RequestsFilter;
	route: RouteState;
	isInitialized: boolean;
	isSectionsLoading: boolean;
	isUsersLoading: boolean;
	sections: ApiSection[];
	users: ApiBodUser[];

	constructor(
		listing: ListingModel<ChangeRequestsListItem>,
		sorting: SortingModel<String>,
		paging: PagingModel,
		search: SearchModel,
		filter: RequestsFilter,
		route: RouteState,
		isInitialized: boolean = false,
		isSectionsLoading: boolean = false,
		isUsersLoading: boolean = false,
		sections: ApiSection[] = [],
		users: ApiBodUser[] = []
	)
	{
		this.listing = listing;
		this.sorting = sorting;
		this.paging = paging;
		this.search = search;
		this.filter = filter;
		this.route = route;
		this.isInitialized = isInitialized;
		this.isSectionsLoading = isSectionsLoading;
		this.isUsersLoading = isUsersLoading;
		this.sections = sections;
		this.users = users;
	}
}
