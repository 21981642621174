import {
	STORAGE_APPOINTMENT_HEAD_ORDER,
	STORAGE_SIGNED_REQUEST,
	STORAGE_SNILS_NAMESPACE,
	STORAGE_INN_NAMESPACE,
	STORAGE_TRUST_NAMESPACE
} from "@/constants/storage";
import { CounterpartyType } from "@/types/CounterpartyType";
import { RouteNames } from "@/router/routes";
import { RouteNames as BarRouteNames } from "@/router/bar/routes";
import { RouteNames as TranchesRouteNames } from "@/router/tranches/routes";
import { TreasuryStatementPaymentsType } from "@/store/loan/modules/treasuryStatement/types/treasuryStatementPaymentsType";
import { CounterpartyEmployeeTrustStatus } from "@/types/CounterpartyEmployeeTrustStatus";
import { ChangeType } from "@/store/bod/modules/requests/types/changeType";
import { ChangeRequestStatusType } from "@/store/bod/modules/document/types/changeRequestStatusType";
import { SizeUnits } from "@/constants/sizeUnits";
import TroubledBorrowerFilterEventsType from "@/store/loan/modules/troubledBorrower/types/troubledBorrowerFilterEventsType";
import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { AgreementsOverduePaymentsModeType } from "@/store/loan/modules/agreementsOverduePayments/types/agreementsOverduePaymentsModeType";
import { TreasuryStatementOperationType } from "@/store/loan/modules/treasuryStatement/types/treasuryStatementOperationType";
import { PaymentOrderType } from "@/store/loan/modules/upcomingPayments/types/paymentOrderType";
import { BankAccountApplicationDocumentTypeEnum } from "@/store/bar/types/BankAccountApplicationDocumentTypeEnum";
import { TrancheScheduleStatusTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleStatusTypeEnum";
import { KpiStatusEnum } from "@/types/kpi/kpiStatusEnum";
import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";
import { DelegacyStatusEnum } from "@/types/kpi/delegacyStatusEnum";

export default {
	fields: {
		date: {
			placeholder: "Введите дату",
			label: "Дата"
		},
		numberPaymentOrder: {
			placeholder: "Введите номер",
			label: "№ п/п"
		},
		validUntilDate: {
			placeholder: "Введите дату",
			label: "Действительна до"
		},
		validFromDate: {
			placeholder: "Введите дату",
			label: "Действует с"
		},
		phone: {
			placeholder: "Введите номер телефона",
			label: "Номер телефона"
		},
		extensionPhoneNumber: {
			placeholder: "Введите добавочный номер телефона",
			label: "Добавочный номер телефона"
		},
		email: {
			placeholder: "Введите адрес электронной почты",
			label: "Адрес электронной почты"
		},
		simpleEmail: {
			placeholder: "Введите email",
			label: "Электронная почта"
		},
		password: {
			placeholder: "Введите пароль",
			label: "Пароль"
		},
		newPassword: {
			placeholder: "Введите пароль",
			label: "Новый пароль"
		},
		confirmedPassword: {
			placeholder: "Повторите пароль",
			label: "Подтвердите пароль"
		},
		inn: {
			placeholder: "Введите ИНН",
			label: "ИНН"
		},
		ogrn: {
			placeholder: "Введите ОГРН",
			label: "ОГРН"
		},
		ogrnip: {
			placeholder: "Введите ОГРНИП",
			label: "ОГРНИП"
		},
		kpp: {
			placeholder: "Введите КПП",
			label: "КПП"
		},
		opf: {
			placeholder: "Введите организационно-правовую форму",
			label: "Организационно-правовая форма"
		},
		snils: {
			placeholderInput: "Введите СНИЛС",
			placeholderChoose: "Выберите файл",
			label: "СНИЛС"
		},
		snilsFile: {
			label: "Файл СНИЛС",
			placeholder: "Выберите файл"
		},
		trust: {
			placeholder: "Выберите файл",
			label: "Доверенность"
		},
		signature: {
			placeholder: "Выберите файл",
			label: "ЭЦП"
		},
		defaultSignature: {
			label: "Подпись"
		},
		position: {
			placeholder: "Введите должность",
			label: "Должность"
		},
		username: {
			label: "Логин"
		},
		firstName: {
			placeholder: "Введите имя",
			label: "Имя"
		},
		lastName: {
			placeholder: "Введите фамилию",
			label: "Фамилия"
		},
		middleName: {
			placeholder: "Введите отчество",
			label: "Отчество"
		},
		personFullName: {
			placeholder: "Введите ФИО",
			label: "ФИО"
		},
		fullName: {
			placeholder: "Введите полное наименование",
			label: "Полное наименование"
		},
		shortName: {
			placeholder: "Введите краткое наименование",
			label: "Краткое наименование"
		},
		registerDate: {
			placeholder: "Введите дату регистрации организации",
			label: "Дата регистрации"
		},
		legalPersonRegisterDate: {
			placeholder: "Введите дату регистрации ИП",
			label: "Дата регистрации"
		},
		registerCountry: {
			placeholder: "Выберите страну регистрации",
			label: "Страна регистрации"
		},
		legalAddress: {
			placeholder: "Введите юридический адрес",
			label: "Юридический адрес"
		},
		comment: {
			placeholder: "Введите комментарий",
			label: "Комментарий"
		},
		year: {
			placeholder: "Выберите год",
			label: "Год"
		},
		description: {
			placeholderInput: "Введите описание",
			placeholderAdd: "Добавьте описание",
			label: "Описание"
		},
		reason: {
			placeholder: "Укажите причину",
			label: "Причина"
		},
		paymentDescription: {
			placeholder: "Напишите описание",
			label: "Назначение платежа"
		},
		paymentActiveDate: {
			placeholder: "Введите дату",
			label: "Дата, с которой задолженность становится срочной"
		},
		switch: {
			frp: {
				label: "РФРП"
			},
			leasingCompany: {
				label: "Лизинговая компания"
			},
			collateralExpertOrganisation: {
				label: "Экспертная организация по залогам"
			},
			vkmExpertCompany: {
				label: "Экспертная организация по ВКМ"
			},
			frpAssigneeCompany: {
				label: "Цессионарий"
			},
			enableFnsUpdate: {
				label: "Обновлять из Контур.Фокус по расписанию"
			}
		},
		headFullName: {
			placeholder: "Введите ФИО руководителя",
			label: "ФИО"
		},
		headPosition: {
			placeholder: "Введите должность руководителя",
			label: "Должность"
		},
		headInn: {
			placeholder: "Введите ИНН руководителя",
			label: "ИНН"
		},
		headSnils: {
			placeholderInput: "Введите СНИЛС руководителя",
			label: "СНИЛС"
		},
		employeeSnils: {
			placeholderInput: "Введите СНИЛС сотрудника",
			label: "СНИЛС"
		},
		titleDocument: {
			placeholder: "Выбрать",
			label: "Документ-основание"
		},
		version: {
			placeholder: "Выбрать",
			label: "Основание"
		},
		changeReason: {
			placeholder: "Выбрать",
			label: "Причина изменения графика"
		},
		number: {
			placeholder: "Введите номер",
			label: "Номер"
		},
		financeSource: {
			placeholder: "Выберите субсидию",
			label: "Субсидия"
		},
		amount: {
			placeholder: "Введите сумму",
			labelRUB: "Сумма, ₽"
		},
		quarter: {
			placeholder: "Выберите квартал",
			label: "Квартал"
		},
		accrualDate: {
			placeholder: "Выбрать дату",
			label: "Дата начисления"
		},
		paymentDate: {
			placeholder: "Выбрать дату",
			label: "Дата оплаты"
		},
		plannedPaymentDate: {
			placeholder: "Выбрать дату",
			label: "Дата платежа"
		},
		accountedDate: {
			placeholder: "Выбрать дату",
			label: "Дата передачи в бухгалтерию"
		},
		accrualType: {
			placeholder: "Выбрать тип",
			label: "Тип начисления"
		},
		period: {
			placeholder: "Выберите период",
			additionPlaceholder: "Период",
			label: "Период"
		},
		projectNumber: {
			placeholder: "Номер проекта"
		},
		agreementNumber: {
			placeholder: "Договор займа"
		},
		agreementStatus: {
			placeholder: "Статус договора"
		},
		allSelected: {
			label: "Отображать все отмеченные"
		},
		penaltyType: {
			placeholder: "Выбрать категорию",
			label: "Категории начисления штрафов/пеней"
		},
		accruedAmount: {
			placeholder: "Введите проценты",
			label: "Сумма процентов"
		},
		additionalInterest: {
			placeholder: "Введите проценты",
			label: "Дополнительная процентная ставка"
		},
		paymentExpense: {
			placeholder: "Выберите статью ДДС",
			label: "Статья ДДС"
		},
		borrower: {
			placeholder: "Выберите заёмщика",
			label: "Заёмщик"
		},
		treasuryBorrower: {
			placeholder: "Название заемщика / Номер проекта / Договор",
			label: "Заёмщик"
		},
		paymentsType: {
			placeholder: "Выбрать",
			label: "Тип записи"
		},
		dropzone: {
			title: {
				uploadFile: "Перенесите файл или нажмите для загрузки"
			},
			description: {
				maxFileWeight: "Максимальный вес файла {maxSize} Мб.",
				maxFileWeightWithoutMb: "Максимальный вес файла {maxSize}.",
				mb: "Мб",
				formats: "Форматы"
			}
		},
		barDropzone: {
			title: {
				uploadSinglePdfFile: "Выберите или перетащите сюда pdf файл",
				maxFileWeight: "(максимум {weight}Мб)"
			}
		},
		status: {
			placeholder: "Выберите статус",
			label: "Статус"
		},
		organization: {
			placeholder: "Выберите организацию",
			label: "Организация"
		},
		bank: {
			additionPlaceholder: "Банк",
			placeholder: "Выберите банк"
		},
		bankBranch: {
			placeholder: "Выберите филиал банка"
		},
		bankAccountNumber: {
			label: "Номер счета",
			placeholder: "Введите номер счета"
		},
		bik: {
			placeholder: "Введите БИК"
		},
		bankAccountType: {
			label: "Тип счета",
			placeholder: "Выберите тип счета"
		},
		currency: {
			label: "Валюта",
			placeholder: "Выберите валюту"
		},
		startAcceptDate: {
			placeholder: "Дата начала акцептов"
		},
		openedAtDate: {
			placeholder: "Дата открытия"
		},
		bankAccountControlDate: {
			placeholder: "Дата контроля"
		},
		endAcceptDate: {
			placeholder: "Дата окончания акцептов"
		},
		monitoringSystemConnection: {
			placeholder: "Выберите статус подключения"
		},
		conclusionDate: {
			placeholder: "Дата заключения"
		},
		contractNumber: {
			placeholder: "Номер"
		},
		openingDate: {
			label: "Дата открытия",
			placeholder: "Выберите дату открытия"
		},
		signStatus: {
			placeholder: "Статус подключения"
		},
		applicationStatus: {
			placeholder: "Любой статус"
		},
		roleInProject: {
			placeholder: "Выберите роль",
			label: "Роль в проекте"
		},
		obligedCompany: {
			placeholder: "Выберите организацию",
			label: "По обязательствам какого юр.лица"
		},
		requirement: {
			placeholder: "Выберите требование",
			label: "Требование"
		},
		event: {
			placeholder: "Выберите мероприятие",
			label: "Мероприятие"
		},
		relatedStatus: {
			placeholder: "Выберите статус",
			label: "Связанный статус"
		},
		judicialDecision: {
			placeholder: "Выберите решение суда",
			label: "Решение суда"
		},
		controlDate: {
			label: "Контрольная дата"
		},
		factualDate: {
			label: "Фактическая дата"
		},
		factualPenaltySum: {
			placeholder: "Введите сумму",
			label: "Фактическая сумма взыскания, руб."
		},
		organizationName: {
			placeholder: "Введите название",
			label: "Название организации"
		},
		responsibleUser: {
			placeholder: "Выберите сотрудника",
			label: "Ответственный"
		},
		responsiblePerson: {
			placeholder: "Выберите исполнителя",
			label: "Исполнитель"
		},
		responseMessage: {
			placeholder: "Кому"
		},
		messageText: {
			placeholder: "Ваш текст"
		},
		startDate: {
			label: "Дата действия с"
		},
		expirationDate: {
			label: "Дата окончания действия"
		},
		headPassportScanOrCertificateInn: {
			label: "Скан паспорта или свидетельства ИНН руководителя или уполномоченного лица, действующего от организации"
		},
		appointmentHeadOrderOrExtractFromEmployeeHiringOrderOrTrust: {
			label: "Приказ о назначении руководителя или выписка из приказа о приеме на работу сотрудника, действующего от организации, либо справка от работодателя/доверенность"
		},
		documentSearch: {
			placeholder: "Поиск документа"
		},
		financeSchemesSearch: {
			placeholder: "Все программы"
		},
		departmentSearch: {
			placeholder: "Подразделение ФРП"
		},
		supervisorSearch: {
			placeholder: "Руководитель"
		},
		documentSectionSearch: {
			placeholder: "Раздел документа"
		},
		activeOnly: {
			label: "Активные документы"
		},
		linkedToFinanceSchemes: {
			label: "Относится к программам ФРП"
		},
		isWeb: {
			label: "Публикация на сайте"
		},
		financingScheme: {
			label: "Программа",
			placeholder: "Выбрать"
		},
		supervisor: {
			label: "Лицо, акцептующее заявку",
			placeholder: "Выбрать"
		},
		department: {
			label: "Ответственное подразделение ФРП",
			placeholder: "Выбрать"
		},
		documentSection: {
			label: "Раздел",
			placeholder: "Выбрать"
		},
		documentTitle: {
			label: "Название документа",
			placeholder: "Введите название документа"
		},
		documentEditableFile: {
			label: "В редактируемом формате"
		},
		documentPdfFile: {
			label: "В формате pdf"
		},
		initiator: {
			label: "Инициатор",
			placeholder: "Инициатор"
		},
		acceptedOnly: {
			label: "Согласованные заявки"
		},
		createdDate: {
			label: "Дата создания"
		},
		changeRequestDescription: {
			placeholder: "Введите описание",
			label: "Суть изменения"
		},
		changeRequestType: {
			placeholder: "Выбрать",
			label: "Тип вносимого изменения"
		},
		changeWarrant: {
			placeholder: "Например: Приказ ОД-89 от 05.04.2023",
			label: "Основание"
		},
		changeWarrantFile: {
			placeholder: "Выберите файл",
			label: "Файл-основание"
		},
		executionDate: {
			placeholder: "Введите дату исполнения",
			label: "Дата исполнения"
		},
		queryTheme: {
			placeholder: "Введите тему запроса",
			label: "Тема запроса"
		},
		queryDescription: {
			placeholder: "Введите текст запроса",
			label: "Тема запроса"
		},
		webLink: {
			placeholder: "Введите текст",
			label: "Страница сайта"
		},
		declineReason: {
			placeholder: "Введите текст",
			label: "Причина отклонения"
		},
		project: {
			label: "Проект",
			placeholder: "Выберите проект"
		},
		controller: {
			placeholder: "Контролер"
		},
		declineExplanation: {
			placeholder: "Текст пояснения"
		},
		daysByPeriod: {
			label: "Дней за период"
		},
		employee: {
			placeholder: "Сотрудник"
		},
		criteria: {
			placeholder: "Критерий"
		},
		criteriaWeight: {
			placeholder: "Введите вес критерия"
		},
		staffEmployee: {
			placeholder: "Выберите сотрудников"
		},
		kpiDepartment: {
			placeholder: "Подразделение"
		},
		kpiDelegacyAppointer: {
			label: "Кто делегирует",
			placeholder: "Введите ФИО сотрудника или выберите из списка"
		},
		kpiFilterDelegacyAppointer: {
			placeholder: "Кто делегирует"
		},
		kpiDelegacyAttorney: {
			label: "Кому делегирует",
			placeholder: "Введите ФИО сотрудника или выберите из списка"
		},
		kpi: {
			placeholder: "Показатель"
		},
		methodology: {
			placeholder: "Методология"
		},
		kpiYear: {
			placeholder: "Год"
		},
		kpiQuarter: {
			placeholder: "Квартал"
		},
		kpiStatus: {
			placeholder: "Статус"
		},
		kpiCompletion: {
			placeholder: "100"
		},
		kpiJustification: {
			label: "Обоснование",
			placeholder: "Введите обоснование"
		},
		kpiPecularity: {
			label: "Особенность",
			placeholder: "Введите особенность"
		},
		kpiLaborRate: {
			label: "Значение КТУ",
			placeholder: "Введите значение"
		},
		hrFullName: {
			placeholder: "ФИО"
		},
		hrDepartment: {
			placeholder: "Подразделение"
		},
		documentChangeWarrant: {
			placeholder: "Введите основание",
			label: "Основание"
		},
		assignmentPeriod: {
			label: "Период расчета",
			placeholder: "Выберите период"
		},
		assignmentAgreement: {
			label: "Договор цессии",
			placeholder: "Выберите договор цессии"
		},
		assignee: {
			label: "Цессионарий",
			placeholder: "Выберите из списка"
		},
		calculatingDate: {
			label: "Дата расчета",
			placeholder: "Введите дату"
		},
		preferentialAmount: {
			label: "% по льготной ставке, ₽",
			placeholder: "Введите проценты"
		},
		compensableAmount: {
			label: "% по компенсируемой ставке, ₽",
			placeholder: "Введите проценты"
		}
	},
	links: {
		loanCashFlow: "Реестр ДДС",
		loanCashFlowActualPayments: "Реестр ДДС (фактические платежи)",
		loanSchedule: "График платежей ОД"
	},
	alerts: {
		errors: {
			loadExternalDataError: "Ошибка при получении данных",
			onSaveExternalError: "Возникла ошибка при сохранении данных",
			onCalibrationExternalError: "Возникла ошибка при запуске калибровки",
			onRequestError: "Возникла ошибка при отправке запроса",
			settings: {
				errorUpdate: "Ошибка изменения данных пользователя"
			},
			organisationAlreadyExists: "Организация с таким именем уже существует",
			legalEntityAlreadyExists: "Юридическое лицо с таким ИНН уже существует",
			legalPersonAlreadyExists: "Индивидуальный предприниматель с таким ИНН уже существует",
			physicalPersonAlreadyExists: "Физическое лицо с таким ИНН уже существует",
			requiredInnForLegalEntity: "Укажите ИНН для предзаполнения сведений из ЕГРЮЛ",
			requiredInnForLegalPerson: "Укажите ИНН для предзаполнения сведений из ЕГРИП",
			requiredHeadSnils: "Укажите СНИЛС руководителя",
			requiredSnilsForAddEmployeeTrust: "Для добавления доверенности укажите СНИЛС сотрудника",
			reloadTrust: {
				ifReload: "При повторной загрузке",
				current: "текущая",
				revokeTrust: "доверенность будет отозвана"
			},
			accessDenied: "Доступ запрещен",
			notRightsToViewPage: "Извините, у вас нет прав, чтобы просматривать эту страницу.",
			contactSysAdminOrReturnBack: {
				contact: "Обратитесь к вашему системному администратору или вернитесь на ",
				returnBack: "предыдущую страницу"
			},
			unexpectedError: "Неожиданная ошибка",
			somethingWrong: "Что-то пошло не так.",
			pageNotFound: "Страница не найдена",
			trustNotFound: "Доверенность не найдена",
			counterpartyNotFound: "Контрагент не найден",
			officeEmployeeNotFound: "Сотрудник фонда не найден",
			requestedPageNotFound: "Запрашиваемая страница не была найдена.",
			legalEntityByInnNotFound: "Не удалось найти организацию с ИНН {inn} в ЕГРЮЛ",
			masterAccountAccessApplicationExists: "Запрос на получение доступа к мастер-аккаунту уже был направлен и находится на рассмотрении. Результат будет направлен на указанный при подаче адрес электронной почты.",
			reportNotUploaded: "Отчетность не загружена",
			reportNotRequired: "Отчетность не требуется",
			reportGenerateError: "Ошибка генерации отчета",
			downloadDocumentsError: "Ошибка загрузки документов",
			counterpartyByLoanApplicationNumberNotFound: "Организация по данному номеру проекта не найдена",
			bankAccountNumberAlreadyExists: "Расчетный счет с таким номером счета уже существует",
			agreementNumberAlreadyJointByBankAccountNumber: "Данный проект уже привязан к введенному номеру счета",
			agreementNumberAlreadyExists: "Данный проект уже существует в текущем списке проектов",
			agreementNumberBelongsToAnotherCounterparty: "Данный проект относится к другой организации",
			bankAccountNumberHasPayments: "Расчетный счет с таким номером имеет привязанные платежи"
		},
		info: {
			noData: "Данные отсутствуют",
			kpiDeleted: "Показатель удален",
			onSuccessCreated: "Данные успешно сохранены",
			onSuccessUpdated: "Данные успешно обновлены",
			onSuccessUploaded: "Документ успешно загружен",
			onSuccessStartCalibration: "Калибровка успешно запущена",
			settings: {
				successUpdated: "Данные пользователя успешно обновлены"
			},
			inputSnilsOnAddTrust: {
				onAddTrust: "Для добавления доверенности укажите",
				inputSnils: "СНИЛС сотрудника"
			},
			organisationWasCreated: "Организация создана",
			youCanCloseCurrentBrowserPage: "Вы можете закрыть текущую страницу браузера",
			reportUploaded: "Отчетность загружена",
			isReportGenerating: "Идет генерация отчета, пожалуйста, подождите...",
			saveAllReportFiles: "Скачать все файлы отчетности",
			createRequest: "Создать запрос",
			executedRequests: "Исполнено, не исполнено / всего",
			requestEmailByConfirmationUploadReport: "Запросить email подтверждение загрузки отчётности",
			reminderForProvideOriginalDocuments: "Напоминаем, что в ФРП требуется предоставить оригиналы данных документов",
			tranchePaymentApplicationSuccessCreated: "Заявка на платеж по траншу № {number} успешно создана."
		},
		saveChangesAlert: {
			title: "Данные изменены",
			text: "Не забудьте сохранить их"
		}
	},
	buttons: {
		addDelegacy: "Добавить делегирование",
		passTest: "Сдать тест",
		scoreCard: "Оценочный лист",
		report: "Отчитаться",
		progressReport: "Отчет о выполнении",
		addKpi: "Добавить показатель",
		addPeriod: "Добавить период",
		currentQuarterReport: "Отчет по текущему кварталу",
		downloadExcel: "Выгрузка excel",
		downloadPdf: "Выгрузка pdf",
		decline: "Отклонить",
		setAccept: "Акцептовать",
		setReject: "Отклонить",
		sign: "Подписать",
		changeAccount: "Сменить аккаунт",
		addMoreUsers: "Добавить еще пользователей",
		goToPersonalAccount: "Перейти в личный кабинет",
		goToPersonalAccountShort: "Перейти в ЛК",
		clear: "Очистить",
		reset: "Cброс",
		clearAll: "Очистить все",
		accept: "Применить",
		apply: "Применить",
		choose: "Выбрать",
		signed: "Подписан",
		loadMore: "Загрузить еще",
		upload: "Загрузить",
		uploadWithSignature: "Загрузить c ЭЦП",
		acceptReporting: "Принять отчетность",
		cancel: "Отменить",
		edit: "Редактировать",
		declineTrust: "Отменить одобрение",
		cancelTrust: "Отклонить",
		addTrust: "Добавить доверенность",
		skip: "Пропустить",
		save: "Сохранить",
		sendForApproval: "Отправить на согласование",
		downloadTrustTemplate: "Скачать шаблон",
		close: "Закрыть",
		dontSave: "Не сохранять",
		ok: "Ок",
		backToList: "Назад к списку",
		add: "Добавить",
		remove: "Удалить",
		update: "Обновить",
		send: "Отправить",
		resend: "Отправить еще раз",
		sendLegalDepartmentRequest: "Отправить заявку",
		back: "Назад",
		next: "Далее",
		logout: "Выйти",
		personalArea: "Личный кабинет",
		signIn: "Вход",
		account: "Пользователь",
		goToHome: "На главную",
		goToAccount: "Перейти к учетной записи",
		profileUser: "Профиль пользователя",
		restoreAccountAccess: "Восстановить доступ",
		continue: "Продолжить",
		esiaSignIn: "Войти с помощью госуслуг",
		resetPassword: "Сбросить пароль",
		addCounterparty: "Добавить контрагента",
		addHead: "Добавить руководителя",
		addEmployee: "Добавить сотрудника",
		recognize: "Распознать",
		addOffice: "Добавить офис",
		confirm: "Подтвердить",
		indicate: "Указать",
		change: "Изменить",
		generate: "Сгенерировать",
		updateViaFns: "Обновить из Контур.Фокус",
		toInvite: "Пригласить",
		updateAndSendCredentials: "Сформировать и отправить новые учётные данные на почту",
		requestPhoneConfirmation: "Запросить подтверждение телефона",
		create: "Создать",
		createDraft: "Создать черновик",
		createVersion: "Создать версию",
		saveAsDraft: "Сохранить как черновик",
		saveAsVersion: "Сохранить как версию",
		addNote: "Добавить запись",
		addTranche: "Добавить транш",
		deleteNote: "Удалить запись",
		submitToAccounting: "Передать в бухгалтерию",
		sendToAccounting: "Отправить в бухгалтерию",
		calculateCompensation: "Рассчитать компенсацию",
		submitTo1C: "Передать в 1C",
		accrueInterest: "Начислить проценты",
		signWithDigitalSignature: "Подписать электронной подписью",
		signWithDigitalSignatureAbbr: "Подписать ЭЦП",
		download: "Скачать",
		addManually: "Добавить вручную",
		additionalInterest: "Дополнительные проценты",
		recalculateInterest: "Переначислить проценты",
		massEdit: "Массовое редактирование",
		addInterest: "Добавить проценты",
		addPayment: "Добавить платеж",
		isForcedPayment: "Принудительное взыскание",
		isCessionPayment: "Уступка",
		isReservePayment: "Резерв",
		importTreasuryStatement: "Импорт выписки",
		handleTreasuryStatement: "Обработать выписку",
		setTreasuryStatementRecordIsNotFinal: "Убрать п/п из обработанных",
		setTreasuryStatementRecordIsFinal: "Сохранить п/п",
		clearForm: "Очистить форму",
		finishProcessing: "Завершить обработку",
		more: "еще",
		addDebtor: "Добавить должника",
		addFinalGoal: "Добавить конечную цель",
		goToSign: "Перейти к подписанию",
		submitTrustOriginal: "Передать оригинал",
		search: "Поиск",
		generatePassport: "Генерация паспорта",
		digitalSignature: {
			isSigned: "Подтверждена с помощью ЭЦП руководителя",
			default: "Подтвердить с помощью ЭЦП руководителя"
		},
		goToAuthenticationPage: "Перейти на страницу аутентификации",
		addDocument: "Добавить документ",
		sendNewDocumentRequest: "Направить заявку на публикацию",
		sendChangeDocumentRequest: "Направить заявку на согласование",
		shareDocument: "Поделиться документом",
		unpublish: "Снять с публикации",
		createChangeRequest: "Заявка на изменение",
		rejectRequest: "Отклонить",
		acceptRequest: "Согласовать",
		goTo: "Перейти",
		troubledBorrowers: "Реестр проблемных заемщиков",
		signature: "ЭЦП",
		rollback: "Отмена изменений",
		clearFilter: "Очистить фильтры",
		bankAccountApplications: "Заявки на подключение РС",
		bankAccounts: "Реестр расчетных счетов",
		addBankAccount: "Добавить счет",
		addProject: "Добавить проект",
		gotToDocumentRequirements: "требования по документам",
		fullRepaymentRequest: "Заявка на полное погашение",
		generatePaymentOrder: "Сформировать ПП",
		accrueOverdue: "Вынести на просрочку",
		accruePenalties: "Начислить пени",
		appoint: "Назначить",
		answer: "Ответить",
		addOverdueOrder: "Сформировать распоряжение",
		submit: "Передать",
		bankAccountApplicationsListByProjectNumber: "Реестр заявок проекта",
		orderApplication: "Заявка на выдачу",
		goToUpload: "Перейти к загрузке",
		cancellation: "Отмена",
		accepted: "Акцептован",
		rejected: "Отклонен",
		withdraw: "Отозвать",
		editOrderConditions: "Редактировать условия выдачи"
	},
	common: {
		legalPerson: "ИП",
		certificatesLength: "Число сертификатов",
		noData: "Отсутствуют данные",
		masterAccountEmailFormText: {
			successText: "На указанный адрес электронной почты направлено письмо с доступом к мастер-аккаунту.",
			editModeText: "Укажите адрес электронной почты. На этот адрес будет направлено письмо с доступом к мастер-аккаунту."
		},
		accountEmailFormText: {
			changeEmailSuccessText: "На указанный адрес электронной почты отправлено письмо со ссылкой для подтверждения.",
			changeCredentialsSuccessText: "На указанный адрес электронной почты отправлено письмо с новыми данными для входа.",
			editModeText: "Укажите новый адрес электронной почты. На этот адрес будет направлено письмо со ссылкой для подтверждения или же новые учётные данные для входа."
		},
		masterAccountAccessSelectCounterpartyStep: {
			text: "Введите ИНН юридического лица или индивидуального предпринимателя"
		},
		masterAccountAccessContactInformationStep: {
			text: "На указанный адрес электронной почты будет направлен пароль для входа в мастер-аккаунт"
		},
		masterAccountAccessDocumentsStep: {
			toConfirmRightPerformActionsOnBehalfOf: "Для подтверждения права на выполнение действий от имени",
			legalEntity: "юридического лица",
			legalPerson: "индивидуального предпринимателя",
			attachDocumentsListBelow: "приложите перечень указанных ниже документов"
		},
		masterAccountAccessFinalStep: {
			isAccessReceived: "Данные для входа в мастер-аккаунта направлены на вашу почту. После аутентификации вы сможете управлять пользователями вашей организации.",
			default: "Данные для входа в мастер-аккаунт будут направлены на вашу почту после успешной проверки представленного пакета документов."
		},
		numbers: {
			adjectives: {
				"1": "Первый",
				"2": "Второй",
				"3": "Третий",
				"4": "Четвертый",
				"5": "Пятый",
				"6": "Шестой",
				"7": "Седьмой"
			}
		},
		emptyFileName: "Файл без названия",
		documentFromDate: "{number} от {date}",
		missing: "Отсутствует",
		tooltipTextSnils: "СНИЛС используется для проверки ЭЦП сотрудника",
		tooltipKpiCriteriaWeightText: "По этому периоду есть отчет, редактирование недоступно",
		inn: "ИНН",
		ogrn: "ОГРН",
		lastAssessmentHint: "Типы последней оценки: [д] – договор, [с] - решение суда, [т] - результат торгов, [п] - результат переоценки",
		inMillionRubles: "млн. руб",
		counterpartyIsMissing: "Отсутствует необходимый контрагент?",
		signTrustOptions: {
			title: "Вы можете подписать доверенность одним из указанных способов:",
			option1: {
				title: "1. “Подписать с ЭЦП”",
				text: "При выборе этого варианта в течение 2 рабочих дней сотрудники Фонда рассматривают доверенность. О результате рассмотрения пользователю приходит письмо на почту."
			},
			option2: {
				title: "2. “Передать оригинал”",
				text: "В случае, если у руководителя отсутствует возможность подписать доверенность ЭЦП, то для ее рассмотрения необходимо передать оригиналы документов в департамент сопровождения проектов ФРП курьером по адресу Москва, Лялин переулок, д. 6, стр.1 (в случае получения займа по федеральной программе) или по адресу Регионального ФРП (в случае получения займа по совместной программе)."
			}
		},
		uploadTrustHint: {
			title: "Загрузка доверенности",
			subtitle: "Для того, что ЭЦП сотрудника могла применяться пользователями организации для подписания отчетных документов, необходима доверенность",
			steps: "Что нужно сделать:",
			step1: "1. Заполнить шаблон доверенности, подписать у руководителя организации;",
			step2: "2. Подписанную доверенность необходимо отсканировать вместе с приложениями и загрузить;",
			step3: "3. После загрузки выбрать опцию: подписать ЭЦП руководителя или направить оригинал в ФРП или РФРП, если заем получен по совместной программе"
		},
		signatureIsMissing: "ЭЦП отсутствует",
		helpWorkWithDigitalSignature: "Справка по работе с ЭЦП",
		unPublished: "Документ снят с публикации",
		allFinanceSchemes: "Все программы",
		selected: "Выбрано",
		noComment: "Нет комментария",
		coFinanceRegister: "Реестр софинансирования",
		onSample: "по шаблону",
		year: "Год",
		uploaded: "Загружено",
		signed: "Подписано",
		fileFormat: "Формат файла",
		reportAccepted: "Отчет принят",
		accepted: "Согласовано",
		isAccepted: "Акцептовано",
		isDeclined: "Отклонено",
		awaitingAccept: "Ожидает согласования",
		declined: "Отклонено",
		responsibleUser: "Руководитель",
		with: "с",
		from: "от",
		to: "по",
		informationLetter: "Информационное письмо",
		informationLetterInfo: "Информационное письмо с указанием реквизитов расчетного счета в банке, открытого для обособленного учета средств по займу",
		informationLetterGenerateInfoLoan: "Сгенерированный файл скачайте, распечатайте, подпишите и загрузите",
		informationLetterGenerateInfoPersonalAccount: "Сгенерированный файл скачайте, распечатайте, подпишите и загрузите в Личном кабинете заявителя",
		forDate: "на",
		relevantFor: "По состоянию на",
		debtBalanceFor: "Остаток задолженности",
		estimatedInterestAfterFullRepayment: "Расчетные пени при полном погашении",
		isLegalDepartmentRequestSent: "Запрос в ЮД отправлен",
		upcomingPaymentsFileName: {
			paymentOrder: "Платежное поручение для оплаты {type}",
			[PaymentOrderType.SCHEDULE_MAIN_DEBT_REPAYMENT]: "основного долга",
			[PaymentOrderType.OVERDUE_MAIN_DEBT_REPAYMENT]: "основного долга",
			[PaymentOrderType.SCHEDULE_MAIN_DEBT_INTEREST_REPAYMENT]: "процентов",
			[PaymentOrderType.OVERDUE_MAIN_DEBT_INTEREST_REPAYMENT]: "процентов",
			[PaymentOrderType.OVERDUE_MAIN_DEBT_PENALTY_REPAYMENT]: "пеней",
			[PaymentOrderType.OVERDUE_MAIN_DEBT_INTEREST_PENALTY_REPAYMENT]: "пеней",
			[PaymentOrderType.LEGAL_PENALTY_REPAYMENT]: "пеней"
		},
		trancheScheduleGenerateInfo: "План-график траншей. Генерация станет доступна после согласование траншей менеджером проекта.",
		trancheScheduleGenerateInfoPersonalAccount: "Сгенерированный файл скачайте, распечатайте, подпишите и загрузите в Личном кабинете заявителя",
		trancheScheduleGeneratedFileName: "План-график траншей.docx",
		tranchePaymentApplicationGeneratedFileName: "Заявка на осуществление платежа",
		rub: "руб",
		examinationResults: "Результаты экспертиз по проектам, которые вынесены на ",
		recommendedForFinancing: "Рекомендован к финансированию",
		recommendedWithReservations: "Рекомендован с оговорками",
		notRecommendedForFinancing: "Не рекомендован к финансированию",
		examinationNotRequested: "Экспертиза не запрошена",
		examinationCreated: "Экспертиза создана",
		no: "Нет"
	},
	dialogs: {
		confirmCancelFormChanges: {
			title: "Отменить изменения?",
			description: "Последние изменения не были сохранены, данные могут быть утеряны."
		},
		confirmOperationWithUnsavedChanges: {
			title: "Изменения будут утеряны",
			description: "Последние изменения не были сохранены, выполнение операции приведет к их утере. Вы действительно хотите продолжить?"
		},
		confirmUnsavedChanges: {
			title: "Сохранить изменения?",
			description: "Форма содержит несохраненные изменения"
		},
		acceptUnappliedChanges: {
			title: "Предупреждение",
			description: "Система не сохранила некоторые из параметров",
			accept: "Понятно"
		},
		datePicker: {
			submit: "Подтвердить",
			cancel: "Отмена"
		},
		resetPassword: {
			title: "Сбросить пароль",
			description: "На указаную вами почту придет письмо с дальнейшими инструкциями по смене пароля"
		},
		wrongAmount: {
			title: "Выявлены несоответствия",
			frpSum: "Суммы займа",
			tranches: "Траншевого финансирования",
			schedule: "Графика возврата займа",
			assignmentSchedule: "Уступленных средств",
			part1: "При проверке сумм",
			part2: "и",
			part3: "были выявлены расхождения в данных. Пожалуйста, исправьте несоответствие и запустите процесс сохранения версии еще раз."
		},
		addAnOverduesNote: {
			title: "Добавить запись просроченной задолженности"
		},
		addLoanScheduleNote: {
			title: "Добавить запись графика возврата займа"
		},
		addTrancheFundingNote: {
			title: "Добавить запись траншевого финансирования"
		},
		deleteNote: {
			title: "Удалить запись",
			warning: "Вы действительно хотите удалить запись? Данное действие невозможно будет восстановить."
		},
		deletePayment: {
			title: "Удалить платеж",
			warning: "Вы действительно хотите удалить платеж? После нажатия кнопки “Удалить” отменить действие будет невозможно."
		},
		saveVersion: {
			title: "Сохранение версии",
			text: "Вы действительно хотите сохранить черновик как версию?"
		},
		saveDraft: {
			title: "Создание черновика"
		},
		addOverdueInterest: {
			title: "Добавить информацию о задолженности"
		},
		addPenaltyInterest: {
			title: "Добавить информацию о штрафе"
		},
		accrueInterest: {
			title: "Начислить проценты"
		},
		accruedInterest: {
			title: "Начислено процентов"
		},
		signOrder: {
			title: "Подписать распоряжение"
		},
		addInterest: {
			title: "Добавить проценты"
		},
		addPayment: {
			title: "Добавление платежа вручную"
		},
		additionalInterest: {
			title: "Дополнительное начисление процентов"
		},
		massEdit: {
			title: "Массовое редактирование"
		},
		importTreasuryStatement: {
			title: "Импорт выписки"
		},
		handleTreasuryStatement: {
			title: "Обработать выписку",
			text: "Вы действительно хотите завершить обработку платежей? После нажатия кнопки “Завершить обработку” все обработанные платежи будут сохранены в системе, а вернуться к результатам обработки станет невозможным."
		},
		compensationAmount: {
			title: "Размер компенсации",
			noDataText: "Для формирования распоряжения необходимо рассчитать компенсацию в системе."
		},
		addingPercentagesManually: {
			title: "Добавление процентов вручную"
		},
		documentSentToAccounting: {
			title: "Документ отправлен в бухгалтерию"
		},
		changesSaved: {
			title: "Изменения сохранены"
		},
		paymentEditing: {
			title: "Редактирование платежа"
		},
		versionCreating: {
			title: "Создание версии"
		},
		loanAgreements: {
			title: "Договоры займа"
		},
		addDebtor: {
			title: "Добавление должника"
		},
		updateDebtor: {
			title: "Редактирование должника"
		},
		debtorStatusHistory: {
			title: "История изменения статуса должника",
			noDataText: "История пуста"
		},
		debtorRequirementsHistory: {
			title: "История изменения требования должника",
			noDataText: "История пуста"
		},
		updateDebtorStatus: {
			title: "Редактирование статуса должника"
		},
		updateStatusComment: {
			title: "Редактирование комментария"
		},
		addRequirement: {
			title: "Добавление требования"
		},
		updateRequirement: {
			title: "Редактирование требования"
		},
		addEvent: {
			title: "Добавление мероприятия"
		},
		updateEvent: {
			title: "Редактирование мероприятия"
		},
		addFinalGoal: {
			title: "Добавление конечной цели"
		},
		updateFinalGoal: {
			title: "Редактирование конечной цели"
		},
		deleteDebtor: {
			title: "Удалить должника",
			text: "Вы уверены, что хотите удалить должника?"
		},
		deleteStatusHistoryItem: {
			title: "Удалить запись из истории статуса",
			text: "Вы уверены, что хотите удалить запись из истории изменения статуса?"
		},
		deleteEvent: {
			title: "Удалить мероприятие",
			text: "Вы уверены, что хотите удалить мероприятие?"
		},
		deleteFinalGoal: {
			title: "Удалить конечную цель",
			text: "Вы уверены, что хотите удалить конечную цель?"
		},
		deleteRequirement: {
			title: "Удалить требование",
			text: "Вы уверены, что хотите удалить требование?"
		},
		deleteRequirementHistoryItem: {
			title: "Удалить запись из истории требований",
			text: "Вы уверены, что хотите удалить запись из истории изменения требований?"
		},
		masterAccountExit: {
			title: "Сотрудник добавлен!",
			textPart1: "На почту сотрудника отправлено письмо с учетной записью для входа в личный кабинет для работы с проектами.",
			textPart2: "Если вы готовы приступить к заполнению заявки на займ, перейдите в личный кабинет по кнопке ниже, указав данные из почты."
		},
		declineTrust: {
			title: "Подтвердите отклонение доверенности"
		},
		uploadedDocuments: {
			title: "Загруженные документы"
		},
		uploadDocument: {
			title: "Загрузка документа"
		},
		generateReport: {
			title: "Генерация отчета"
		},
		acceptReport: {
			title: "Принять отчетность",
			text: "Вы действительно хотите принять отчетность?"
		},
		declineReport: {
			title: "Отменить принятую отчетность",
			text: "Вы действительно хотите отменить принятую отчетность?"
		},
		addQuery: {
			title: "Добавить запрос"
		},
		declineRequest: {
			title: "Отклонить заявку"
		},
		accrueOverdue: {
			title: "Вынести на просрочку",
			text: "Вы действительно хотите вынести на просрочку выбранные платежи?"
		},
		accruePenalties: {
			title: "Начислить пени",
			text: "Вы действительно хотите начислить пени по выбранным просроченным платежам?"
		},
		informationLetterGenerating: {
			title: "Генерация информационного письма"
		},
		bankAccountApplicationStatusHistory: {
			title: "История изменения статуса заявки",
			noDataText: "История пуста"
		},
		addNewAccountAgreements: {
			title: "Вы точно хотите добавить проекты?",
			text: "Действие нельзя будет отменить."
		},
		declineStatusExplanation: {
			title: "Пояснение при отклонении"
		},
		appointmentResponsiblePerson: {
			title: "Назначить ответственного"
		},
		addOverdueOrder: {
			mainDebtTitle: "Вынести на просрочку по Основному долгу",
			interestTitle: "Вынести на просрочку по Процентам",
			submitTitle: "Комментарий к уведомлению"
		},
		addPenaltyOverdueOrder: {
			mainDebtTitle: "Начислить пени по Основному долгу",
			interestTitle: "Начислить пени по Процентам",
			submitTitle: "Комментарий к распоряжению"
		},
		submitOverdueOrderToAccounting: {
			commentLabel: "Комментарий",
			commentPlaceholder: "Введите комментарий"
		},
		refundSourceHistory: {
			title: "История изменения источника возврата от заёмщика"
		},
		removeRefundSourceHistory: {
			title: "Удалить элемент истории",
			confirm: "Вы уверены, что хотите удалить элемент истории изменения источника возврата от заёмщика?"
		},
		incomePredictionHistory: {
			title: "История изменения прогноза поступлений",
			createdAt: "Дата и время изменения",
			quarter: "Квартал",
			conservativeValue: "Консервативный прогноз, руб.",
			optimisticValue: "Оптимистичный прогноз, руб.",
			createdBy: "Автор изменения"
		},
		addKpiPeriods: {
			title: "Добавить периоды"
		},
		refundStatusTypeHistory: {
			title: "История изменения Планового статуса возврата"
		},
		trancheScheduleGenerating: {
			title: "Генерация план-графика траншей"
		},
		tranchePaymentApplicationGenerating: {
			title: "Генерация заявки на платеж по траншу от {plannedDate} на сумму {amount}руб."
		},
		successSentTrancheScheduleForApprovalByManager: {
			title: "План-график траншей отправлен на согласование менеджеру проекта."
		},
		withdrawTrancheSchedule: {
			title: "Отозвать план-график траншей?"
		},
		acceptTrancheSchedule: {
			title: "Вы уверенны, что хотите акцептовать план-график траншей?"
		},
		rejectTrancheSchedule: {
			title: "Вы уверенны, что хотите отклонить план-график траншей?"
		},
		deleteKpi: {
			title: "Удалить показатель",
			warning: "Вы действительно хотите удалить показатель? Данное действие невозможно будет восстановить."
		},
		expectations: {
			title: "Заявки"
		},
		bankAccountAgreementTypeHistory: {
			title: "История изменений типа расчетного счета"
		},
		addDelegacy: {
			title: "Добавить делегирование"
		},
		deleteDelegacy: {
			title: "Завершение делегирования",
			text: "Вы действительно хотите завершить делегирование пользователю {user}?"
		}
	},
	errorsPages: {
		pageNotFound: "Что-то пошло не так, давайте попробуем сначала!",
		internalServer: "500 (Ошибка сервера)"
	},
	placeholders: {
		search: "Поиск",
		email: "email@mail.ru",
		password: "Введите пароль",
		request: "Введите запрос"
	},
	hints: {
		range: "Диапазон значений от {min} до {max}"
	},
	logs: {
		error: {
			requestError: "Ошибка выполнения запроса"
		},
		info: {
			request: "Запрос {url}",
			response: "Ответ {url}"
		}
	},
	navigation: {
		applications: {
			index: "Запросы и обращения",
			masterAccountAccess: "Доступ к мастер-аккаунту",
			employeeJoin: "Прикрепление к организации"
		},
		admin: {
			index: "Администрирование",
			counterparties: "Контрагенты",
			counterparty: "Контрагент",
			office: "Фонд",
			accounts: "Пользователи"
		},
		finance: {
			title: "Финансовый блок"
		},
		procuring: {
			title: "Обеспечение"
		},
		support: {
			title: "Техподдержка"
		},
		common: {
			legalEntity: "Организации",
			users: "Пользователи"
		},
		kpi: {
			kpi: "KPI",
			reporting: "Отчет по KPI",
			delegacy: "Делегирование"
		},
		hr: {
			own: "Мой HR",
			employees: "Сотрудники ФРП"
		},
		breadcrumbs: {
			[RouteNames.ROOT]: "Главная",
			[RouteNames.COUNTERPARTIES]: "Контрагенты",
			[RouteNames.COUNTERPARTY_CREATE]: "Добавление контрагента",
			[RouteNames.COUNTERPARTY_EMPLOYEES]: "Сотрудники",
			[RouteNames.COUNTERPARTY_MASTER_ACCOUNT]: "Мастер-аккаунт",
			[RouteNames.COUNTERPARTY_EMPLOYEE_CREATE]: "Добавление сотрудника",
			[RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS]: "Доверенности",
			[RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE]: "Профиль",
			[RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE]: "Новая доверенность",
			[RouteNames.OFFICE]: "Фонд",
			[RouteNames.OFFICE_EMPLOYEES]: "Сотрудники",
			[RouteNames.OFFICE_EMPLOYEE_CREATE]: "Новый сотрудник",
			[RouteNames.OFFICE_EMPLOYEE_PROFILE]: "Профиль",
			[RouteNames.APPLICATIONS]: "Запросы",
			[RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES]: "Запросы на получение доступа к мастер-аккаунту",
			[RouteNames.APPLICATIONS_EMPLOYEE_JOIN_LIST]: "Запросы на присоединение к организации",
			[RouteNames.PROFILE_ASSIGNMENT]: "Назначение профиля",
			[RouteNames.ACCOUNTS]: "Пользователи",
			[RouteNames.ACCOUNT_PROFILES]: "Профили"
		},
		barBreadcrumbs: {
			[BarRouteNames.ROOT]: "Главная",
			[BarRouteNames.BANK_ACCOUNT_APPLICATIONS_LIST]: "Реестр заявок",
			[BarRouteNames.BANK_ACCOUNT_APPLICATION]: "Карточка заявки №{applicationNumber} от {createdAt} на подключение расчетного счета",
			[BarRouteNames.BANK_ACCOUNT_APPLICATION_UPDATE]: "Изменение карточки заявки №{applicationNumber} от {createdAt} на подключение расчетного счета",
			[BarRouteNames.BANK_ACCOUNT_APPLICATION_CREATE]: "Новая заявка на подключение расчетного счета",
			[BarRouteNames.BANK_ACCOUNTS_LIST]: "Реестр счетов",
			[BarRouteNames.BANK_ACCOUNT]: "Детальный просмотр карточки расчетного счета",
			[BarRouteNames.BANK_ACCOUNT_CREATE]: "Новый счет"
		},
		tranchesRouteNames: {
			[TranchesRouteNames.ROOT]: "Оформление ДЗ",
			[TranchesRouteNames.TRANCHES_TRANCHE_APPLICATION]: "Траншевое финансирование"
		},
		documents: "Реестр документов",
		requests: "Реестр заявок на изменение",
		otherModules: "Иные модули"
	},
	subheaders: {
		general: "Сведения",
		profile: "Профиль",
		profiles: "Профили",
		trusts: "Доверенности",
		employees: "Сотрудники",
		masterAccount: "Мастер-аккаунт",
		head: "Руководитель",
		organization: "Сведения об организации",
		employee: "Сведения о сотруднике",
		changeEmail: "Изменение адреса электронной почты",
		fieldFilter: "Фильтр по полю",
		setValue: "Установить значение",
		project: "Проект",
		mainInfo: "Основные данные",
		additionalInfo: "Дополнительные данные",
		document: "Документ",
		changeHistory: "История изменения",
		changeDocumentRequest: "Заявка по документу",
		forPeriod: "За {quarter} квартал {year} г.",
		forQuarter: "За {quarter} квартал"
	},
	titles: {
		general: "Сведения",
		trust: "Доверенность",
		trusts: "Доверенности",
		profile: "Профиль",
		profiles: "Профили",
		roles: "Роли",
		applications: "Список заявок",
		counterparties: "Контрагенты",
		users: "Пользователи",
		characteristics: "Признаки",
		fns: "Контур.Фокус",
		createEmployee: "Добавление сотрудника",
		createTrust: "Добавление доверенности",
		employee: "Сотрудник",
		employees: "Сотрудники",
		masterAccount: "Мастер-аккаунт",
		offices: "Офисы",
		head: "Руководитель",
		inviteAnEmployee: "Приглашение сотрудника",
		inviteToJoinAnOrganization: "Запрос на прикрепление к организации",
		invitesToJoinAnOrganization: "Запросы на присоединение к организации",
		requestForAccessToMasterAccount: "Запрос на получение доступа к мастер-аккаунту",
		requestsForAccessToMasterAccount: "Запросы на получение доступа к мастер-аккаунту",
		appealToDigitalSignature: "Обращение к электронной подписи",
		chooseDigitalSignature: "Выберите электронную подпись",
		loanAgreement: "Договор займа",
		loanAgreements: "Договоры займа",
		payments: "Фактическое движение денежных средств",
		overdues: "Просроченная задолженность",
		tranches: "Траншевое финансирование",
		loanSchedule: "График возврата займа",
		industrialDevelopmentOffice: "Фонд развития промышленности",
		signatureGeneral: "Сведения ЭЦП",
		informationAboutSignature: "Сведения о подписи",
		documentEditing: "Редактирование документа",
		interestByPeriod: "Проценты за период",
		overdueInterest: "Просроченная задолженность по процентам",
		penaltyInterest: "Реестр начисленных штрафов/пеней",
		accruedInterest: "Реестр начисленных процентов",
		treasuryStatementRecords: "Платежные поручения",
		treasuryStatementPayments: "Платежи",
		securityAgreements: "Обеспечение",
		productionStatus: "Статус производства в разрезе должников",
		debtorRequirements: "Реестр требований",
		attachDocumentsList: "Приложите перечень документов",
		enterContactInformation: "Укажите контактную информацию",
		enterInnYourOrganization: "Введите ИНН вашей организации",
		masterAccountAccessApplication: {
			legalEntityCounterpartyType: "Подтвердите правильность сведений о юридическом лице",
			legalPersonCounterpartyType: "Подтвердите правильность сведений об индивидуальном предпринимателе"
		},
		publicDocuments: "База общедоступных документов",
		createNewDocumentRequest: "Заявка на создание нового документа",
		document: "Карточка документа",
		newDocument: "Новый документ",
		requestAccepting: "Согласование заявки",
		createUpdateDocumentRequest: "Заявка на изменение",
		createUnpublishDocumentRequest: "Заявка на снятие с публикации",
		createRollbackDocumentRequest: "Заявка на отмену изменений",
		requests: "Реестр заявок на изменение документов",
		troubledBorrower: "Паспорт проблемного заемщика",
		troubledBorrowers: "Реестр проблемных заемщиков",
		assignmentCompensation: "Расчет размера компенсации за период",
		reports: "Отчетные документы",
		reportsFinal: "Отчетные документы по итоговому отчету",
		bankAccountInformation: "Информация о счете",
		bankAccountApplications: "Заявки",
		projectsInfo: "Информация о проектах",
		control: "Контроль",
		organizationData: "Данные организации",
		bank: "Банк",
		bankAccounts: "Счета",
		applicationInformation: "Информация о заявке",
		informationLetter: "Генерация",
		documents: "Документы",
		legalDepartmentRequest: "Запрос в ЮД",
		correspondenceInsideFrp: "Переписка внутри ФРП",
		plannedPayments: "Плановые платежи",
		mainDebt: "Основной долг",
		percents: "Проценты",
		penalty: "Пени и штрафы",
		otherPenalty: "Судебные",
		refundSources: "Источник возврата от заёмщика",
		incomePrediction: "Прогноз поступлений",
		costs: "Учет затрат",
		trancheSchedule: "Траншевое финансирование",
		generation: "Генерация",
		projectInfo: "Информация о проекте",
		trancheSchedulePlan: "План-график траншей",
		standardAwareness: "Тестирование по стандартам",
		employeeDiscipline: "Исполнительская дисциплина",
		employeeFeedback: "Оценка 360",
		employeeKpi: "Выполнение KPI",
		colleagues: "Коллеги",
		subordinates: "Подчиненные",
		promises: "Задачи",
		expectations: "Заявки",
		usefulInfo: "Полезная информация",
		delegacy: "Делегирование прав"
	},
	metaTitles: {
		loan: "Кабинет заемщика",
		loanSchedule: "График платежей",
		accruedInterest: "Реестр начисленных процентов",
		overdueInterest: "Просроченные проценты и пени",
		agreementsAccruedInterest: "Начисление процентов",
		cashFlow: "Реестр ДДС",
		treasuryStatement: "Загрузка выписки УФК",
		troubledBorrower: "Паспорт проблемного заемщика",
		troubledBorrowers: "Реестр проблемных заемщиков",
		bodDocuments: "База общедоступных документов",
		createNewDocumentRequest: "Заявка на создание нового документа",
		document: "Карточка документа",
		reports: "Отчетные документы",
		finalReports: "Отчетные документы по итоговому отчету",
		bankAccountsRegister: "Реестр расчетных счетов",
		bankAccountApplications: "Реестр заявок на подключение к расчетным счетам",
		newBankAccountApplication: "Новая заявка на подключение расчетного счета",
		updateBankAccountApplication: "Изменение карточки заявки №{applicationNumber} от {createdAt} на подключение расчетного счета",
		bankAccountApplication: "Карточка заявки №{applicationNumber} от {createdAt} на подключение расчетного счета",
		newBankAccount: "Новый счет",
		bankAccount: "Детальный просмотр карточки расчетного счета",
		upcomingPayments: "Ближайшие платежи по договору займа",
		agreementsOverduePayments: "Перенос на просрочку",
		agreementsAccruedPenalties: "Начисление пеней",
		assignmentCompensation: "Расчет размера компенсации",
		tranches: "Траншевое финансирование",
		kpiCard: "Карточка показателя",
		kpiCardCreate: "Добавление показателя",
		kpisByYear: "Реестр KPI за {year} год",
		reportingByYear: "Отчет по KPI за {year} год",
		reportingByQuarter: "Отчет по KPI за {quarter} квартал {year} года",
		kpis: "Реестр KPI",
		reporting: "Отчет",
		delegacy: "Делегирование",
		frpEmployees: "Сотрудники ФРП"
	},
	content: {
		filter: "Фильтр",
		chooseType: "Выберите тип",
		enterGeneral: "Укажите сведения",
		lastUpdateTime: "Время последнего обновления:",
		generalNotUpdated: "сведения не обновлялись",
		availableAfterSaving: "Доступно после сохранения",
		hasAccessToLoanAgreements: "Имеет доступ к договорам займа",
		expireAt: "Доверенность до",
		owner: "Владелец",
		verificationCenter: "Удостоверяющий центр",
		from: "от",
		documentVersion: "Версия документа",
		titleDocument: "Документ основания",
		reasonForScheduleChange: "Причина изменения графика",
		comment: "Комментарий",
		withoutComments: "Без комментариев",
		noNotes: "Нет записей",
		noMessages: "Нет сообщений",
		noApplications: "Нет заявок",
		total: "Итого",
		allInAll: "Всего",
		remainingDebt: "Остаток задолженности по ДЗ",
		remainingAssign: "Остаток задолженности по ДЦ",
		loanScheduleVersionsNotFound: "Не найдено версий графика возврата займа",
		uploadScanOfSnilsDocumentAnHeadOfOrganization: "Загрузите скан документа СНИЛС руководителя организации",
		uploadScanOfSnilsDocumentAnEmployeeOfOrganization: "Загрузите скан документа СНИЛС сотрудника организации",
		youCanDownloadTrustTemplateInWordFormat: "Вы можете скачать шаблон доверенности в формате word",
		isProblem: "Является проблемой",
		agreementNumber: "Номер договора",
		noDebtors: "У вас нет должников",
		chooseModuleToContinue: "Выберите модуль для продолжения",
		atypicalContractCoordination: "Согласование нетипового соглашения",
		immediateRepaymentIsRequired: "Требуется незамедлительное погашение",
		needAcrrueMainDebtOverdue: "Необходимо вынести на просрочку по Основному долгу",
		needAcrrueInterestOverdue: "Необходимо вынести на просрочку по Процентам",
		needAcrrueMainDebtPenalties: "Необходимо начислить пени за просрочку по Основному долгу",
		needAcrrueInterestPenalties: "Необходимо начислить пени за просрочку по Процентам",
		noOverduePayments: "Просроченных платежей не найдено",
		noPenaltyPayments: "Пени начислены",
		status: "Статус",
		finalAward: "Итоговая премия",
		testingRate: "Тестирование",
		laborRate: "КТУ",
		workingRate: "КОВ",
		reservedTotalAmount: "Резерв",
		phone: "Тел.",
		processingTasks: "Задач в работе",
		overdueTasks: "Задач просроченно",
		bitrix: "Битрикс 24",
		expectations: {
			titles: {
				dmkp: "ДМКП",
				legalDepartment: "ЮД",
				it: "ДАИТ",
				def: "ДЭФ",
				drp: "Департамент по работе с персоналом"
			},
			links: {
				borrowerSettlementsRequest: "Направить запрос по расчетам заемщика",
				lawyersRequest: "Направить запрос юристам",
				itTechnicalSupport: "Обратиться за технической поддержкой по информационным системам",
				vendorContractPaymentRequest: "Подать заявку на платеж по договору с поставщиком",
				internshipRequest: "Подать заявку на стажировку",
				vacationRequest: "Сходить в отпуск",
				businessTripRequest: "Съездить в командировку"
			}
		},
		hrUsefulLinks: {
			welcomebook: "Welcomebook",
			personnelDocumentTemplates: "Шаблоны кадровых документов",
			informationSystems: "Информационные системы",
			organizationalStructure: "Организационная структура Фонда",
			faq: "Частые вопросы",
			billboard: "Афиша ФРП",
			codex: "Кодекс ФРП",
			callsDetails: "Детализация звонков"
		},
		profile: {
			email: "Почта",
			innerPhone: "Внутренний номер",
			mobilePhone: "Мобильный номер",
			status: "Статус",
			employmentDate: "В команде Фонда с",
			department: "Подразделение",
			head: "Руководитель",
			vacationRemainingDays: "Доступно дней отпуска"
		},
		birthday: "День рождения",
		reportingDownloadBulkConfiguration: {
			allFilesVersions: "Все версии файлов",
			onlyLastFilesVersions: "Только последние версии файлов"
		}
	},
	validation: {
		inn: "Неверный ИНН",
		snils: "Неверный СНИЛС",
		snilsFormat: "Не верный формат файла СНИЛС",
		ogrn: "Неверный ОГРН",
		ogrnip: "Неверный ОГРНИП",
		kpp: "Неверный КПП",
		validPassword: "Неверный пароль",
		validPhone: "Неверный формат телефона",
		required: "Поле не может быть пустым",
		maxNumber: "Не более {number}",
		minNumber: "Не менее {number}",
		maxDate: "Не позднее {number}",
		minDate: "Не раньше {number}",
		validOnlyNumbers: "Поле принимает только числа",
		validOnlyIntegerNumbers: "Поле принимает только целые числа",
		validIpAddress: "Неверный формат адреса",
		invalidDate: "Неверная дата",
		validEmail: "Неверный формат почты",
		validConfirmedEmail: "Адреса электронной почты не совпадают",
		emailAlreadyUsedInAccount: "Указанный адрес уже используется в учётной записи",
		accountWithThisEmailIsExist: "Аккаунт с таким email уже существует",
		maxLength: "Максимальное количество символов: {number}",
		minLength: "Минимальное количество символов: {number}",
		bankAccountNumber: "Неверный номер счета",
		correspondentAccount: "Неверный корреспондентский счет",
		bankIdentificationCode: "Неверный БИК"
	},
	tables: {
		delegacyDepartment: "Подразделение",
		appointer: "Кто делегирует",
		attorney: "Кому делегирует",
		loading: "Загрузка данных...",
		createdAt: "Дата добавления",
		expireAt: "Действительна до",
		ogrn: "ОГРН",
		inn: "ИНН",
		kpp: "КПП",
		type: "Тип",
		projects: "Проекты",
		projectsAndType: "Проекты и тип",
		phone: "Номер телефона",
		status: "Статус",
		fullName: "ФИО",
		heads: "ФИО руководителя",
		title: "Наименование",
		login: "Логин",
		password: "Пароль",
		email: "Email",
		position: "Должность",
		number: "Номер",
		acceptDate: "Дата акцептования",
		isSigned: "ЭЦП",
		roles: "Роли",
		description: "Описание",
		financeSource: "Субсидия",
		date: "Дата",
		dateFact: "Дата (факт)",
		quarter: "Квартал",
		amountRUB: "Сумма, ₽",
		isProblem: "Является проблемной",
		comment: "Комментарий",
		plannedRepaymentAmount: "Плановая сумма погашения",
		issuanceLoanRUB: "Выдача займа, ₽",
		actualRepaymentAmountRUB: "Фактическая сумма погашения, ₽",
		withdrawalForDelay: "Вынос на просрочку",
		paymentOfDelay: "Уплата просрочки",
		assignedLoan: "Уступлено банку",
		assignedMainDebt: "Погашение займа банку",
		consider: "Учитывать",
		borrower: "Заемщик",
		agreement: "Договор",
		currentStatus: "Текущий статус",
		daysByPeriod: "Дней за период",
		accruedAmount: "Начислено (сумма), ₽",
		accountedAmount: "Передано в бухгалтерию, ₽",
		accountedDate: "Дата передачи в бухгалтерию",
		accrualType: "Тип начисления",
		accrualDate: "Дата начисления",
		paymentDate: "Дата оплаты",
		plannedPaymentDate: "Дата платежа",
		accruedAmountSum: "Сумма процентов",
		numberPaymentOrder: "№п/п",
		sectionNumber: "№ Раздела",
		numberOrder: "№ п/п",
		sectionName: "Наименование раздела",
		documentName: "Наименование документа",
		upload: "Загрузка",
		uploaderFullName: "ФИО загрузившего",
		uploadDate: "Дата загрузки",
		signerFullName: "ФИО подписавшего",
		paymentExpense: "Статья ДДС",
		isForcedPayment: "Принудительное взыскание",
		paymentDescription: "Назначение платежа",
		trust: "Доверенность",
		pledges: "Залоги",
		securityKindAndLocation: "Вид обеспечения [местонахождения: субъект РФ]",
		guarantor: "Обеспечитель",
		agreementValue: "Стоимость по договору, млн. руб.",
		lastAssessmentDateAndType: "Дата последней оценки [тип проведенной оценки]",
		lastValuation: "Стоимость по последней оценке, млн. руб.",
		event: "Мероприятие",
		controlDate: "Контрольная дата",
		dateOfControl: "Дата контроля",
		factualDate: "Фактическая дата",
		organizationShortName: "Сокращенное название организации",
		loanAgreements: "Договоры займа",
		frpSum: "Сумма ДЗ, млн. руб.",
		requirements: "Требования",
		fullRefundStatus: "Полный возврат",
		responsibleUser: "Ответственный",
		newTroubledBorrowerPassportGeneration: "Генерация",
		decliner: "Отклонил",
		documentSection: "Раздел",
		financingScheme: "Программа",
		documentTitle: "Название документа",
		responsibleDepartment: "Ответственное подразделение ФРП",
		documentOnDisk: "Документ",
		share: "Поделиться",
		createdDate: "Дата создания",
		initiator: "Инициатор",
		requestType: "Тип заявки",
		changeWarrant: "Основание для изменения",
		changeDescription: "Суть изменения",
		isPropertySold: "Имущество реализовано",
		accountNumber: "Номер счета",
		organizationName: "Название организации",
		project: "Проект",
		bank: "Банк",
		responsiblePerson: "Ответственный",
		currency: "Валюта",
		accountCurrency: "Валюта счета",
		application: "Заявка",
		applicationStatus: "Статус заявки",
		accountType: "Тип счета",
		openingDate: "Дата открытия",
		dateMainDebt: "Ближайшая дата оплаты",
		period: "Период",
		balancePrevPercent: "Долг(+) / аванс(-)",
		datePayment: "Плановая дата оплаты",
		accruedPercent: "Расчетные проценты с учетом графика платежей ОД",
		paymentPrevPercent: "Оплачено за период",
		paymentSum: "Сумма к оплате",
		periodDays: "Количество дней в периоде",
		accrualDays: "Количество дней для расчета процентов",
		accruedInterestSum: "Сумма расчетных процентов",
		dayOver: "Количество дней непрерывной просрочки",
		category: "Категория",
		projectNumber: "Номер проекта",
		plannedOverduePaymentDate: "Плановая дата оплаты",
		plannedPaymentSum: "Плановая сумма оплаты",
		overdue: "Вынести на просрочку",
		overdueDays: "Количество дней просрочки",
		overdueSum: "Сумма просрочки",
		rate: "Ставка",
		accruedPenalties: "Начислить пени",
		incomePredictions: {
			conservativeValue: "Консервативный прогноз, руб.",
			optimisticValue: "Оптимистичный прогноз, руб."
		},
		costs: {
			actualAmount: "Фактическое значение, руб",
			plannedAmount: "Плановое значение, руб"
		},
		numberSymbol: "№",
		trancheSum: "Сумма транша",
		plannedOrderDate: "Плановая дата выдачи",
		orderApplication: "Заявка на выдачу",
		orderDate: "Дата заявки на выдачу",
		orderCondition: "Условие выдачи",
		projectManager: "Менеджер проекта",
		requestId: "Номер заявки",
		projectName: "Название проекта (организация)",
		loanTotal: "Сумма займа тыс.руб",
		financialExpert: "Финансовый аналитик",
		nte: "НТЭ",
		pte: "ПТЭ",
		pre: "ПЭ",
		fie: "ФЭЭ",
		zle: "ЗЭ",
		minprom: "МПТ",
		mainConditions: "ОУ",
		bankGuarantee: "БГ",
		risks: "Риски",
		complianceExpertiseNational: "ЭСПНЦ",
		complianceExpertise: "Экс. соотв.программы и обеспечения",
		fundPosition: "Позиция Фонда по проекту",
		staff: "Сотрудник",
		periods: "Периоды",
		kpiDepartment: "Отдел / департамент",
		criteria: "Критерий",
		criteriaWeightInPercents: "Вес критерия в %",
		quarter1: "I кв",
		quarter2: "II кв",
		quarter3: "III кв",
		quarter4: "IV кв",
		year: "Год",
		kpi: "Показатель",
		weightInPercents: "Вес в %",
		completion: "Процент выполнения",
		justification: "Обоснование",
		department: "Подзразделение",
		innerPhone: "Внутренний номер",
		workMobilePhone: "Рабочий мобильный",
		hrEmail: "Почта",
		hrPhone: "Телефон",
		vacationRemainingDays: "Доступно дней отпуска",
		hrKpi: "KPI",
		hrDiscipline: "Исп.дисциплина",
		hrStandardAwareness: "Тестирование по стандартам",
		assignmentAgreement: "Договор цессии",
		preferentialAmount: "% по льготной ставке, ₽",
		compensableAmount: "% по компенсируемой ставке, ₽",
		compensationAmount: "Размер компенсации, ₽"
	},
	details: {
		titles: {
			general: "Сведения",
			officeGeneral: "Сведения о фонде",
			employeeGeneral: "Сведения о сотруднике",
			legalEntityGeneral: "Сведения об организации",
			legalPersonGeneral: "Сведения об индивидуальном предпринимателе",
			status: "Статус",
			name: "Наименование",
			fullName: "Наименование",
			fullPersonName: "ФИО",
			position: "Должность",
			snils: "СНИЛС",
			ogrn: "ОГРН",
			kpp: "КПП",
			inn: "ИНН",
			phone: "Номер телефона",
			legalAddress: "Юридический адрес",
			requestorEmail: "Контактный адрес электронной почты",
			email: "Email",
			commonData: "Общие данные",
			documentsList: "Перечень документов",
			snilsFile: "Файл СНИЛС",
			owner: "Владелец",
			certificate: "Сертификат",
			expireDate: "Действителен до",
			interestPeriod: "Процентный период",
			loanAgreementsCount: "Количество договоров займа",
			totalSum: "Общая сумма",
			interestAccruedAmount: "Доначислено процентов на сумму",
			treasuryStatementRecordsCount: "Всего документов",
			treasuryStatementOrderRecordSum: "Итого поступления",
			treasuryStatementPaymentRecordSum: "Итого выплаты",
			treasuryStatementFinalPaymentsSum: "Итого обработанные",
			treasuryStatementNotFinalPaymentsSum: "Итого необработанные",
			treasuryStatementRecord: "Платежное поручение",
			treasuryStatementRecordDocument: "{number} от {date}",
			counterparty: "Контрагент",
			kbk: "КБК",
			paymentSum: "Сумма",
			paymentPurpose: "Назначение платежа",
			borrower: "Заемщик",
			financingScheme: "Программа",
			loanAgreementsFrpSum: "Сумма ДЗ, млн. руб.",
			loanAgreementsRequirements: "Требования, млн. руб.",
			loanAgreementsMainDebt: "Основной долг",
			loanAgreementsInterest: "Проценты",
			loanAgreementsPenalty: "Неустойка",
			refund: "Возврат средств",
			refundStatus: "Плановый статус возврата",
			partialRefund: "Частичный возврат, в т.ч.",
			refundWithoutAcceptance: "Безакцептно",
			refundBankGuarantee: "Банковская гарантия",
			refundPenalty: "Взыскание",
			remainingRefund: "Остаток к возврату",
			keyDates: "Ключевые даты",
			keyDatesRecall: "Отзыв [ЭС/НС]",
			keyDatesRequirements: "Требования",
			keyDatesDeclaration: "Исковое заявление",
			keyDatesSettlement: "Урегулирование [ЭС/НС]",
			responsibleUsers: "Ответственное подразделение",
			legalDepartmentUser: "ЮД",
			problemDebtDepartmentUser: "Сотрудник Управления урегулирования",
			problemDebtDepartmentOrProjectManagerUser: "Сотрудник ДП/ДМ или Управления реструктуризации",
			projectManagerUser: "ДП [В случае урегулирования]",
			currentDebtorStatusComment: "Текущий статус процесса взыскания",
			declineReason: "Причина отклонения",
			acceptor: "Одобрил",
			canceller: "Отменил изменения",
			decliner: "Отклонил изменения",
			project: "Проект",
			projects: "Проекты",
			bank: "Банк",
			bankBranch: "Филиал банка",
			bik: "БИК",
			correspondentAccount: "Корреспондентский счет",
			accountNumber: "Номер счета",
			accountType: "Тип счета",
			accountCurrency: "Валюта счета",
			startAcceptDate: "Дата начала акцептов",
			openedAtDate: "Дата открытия",
			endAcceptDate: "Дата окончания акцептов",
			monitoringSystemConnection: "Подключение к системе мониторинга",
			responsible: "Ответственный",
			controlDate: "Дата контроля",
			applicationStatus: "Статус заявки",
			contractRKO: "Реквизиты договора РКО",
			frpSum: "Сумма займа ФРП",
			financeScheme: "Программа финансирования",
			frpIssuedSum: "Сумма выданных средств",
			frpIssedSumEmpty: "Информация будет отображена на странице после поступления денежных средств на расчетный счет банка",
			kpi: "Показатель",
			calculationMethodology: "Методология расчета",
			criterias: "Критерии",
			constantIndicator: "Постоянный показатель",
			applicableToPeriods: "Применимо к периодам",
			staffEmployees: "Сотрудники",
			refundSource: {
				category: "Категория",
				startDate: "Дата начала действия"
			},
			supervisorProjectManager: "Менеджер сопровождения проекта",
			loanSum: "Сумма займа",
			supervisor: "Менеджер сопровождения",
			projectManager: "Менеджер проекта",
			legalExpertise: "Правовая экспертиза"
		},
		isSigned: {
			digitallySigned: "Подписано с ЭЦП",
			digitallyMissed: "ЭЦП отсутствует",
			signatureMissed: "Подпись отсутствует"
		},
		values: {
			missing: "Отсутствует"
		},
		expireDate: "Действительна до",
		acceptedDate: "Дата и время акцептования",
		acceptedEmployeeName: "Кем акцептована",
		declinedDate: "Дата и время отклонения",
		declinedEmployeeName: "Кем отклонена",
		canceledDate: "Дата и время отмены",
		declineReason: "Причина отклонения",
		canceledEmployeeName: "Кем отменена",
		phone: "Телефон",
		phoneConfirmationStatus: "Статус подтверждения телефона",
		isSignatureRequired: "Необходимо подтверждение с помощью ЭЦП при входе"
	},
	aliases: {
		kpiStatus: {
			[KpiStatusEnum.ACTIVE]: "Активен",
			[KpiStatusEnum.NOT_ACTIVE]: "Не активен"
		},
		approveStatus: {
			Created: "Открыт",
			Approved: "Одобрен",
			Rejected: "Отклонен",
			Undefined: "Неизвестно"
		},
		trustStatus: {
			Undefined: "Неизвестно",
			Created: "Создана",
			Accepted: "Акцептована",
			Declined: "Отклонена"
		},
		documents: {
			[STORAGE_SNILS_NAMESPACE]: "СНИЛС",
			[STORAGE_INN_NAMESPACE]: "ИНН",
			"trust/files": "Файл доверенности",
			signatureGeneral: "Сведения об ЭЦП",
			informationAboutSignature: "Сведения о подписи",
			trustTemplate: "Шаблон доверенности",
			[STORAGE_APPOINTMENT_HEAD_ORDER]: "Приказ о назначении руководителем",
			[STORAGE_SIGNED_REQUEST]: "Запрос, подписанный ЭЦП руководителя",
			treasuryStatement: "Файл выписки"
		},
		counterpartyType: {
			[CounterpartyType.LEGAL_ENTITY]: "Юридическое лицо",
			[CounterpartyType.LEGAL_PERSON]: "Индивидуальный предприниматель",
			[CounterpartyType.PHYSICAL_PERSON]: "Физическое лицо",
			[CounterpartyType.FOREIGN_ORGANIZATION]: "Иностранная организация"
		},
		counterpartyEmployeeTrustStatus: {
			[CounterpartyEmployeeTrustStatus.ABSENT]: "Отсутствует",
			[CounterpartyEmployeeTrustStatus.REVIEW]: "На рассмотрении",
			[CounterpartyEmployeeTrustStatus.ACTIVE]: "Действующая",
			[CounterpartyEmployeeTrustStatus.REJECTED]: "Отклонена",
			[CounterpartyEmployeeTrustStatus.OUTDATED]: "Срок действия истек"
		},
		treasuryStatementPaymentsType: {
			[TreasuryStatementPaymentsType.ALL]: "Все",
			[TreasuryStatementPaymentsType.FINAL]: "Обработанные",
			[TreasuryStatementPaymentsType.NOT_FINAL]: "Необработанные"
		},
		treasuryStatementRecordDocumentType: {
			[TreasuryStatementOperationType.PAYOUT]: "Выплата",
			[TreasuryStatementOperationType.INCOME]: "Поручение"
		},
		troubledBorrowerFilterEventsType: {
			[TroubledBorrowerFilterEventsType.ALL]: "Все",
			[TroubledBorrowerFilterEventsType.PAST]: "Прошедшие",
			[TroubledBorrowerFilterEventsType.PLANNED]: "Запланированные"
		},
		bankAccountType: {
			[BankAccountTypeEnum.UNKNOWN]: "Неизвестно",
			[BankAccountTypeEnum.PRIMARY]: "Основной",
			[BankAccountTypeEnum.SECONDARY]: "Дополнительный"
		},
		sourceType: {
			[SourceTypeEnum.UNKNOWN]: "",
			[SourceTypeEnum.KZ]: "КЗ",
			[SourceTypeEnum.LK]: "ЛК"
		},
		bankAccountApplicationDocumentName: {
			[BankAccountApplicationDocumentTypeEnum.DIRECT_WITHDRAWAL_AGREEMENT]: "Дополнительное соглашение к договору банковского счета Заявителя, предоставляющее Фонду право безакцептного списания средств со счета",
			[BankAccountApplicationDocumentTypeEnum.PAYMENT_ACCEPT_AGREEMENT]: "Документы, подтверждающие права Фонда на акцепт платежей по счету Заявителя №{accountNumber}",
			[BankAccountApplicationDocumentTypeEnum.INFORMATIONAL_LETTER]: "Информационное письмо с указанием реквизитов расчетного счета в банке, открытого для обособленного учета средств по займу"
		},
		bankAccountApplicationStatusType: {
			[BankAccountApplicationStatusTypeEnum.IN_PROGRESS]: "В работе",
			[BankAccountApplicationStatusTypeEnum.COMPLETED]: "Завершена"
		},
		activeStatus: {
			active: "Активен",
			inactive: "Неактивен"
		},
		affirmation: {
			yes: "Да",
			no: "Нет"
		},
		confirmation: {
			confirmed: "Подтверджен",
			notConfirmed: "Не подтвержден"
		},
		itemAvailable: {
			available: "Есть",
			notAvailable: "Нет"
		},
		userAgreement: {
			pushingButton: "Нажимая кнопку",
			send: "“Отправить”",
			iGiveMyConsentToProcessingMyPersonalData: "Вы подтверждаете согласие лица, данные о котором указаны в форме, на обработку персональных данных, в соответствии с Федеральным законом от 27.07.2006 года № 152-ФЗ",
			onPersonalData: "“О персональных данных”, на условиях и для целей, определенных в",
			consentToProcessingMyPersonalData: "Согласии на обработку персональных данных."
		},
		changeDocumentType: {
			[ChangeType.Create]: "Новый документ",
			[ChangeType.Edit]: "Изменение содержания",
			[ChangeType.UnPublish]: "Снятие с публикации",
			[ChangeType.Rollback]: "Отмена изменений"
		},
		changeDocumentRequestType: {
			[ChangeType.Create]: "Создание нового документа",
			[ChangeType.Edit]: "Изменение содержания документа",
			[ChangeType.UnPublish]: "Снятие с публикации",
			[ChangeType.Rollback]: "Отмена изменений"
		},
		changeRequestStatusType: {
			[ChangeRequestStatusType.Created]: "Создано",
			[ChangeRequestStatusType.Accepted]: "Одобрено",
			[ChangeRequestStatusType.Declined]: "Отклонено"
		},
		changeRequestFilterStatusType: {
			[ChangeRequestStatusType.Created]: "На рассмотрении",
			[ChangeRequestStatusType.Accepted]: "Согласованные",
			[ChangeRequestStatusType.Declined]: "Отклоненные"
		},
		sizeType: {
			[SizeUnits.BYTE]: "Байт",
			[SizeUnits.KI_B]: "Кб",
			[SizeUnits.MI_B]: "Мб",
			[SizeUnits.GI_B]: "Гб",
			[SizeUnits.TI_B]: "Тб"
		},
		accrualPaymentsModeType: {
			[AgreementsOverduePaymentsModeType.MAIN_DEBT]: "Основной долг",
			[AgreementsOverduePaymentsModeType.INTEREST]: "Проценты"
		},
		trancheScheduleMainStatus: {
			[TrancheScheduleStatusTypeEnum.DRAFT]: "Черновик",
			[TrancheScheduleStatusTypeEnum.PENDING]: "На рассмотрении у менеджера проекта",
			[TrancheScheduleStatusTypeEnum.ACCEPTED]: "Акцептовано менеджером проекта"
		},
		trancheSchedulePlanStatus: {
			[TrancheScheduleStatusTypeEnum.DRAFT]: "",
			[TrancheScheduleStatusTypeEnum.PENDING]: "",
			[TrancheScheduleStatusTypeEnum.ACCEPTED]: "Акцептован"
		},
		kpiQuarter: {
			[KpiQuarterEnum.YEAR]: "Год",
			[KpiQuarterEnum.I]: "I кв",
			[KpiQuarterEnum.II]: "II кв",
			[KpiQuarterEnum.III]: "III кв",
			[KpiQuarterEnum.IV]: "IV кв"
		},
		delegacyStatus: {
			[DelegacyStatusEnum.ACTIVE]: "Активные",
			[DelegacyStatusEnum.NOT_ACTIVE]: "Неактивные"
		}
	},
	tooltips: {
		workingRate: "Коэффициент отработанного времени",
		laborRate: "Коэффициент трудового участия"
	},
	chart: {
		legend: {
			correctAnswersRate: "% верных ответов",
			rewardRate: "% премии",
			tasksTotalCount: "задач всего",
			tasksOverdueCount: "задач просроченно",
			tasksCompletedOnTimeCount: "% задач",
			completionRate: "% выполнения",
			laborRate: "КТУ"
		}
	}
};
