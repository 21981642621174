import { Store } from "vuex";
import { resolveMutation } from "@/utils/vuexModules";
import alertTypes from "@/store/modules/alerts/types";

let store: Store<any>;

export const registerAlertService = (value: Store<any>) => {
	store = value;
};

export enum AlertKeys {
	OFFLINE_REQUEST_ERROR,
	SERVER_UNAVAILABLE_ERROR,
	REQUEST_UNKNOWN_ERROR,
	SUCCESS_CREATED_INFO,
	SUCCESS_SENT_INFO,
	SUCCESS_UPDATED_INFO,
	PROFILE_ALREADY_ACTIVATED,
	PROFILE_ACTIVATED,
	PROFILE_DEACTIVATED,
	ACCOUNT_ACTIVATED,
	ACCOUNT_DEACTIVATED,
	MASTER_ACCOUNT_ACCESS_APPLICATION_APPROVED,
	MASTER_ACCOUNT_ACCESS_APPLICATION_REJECTED,
	EMPLOYEE_JOIN_APPLICATION_CREATED,
	EMPLOYEE_JOIN_APPLICATION_APPROVED,
	EMPLOYEE_JOIN_APPLICATION_REJECTED,
	TRUST_ACCEPTED,
	TRUST_DECLINED,
	TRUST_ACCEPT_CANCELED,
	EMPLOYEE_PROFILE_ALREADY_ACTIVATED,
	PASSWORD_RESET_SUCCESSFULLY,
	EMPLOYEE_SUCCESS_CREATED,
	EMPLOYEE_SUCCESS_INVITED,
	COUNTERPARTY_SUCCESS_UPDATED_VIA_FNS,
	DRAFT_SUCCESS_CREATED,
	DRAFT_SUCCESS_SAVED,
	VERSION_SUCCESS_ACTIVATED,
	VERSION_SUCCESS_CREATED,
	TRANCHE_SUCCESS_CREATED,
	TRANCHE_SUCCESS_DELETED,
	TRANCHES_SUCCESS_SAVED,
	OVERDUE_SUCCESS_CREATED,
	OVERDUE_SUCCESS_DELETED,
	OVERDUES_SUCCESS_SAVED,
	SCHEDULE_ITEM_SUCCESS_CREATED,
	SCHEDULE_ITEM_SUCCESS_DELETED,
	SCHEDULE_ITEMS_SUCCESS_SAVED,
	OVERDUE_INTEREST_SUCCESS_CREATED,
	OVERDUE_INTEREST_SUCCESS_DELETED,
	OVERDUE_INTEREST_SUCCESS_SAVED,
	PENALTY_INTEREST_SUCCESS_CREATED,
	PENALTY_INTEREST_SUCCESS_DELETED,
	PENALTY_INTEREST_SUCCESS_SAVED,
	ACCRUED_INTEREST_ITEM_SUCCESS_CREATED,
	ACCRUED_INTEREST_ITEM_SUCCESS_DELETED,
	ACCRUED_INTEREST_ITEM_SUCCESS_SAVED,
	ACCRUED_INTEREST_ITEMS_SUCCESS_UPDATED,
	ASSIGNMENT_COMPENSATION_SUCCESS_CALCULATED,
	CASH_FLOW_ITEM_SUCCESS_CREATED,
	CASH_FLOW_ITEM_SUCCESS_DELETED,
	CASH_FLOW_ITEM_SUCCESS_UPDATED,
	SNILS_RECOGNIZE_SUCCESS,
	SNILS_RECOGNIZE_ERROR,
	PHONE_CONFIRMATION_REQUEST_SUCCESS_CREATED,
	CHANGES_SUCCESS_SAVED,
	EXTRA_RATE_SUCCESS_CHARGED,
	UPLOAD_TREASURY_STATEMENT_SUCCESS,
	HANDLE_TREASURY_STATEMENT_SUCCESS,
	NOTE_SUCCESS_CREATED,
	NOTE_SUCCESS_UPDATED,
	NOTE_SUCCESS_DELETED,
	TREASURY_STATEMENT_IMPORT_CANCELLED,
	PROJECT_SUCCESS_UPDATED,
	DEBTOR_SUCCESS_CREATED,
	DEBTOR_SUCCESS_DELETED,
	DEBTOR_SUCCESS_UPDATED,
	STATUS_SUCCESS_UPDATED,
	COMMENT_SUCCESS_UPDATED,
	LOAN_PERMISSIONS_NOT_FOUND,
	SUCCESS_COPIED_LINK,
	COPY_LINK_ERROR,
	UPDATE_DOCUMENT_REQUEST_CREATED,
	UNPUBLISH_DOCUMENT_REQUEST_CREATED,
	ROLLBACK_DOCUMENT_REQUEST_CREATED,
	CREATE_DOCUMENT_REQUEST_CREATED,
	REQUEST_ACCEPTED,
	REQUEST_DECLINED,
	DOCUMENT_SUCCESS_UPLOADED,
	DOCUMENT_SUCCESS_DELETED,
	REPORT_SUCCESS_ACCEPTED,
	REPORT_SUCCESS_DECLINED,
	EMAIL_REQUEST_CONFIRMATION_SUCCESS_SENT,
	QUERY_SUCCESS_REPORTED,
	BANK_ACCOUNT_ITEM_SUCCESS_CREATED,
	BANK_ACCOUNT_ITEM_SUCCESS_UPDATED,
	BANK_ACCOUNT_APPLICATION_ITEM_SUCCESS_CREATED,
	BANK_ACCOUNT_APPLICATION_ITEM_SUCCESS_UPDATED,
	BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_REQUEST_SUCCESS_SENT,
	BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_ACCEPTED,
	BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_DECLINED,
	OVERDUE_ORDER_NOT_FOUND,
	TRANCHE_SCHEDULE_SUCCESS_SENT,
	TRANCHE_SCHEDULE_SUCCESS_UPDATED,
	TRANCHE_SCHEDULE_ORDER_CONDITIONS_SUCCESS_UPDATED,
	TRANCHE_SCHEDULE_SUCCESS_ACCEPTED,
	TRANCHE_SCHEDULE_SUCCESS_WITHDRAWN,
	TRANCHE_SCHEDULE_SUCCESS_REJECTED,
	KPI_SUCCESS_DELETED,
	KPI_DELEGACY_SUCCESS_DELETED
}

const alertTextMap = {
	[AlertKeys.REQUEST_UNKNOWN_ERROR]: "Возникла непредвиденная ошибка.",
	[AlertKeys.SERVER_UNAVAILABLE_ERROR]: "Сервис временно недоступен. Повторите попытку позже.",
	[AlertKeys.OFFLINE_REQUEST_ERROR]: "Отсутствует интернет-соединение. Проверьте подключение и повторите попытку.",
	[AlertKeys.SUCCESS_CREATED_INFO]: "Данные успешно сохранены.",
	[AlertKeys.SUCCESS_SENT_INFO]: "Данные успешно отправлены.",
	[AlertKeys.SUCCESS_UPDATED_INFO]: "Данные успешно обновлены",
	[AlertKeys.PROFILE_ALREADY_ACTIVATED]: "Не удалось активировать профиль. Профиль был активирован ранее.",
	[AlertKeys.PROFILE_ACTIVATED]: "Профиль успешно активирован.",
	[AlertKeys.PROFILE_DEACTIVATED]: "Профиль успешно деактивирован.",
	[AlertKeys.ACCOUNT_ACTIVATED]: "Аккаунт успешно активирован.",
	[AlertKeys.ACCOUNT_DEACTIVATED]: "Аккаунт успешно деактивирован.",
	[AlertKeys.MASTER_ACCOUNT_ACCESS_APPLICATION_APPROVED]: "Заявка на получение доступа к мастер-аккаунту успешно одобрена.",
	[AlertKeys.MASTER_ACCOUNT_ACCESS_APPLICATION_REJECTED]: "Заявка на получение доступа к мастер-аккаунту успешно отклонена.",
	[AlertKeys.EMPLOYEE_JOIN_APPLICATION_CREATED]: "Заявка на присоединение к организации отправлена.",
	[AlertKeys.EMPLOYEE_JOIN_APPLICATION_APPROVED]: "Заявка на присоединение к организации успешно одобрена.",
	[AlertKeys.EMPLOYEE_JOIN_APPLICATION_REJECTED]: "Заявка на присоединение к организации успешно отклонена.",
	[AlertKeys.TRUST_ACCEPTED]: "Доверенность акцептована.",
	[AlertKeys.TRUST_DECLINED]: "Доверенность отклонена.",
	[AlertKeys.TRUST_ACCEPT_CANCELED]: "Акцепт с доверенности снят.",
	[AlertKeys.EMPLOYEE_PROFILE_ALREADY_ACTIVATED]: "Профиль сотрудника уже был активирован.",
	[AlertKeys.PASSWORD_RESET_SUCCESSFULLY]: "Пароль успешно сброшен.",
	[AlertKeys.EMPLOYEE_SUCCESS_CREATED]: "Сотрудник был успешно добавлен.",
	[AlertKeys.EMPLOYEE_SUCCESS_INVITED]: "Письмо с приглашением направлено на указанную почту.",
	[AlertKeys.COUNTERPARTY_SUCCESS_UPDATED_VIA_FNS]: "Данные успешно обновлены из Контур.Фокус.",
	[AlertKeys.DRAFT_SUCCESS_CREATED]: "Черновик был успешно создан.",
	[AlertKeys.DRAFT_SUCCESS_SAVED]: "Черновик успешно сохранен.",
	[AlertKeys.VERSION_SUCCESS_ACTIVATED]: "Версия успешно активирована.",
	[AlertKeys.VERSION_SUCCESS_CREATED]: "Версия успешно создана.",
	[AlertKeys.TRANCHE_SUCCESS_CREATED]: "Новая запись блока Траншевое финансирование успешно добавлена.",
	[AlertKeys.TRANCHE_SUCCESS_DELETED]: "Запись блока Траншевое финансирование успешно удалена.",
	[AlertKeys.TRANCHES_SUCCESS_SAVED]: "Данные блока Траншевое финансирование успешно сохранены.",
	[AlertKeys.OVERDUE_SUCCESS_CREATED]: "Новая запись блока Просроченная задолженность успешно добавлена.",
	[AlertKeys.OVERDUE_SUCCESS_DELETED]: "Запись блока Просроченная задолженность успешно удалена.",
	[AlertKeys.OVERDUES_SUCCESS_SAVED]: "Данные блока Просроченная задолженность успешно сохранены.",
	[AlertKeys.SCHEDULE_ITEM_SUCCESS_CREATED]: "Новая запись блока График возврата займа успешно добавлена.",
	[AlertKeys.SCHEDULE_ITEM_SUCCESS_DELETED]: "Запись блока График возврата займа успешно удалена.",
	[AlertKeys.SCHEDULE_ITEMS_SUCCESS_SAVED]: "Данные блока График возврата займа успешно сохранены.",
	[AlertKeys.OVERDUE_INTEREST_SUCCESS_CREATED]: "Новая запись блока Просроченная задолженность по процентам успешно добавлена.",
	[AlertKeys.OVERDUE_INTEREST_SUCCESS_DELETED]: "Запись блока Просроченная задолженность по процентам успешно удалена.",
	[AlertKeys.OVERDUE_INTEREST_SUCCESS_SAVED]: "Данные блока Просроченная задолженность по процентам успешно сохранены.",
	[AlertKeys.PENALTY_INTEREST_SUCCESS_CREATED]: "Новая запись блока Реестр начисленных штрафов/пеней успешно добавлена.",
	[AlertKeys.PENALTY_INTEREST_SUCCESS_DELETED]: "Запись блока Реестр начисленных штрафов/пеней успешно удалена.",
	[AlertKeys.PENALTY_INTEREST_SUCCESS_SAVED]: "Данные блока Реестр начисленных штрафов/пеней успешно сохранены.",
	[AlertKeys.ACCRUED_INTEREST_ITEM_SUCCESS_CREATED]: "Новая запись блока Реестр начисленных процентов успешно добавлена.",
	[AlertKeys.ACCRUED_INTEREST_ITEM_SUCCESS_DELETED]: "Запись блока Реестр начисленных процентов успешно удалена.",
	[AlertKeys.ACCRUED_INTEREST_ITEM_SUCCESS_SAVED]: "Запись блока Реестр начисленных процентов успешно сохранена.",
	[AlertKeys.ACCRUED_INTEREST_ITEMS_SUCCESS_UPDATED]: "Массовое редактирование блока Реестр начисленных процентов выполнено успешно.",
	[AlertKeys.ASSIGNMENT_COMPENSATION_SUCCESS_CALCULATED]: "Компенсация успешно рассчитана",
	[AlertKeys.CASH_FLOW_ITEM_SUCCESS_CREATED]: "Новая запись блока Реестр движения денежных средств успешно добавлена.",
	[AlertKeys.CASH_FLOW_ITEM_SUCCESS_DELETED]: "Запись блока Реестр движения денежных средств успешно удалена.",
	[AlertKeys.CASH_FLOW_ITEM_SUCCESS_UPDATED]: "Данные блока Реестр движения денежных средств по процентам успешно сохранены.",
	[AlertKeys.SNILS_RECOGNIZE_SUCCESS]: "Распознавание СНИЛС прошло успешно.",
	[AlertKeys.SNILS_RECOGNIZE_ERROR]: "Не удалось распознать СНИЛС.",
	[AlertKeys.PHONE_CONFIRMATION_REQUEST_SUCCESS_CREATED]: "Запрос на подтверждение номера телефона успешно сформирован.",
	[AlertKeys.CHANGES_SUCCESS_SAVED]: "Изменения успешно сохранены.",
	[AlertKeys.EXTRA_RATE_SUCCESS_CHARGED]: "Дополнительные проценты начислены.",
	[AlertKeys.UPLOAD_TREASURY_STATEMENT_SUCCESS]: "Импортирование выписки прошло успешно.",
	[AlertKeys.HANDLE_TREASURY_STATEMENT_SUCCESS]: "Обработка выписки прошла успешно.",
	[AlertKeys.NOTE_SUCCESS_CREATED]: "Запись успешно создана.",
	[AlertKeys.NOTE_SUCCESS_UPDATED]: "Запись успешно сохранена.",
	[AlertKeys.NOTE_SUCCESS_DELETED]: "Запись успешно удалена.",
	[AlertKeys.TREASURY_STATEMENT_IMPORT_CANCELLED]: "Запрос на импорт выписки УФК отменен.",
	[AlertKeys.PROJECT_SUCCESS_UPDATED]: "Проект успешно изменен.",
	[AlertKeys.DEBTOR_SUCCESS_CREATED]: "Должник успешно создан.",
	[AlertKeys.DEBTOR_SUCCESS_DELETED]: "Должник успешно удален.",
	[AlertKeys.DEBTOR_SUCCESS_UPDATED]: "Должник успешно изменен.",
	[AlertKeys.STATUS_SUCCESS_UPDATED]: "Статус успешно изменен.",
	[AlertKeys.COMMENT_SUCCESS_UPDATED]: "Комментарий успешно изменен.",
	[AlertKeys.LOAN_PERMISSIONS_NOT_FOUND]: "Не удалось получить список прав доступа.",
	[AlertKeys.SUCCESS_COPIED_LINK]: "Ссылка успешно скопирована.",
	[AlertKeys.COPY_LINK_ERROR]: "Не удалось скопировать ссылку.",
	[AlertKeys.UPDATE_DOCUMENT_REQUEST_CREATED]: "Заявка на изменение документа отправлена.",
	[AlertKeys.UNPUBLISH_DOCUMENT_REQUEST_CREATED]: "Заявка на снятие документа с публикации отправлена.",
	[AlertKeys.ROLLBACK_DOCUMENT_REQUEST_CREATED]: "Заявка на отмену изменений отправлена.",
	[AlertKeys.CREATE_DOCUMENT_REQUEST_CREATED]: "Заявка на создание документа отправлена.",
	[AlertKeys.REQUEST_ACCEPTED]: "Заявка успешно одобрена.",
	[AlertKeys.REQUEST_DECLINED]: "Заявка успешно отклонена.",
	[AlertKeys.DOCUMENT_SUCCESS_UPLOADED]: "Документ успешно загружен.",
	[AlertKeys.DOCUMENT_SUCCESS_DELETED]: "Документ успешно удален.",
	[AlertKeys.REPORT_SUCCESS_ACCEPTED]: "Отчет успешно принят.",
	[AlertKeys.REPORT_SUCCESS_DECLINED]: "Принятая отчетность успешно отменена.",
	[AlertKeys.EMAIL_REQUEST_CONFIRMATION_SUCCESS_SENT]: "Email подтверждение было направлено на ваш email.",
	[AlertKeys.QUERY_SUCCESS_REPORTED]: "Запрос успешно отправлен.",
	[AlertKeys.BANK_ACCOUNT_ITEM_SUCCESS_CREATED]: "Новый расчетный счет успешно добавлен.",
	[AlertKeys.BANK_ACCOUNT_ITEM_SUCCESS_UPDATED]: "Информация в карточке расчетного счета успешно изменена.",
	[AlertKeys.BANK_ACCOUNT_APPLICATION_ITEM_SUCCESS_CREATED]: "Новая заявка на подключение расчетного счета успешно добавлена.",
	[AlertKeys.BANK_ACCOUNT_APPLICATION_ITEM_SUCCESS_UPDATED]: "Заявка на подключение расчетного счета успешно обновлена.",
	[AlertKeys.BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_REQUEST_SUCCESS_SENT]: "Заявка на запрос в ЮД успешно отправлена",
	[AlertKeys.BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_ACCEPTED]: "Согласование нетипового соглашения успешно акцептовано.",
	[AlertKeys.BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_DECLINED]: "Согласование нетипового соглашения успешно отклонено.",
	[AlertKeys.OVERDUE_ORDER_NOT_FOUND]: "Распоряжение не найдено",
	[AlertKeys.TRANCHE_SCHEDULE_SUCCESS_SENT]: "План-график траншей успешно отправлен на согласование менеджеру проекта.",
	[AlertKeys.TRANCHE_SCHEDULE_SUCCESS_UPDATED]: "План-график траншей успешно изменен.",
	[AlertKeys.TRANCHE_SCHEDULE_ORDER_CONDITIONS_SUCCESS_UPDATED]: "Условия выдачи план-графика траншей успешно изменены.",
	[AlertKeys.TRANCHE_SCHEDULE_SUCCESS_ACCEPTED]: "План-график траншей успешно акцептован.",
	[AlertKeys.TRANCHE_SCHEDULE_SUCCESS_WITHDRAWN]: "План-график траншей успешно отозван.",
	[AlertKeys.TRANCHE_SCHEDULE_SUCCESS_REJECTED]: "План-график траншей успешно отклонен.",
	[AlertKeys.KPI_SUCCESS_DELETED]: "Показатель успешно удален.",
	[AlertKeys.KPI_DELEGACY_SUCCESS_DELETED]: "Делегирование успешно завершено."
};


class AlertService {
	addError(key: AlertKeys) {
		store.commit(resolveMutation(alertTypes.namespace, alertTypes.mutationTypes.ADD_ERROR_ALERT),
			alertTextMap[key], { root: true });
	}
	
	addInfo(key: AlertKeys) {
		store.commit(resolveMutation(alertTypes.namespace, alertTypes.mutationTypes.ADD_INFO_ALERT),
			alertTextMap[key], { root: true });
	}
	
	addCustomError(text: string) {
		store.commit(resolveMutation(alertTypes.namespace, alertTypes.mutationTypes.ADD_ERROR_ALERT),
			text, { root: true });
	}
	
	addCustomInfo(text: string) {
		store.commit(resolveMutation(alertTypes.namespace, alertTypes.mutationTypes.ADD_INFO_ALERT),
			text, { root: true });
	}
}

export default new AlertService();
