export default class ApiCreateBodFile {
	data: File;
	name: string;
	mimeType: string;

	constructor(data: File, name: string, mimeType: string) {
		this.data = data;
		this.name = name;
		this.mimeType = mimeType;
	}
}
