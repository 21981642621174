import { render, staticRenderFns } from "./BodLayout.vue?vue&type=template&id=fe0c9c92&scoped=true"
import script from "./BodLayout.vue?vue&type=script&lang=js"
export * from "./BodLayout.vue?vue&type=script&lang=js"
import style0 from "./BodLayout.vue?vue&type=style&index=0&id=fe0c9c92&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe0c9c92",
  null
  
)

export default component.exports