export default class ApiUpdateDocumentRequest {
	sectionId: string;
	financeSchemeIds: string[];
	responsibleDepartmentId: string;
	responsibleUserId: string;
	linkedToFinanceSchemes: boolean;
	isWeb: boolean;
	webLink: string;

	constructor(
		sectionId: string = "",
		financeSchemeIds: string[] = [],
		responsibleDepartmentId: string = "",
		responsibleUserId: string = "",
		linkedToFinanceSchemes: boolean = false,
		isWeb: boolean = false,
		webLink: string = ""
	)
	{
		this.sectionId = sectionId;
		this.financeSchemeIds = financeSchemeIds;
		this.responsibleDepartmentId = responsibleDepartmentId;
		this.responsibleUserId = responsibleUserId;
		this.linkedToFinanceSchemes = linkedToFinanceSchemes;
		this.isWeb = isWeb;
		this.webLink = webLink;
	}
}
