<template>
	<v-container fluid class="pa-0">
		<v-row style="position: fixed; bottom: 0; left: 0; width: 100%" class="white ma-0 py-2 pr-3">
			<v-col class="d-flex justify-end mr-5">
				<frp-btn data-cy="cancel"
						 color="primary"
						 outlined
						 @click="dialogEnabled = true"
						 :disabled="isCancelDisabled || isLoadingState">
					{{ $t("buttons.cancel") }}
				</frp-btn>

				<slot name="submit-action">
					<frp-btn data-cy="submit"
							 elevation="0"
							 @click="onFormSubmit"
							 :loading="isLoadingState"
							 :disabled="isSaveDisabled || isLoadingState">
						{{ submitText || $t("buttons.save") }}
					</frp-btn>
				</slot>

				<frp-confirm-dialog v-if="dialogEnabled"
									@submit="onCancelFormSubmit"
									@cancel="dialogEnabled = false"
									:title="$t('dialogs.confirmCancelFormChanges.title')"
									:description="$t('dialogs.confirmCancelFormChanges.description')">
				</frp-confirm-dialog>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpConfirmDialog from "Components/dialogs/FrpConfirmDialog";

export default {
	props: {
		isLoadingState: Boolean,
		isCancelDisabled: Boolean,
		isSaveDisabled: Boolean,
		submitText: String
	},
	data() {
		return {
			dialogEnabled: false
		};
	},
	methods: {
		onCancelFormSubmit() {
			this.dialogEnabled = false;
			this.$emit("cancel");
		},
		onFormSubmit() {
			this.$emit("submit");
		}
	},
	components: {
		FrpBtn,
		FrpConfirmDialog
	}
};
</script>
