import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import DocumentsRouteQuery from "@/store/bod/modules/documents/types/documentsRouteQuery";
import DocumentsState from "@/store/bod/modules/documents/types/documentsState";
import { parseArrayParameter, parseBooleanParameter } from "@/utils/query";

export default class DocumentsRouteQueryService {
	defaultRouteQuery: DocumentsRouteQuery;

	constructor(defaultRouteQuery: DocumentsRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: DocumentsState) {
		let query = new DocumentsRouteQuery(
			state.paging.page,
			state.filter.text,
			state.filter.financeSchemeIds,
			state.filter.departmentIds,
			state.filter.responsibleUserIds,
			state.filter.sectionIds,
			state.filter.initiatorIds,
			state.filter.activeOnly,
			state.filter.isWeb
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(DocumentsRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				financeSchemeIds: parseArrayParameter(query.financeSchemeIds),
				departmentIds: parseArrayParameter(query.departmentIds),
				responsibleUserIds: parseArrayParameter(query.responsibleUserIds),
				sectionIds: parseArrayParameter(query.sectionIds),
				initiatorIds: parseArrayParameter(query.initiatorIds),
				activeOnly: parseBooleanParameter(query.activeOnly, this.defaultRouteQuery.activeOnly),
				isWeb: parseBooleanParameter(query.isWeb, this.defaultRouteQuery.isWeb)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
