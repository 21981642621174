import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import RequestsRouteQuery from "@/store/bod/modules/requests/types/requestsRouteQuery";
import RequestsState from "@/store/bod/modules/requests/types/requestsState";
import { parseArrayParameter } from "@/utils/query";
import { ChangeRequestStatusType } from "@/store/bod/modules/document/types/changeRequestStatusType";
import { isEqual } from "lodash";

export default class RequestsRouteQueryService {
	defaultRouteQuery: RequestsRouteQuery;

	constructor(defaultRouteQuery: RequestsRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: RequestsState) {
		let query = new RequestsRouteQuery(
			state.paging.page,
			state.filter.text,
			state.filter.sectionIds,
			state.filter.initiatorIds,
			state.filter.statuses
		);

		const res = {};
		Object.keys(this.defaultRouteQuery).forEach(key => {
			//@ts-ignore
			if(!isEqual(this.defaultRouteQuery[key], query[key]))
				//@ts-ignore
				res[key] = query[key];
		});

		return res;
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(RequestsRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				statuses: parseArrayParameter(query.statuses, [ChangeRequestStatusType.Accepted]),
				sectionIds: parseArrayParameter(query.sectionIds),
				initiatorIds: parseArrayParameter(query.initiatorIds)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
