export enum FileTypes {
	JPG = "image/jpeg",
	PNG = "image/png",
	BD = ".bd*",
	PDF = "application/pdf",
	DOC = "application/msword",
	X_RAR = "application/x-rar",
	RAR = "application/vnd.rar",
	ZIP = "application/zip",
	X_ZIP_COMPRESSED = "application/x-zip-compressed",
	DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	XLS = "application/vnd.ms-excel",
	XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}
