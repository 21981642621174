import ApiBodFile from "@/api/bod/types/apiBodFile";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import Document from "@/store/bod/modules/document/types/document";
import BodAcceptorBase from "@/store/bod/modules/document/types/bodAcceptorBase";
import BodPersonAcceptor from "@/store/bod/modules/document/types/bodPersonAcceptor";
import BodDepartmentAcceptor from "@/store/bod/modules/document/types/bodDepartmentAcceptor";

export type Acceptor =
	BodAcceptorBase
	| BodPersonAcceptor
	| BodDepartmentAcceptor

export default class ChangeRequest {
	id: string;
	createdAt: number;
	status: string;
	type: string;
	changeWarrant: string;
	responsibleUser: ApiBodUser;
	initiator: ApiBodUser;
	description: string;
	editableFile: ApiBodFile;
	nonEditableFile: ApiBodFile;
	changeWarrantFile: ApiBodFile;
	document: Document;
	webLink: string;
	declineReason: string;
	acceptors: Acceptor[];

	constructor(
		id: string = "",
		createdAt: number = 0,
		status: string = "",
		type: string = "",
		changeWarrant: string = "",
		responsibleUser: ApiBodUser = new ApiBodUser(),
		initiator: ApiBodUser = new ApiBodUser(),
		description: string = "",
		editableFile: ApiBodFile = new ApiBodFile(),
		nonEditableFile: ApiBodFile = new ApiBodFile(),
		changeWarrantFile: ApiBodFile = new ApiBodFile(),
		document: Document = new Document(),
		webLink: string = "",
		declineReason: string = "",
		acceptors: Acceptor[] = []
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.status = status;
		this.type = type;
		this.changeWarrant = changeWarrant;
		this.responsibleUser = responsibleUser;
		this.initiator = initiator;
		this.description = description;
		this.editableFile = editableFile;
		this.nonEditableFile = nonEditableFile;
		this.changeWarrantFile = changeWarrantFile;
		this.document = document;
		this.webLink = webLink;
		this.declineReason = declineReason;
		this.acceptors = acceptors;
	}
}
