import ApiSection from "@/api/bod/types/apiSection";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import ApiDepartment from "@/api/bod/types/apiDepartment";
import ApiBodUser from "@/api/bod/types/apiBodUser";

export default class UpdateDocumentRequest {
	section: ApiSection;
	financeSchemes: ApiFinanceScheme[];
	responsibleDepartment: ApiDepartment;
	responsibleUser: ApiBodUser;
	linkedToFinanceSchemes: boolean;
	isWeb: boolean;
	webLink: string;

	constructor(
		section: ApiSection = new ApiSection(),
		financeSchemes: ApiFinanceScheme[] = [],
		responsibleDepartment: ApiDepartment = new ApiDepartment(),
		responsibleUser: ApiBodUser = new ApiBodUser(),
		linkedToFinanceSchemes: boolean = false,
		isWeb: boolean = false,
		webLink: string = ""
	)
	{
		this.section = section;
		this.financeSchemes = financeSchemes;
		this.responsibleDepartment = responsibleDepartment;
		this.responsibleUser = responsibleUser;
		this.linkedToFinanceSchemes = linkedToFinanceSchemes;
		this.isWeb = isWeb;
		this.webLink = webLink;
	}
}
