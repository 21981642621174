import { IsArray, IsString } from "class-validator";

export default class RequestsFilter {
	@IsString()
	text: string;

	@IsArray()
	sectionIds: string[];

	@IsArray()
	initiatorIds: string[];

	@IsArray()
	statuses: string[];

	constructor(
		text: string = "",
		sectionIds: string[] = [],
		initiatorIds: string[] = [],
		statuses: string[] = []
	)
	{
		this.text = text;
		this.sectionIds = sectionIds;
		this.initiatorIds = initiatorIds;
		this.statuses = statuses;
	}
}
