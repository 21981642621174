<template>
	<bod-content-layout :title="$t('titles.requests')">
		<v-card elevation="0"
				class="loan-card px-6 py-9"
				style="border-bottom-left-radius: 0; border-bottom-right-radius: 0">
			<div class="d-flex flex-wrap" style="gap: 16px">
				<frp-text-field hide-details
								style="width: 240px"
								v-model="internalFilterValues.text"
								:placeholder="$t('fields.documentSearch.placeholder')">
				</frp-text-field>

				<bod-autocomplete item-text="title"
								  return-object
								  @firstclick="fetchSections"
								  multiple
								  :loading="isSectionsLoading"
								  style="width: 240px"
								  :items="sections"
								  v-model="filterSectionIds"
								  color="blue"
								  :placeholder="$t('fields.documentSectionSearch.placeholder')"
								  hide-details>
				</bod-autocomplete>

				<bod-autocomplete item-text="fullName"
								  return-object
								  @firstclick="fetchInitiatorUsers"
								  multiple
								  :loading="isUsersLoading"
								  style="width: 240px"
								  :items="users"
								  v-model="filterInitiatorIds"
								  color="blue"
								  :placeholder="$t('fields.initiator.placeholder')"
								  hide-details>
				</bod-autocomplete>

				<bod-autocomplete item-text="text"
								  item-value="value"
								  return-object
								  multiple
								  style="width: 240px"
								  :items="statusFilterItems"
								  v-model="filterStatuses"
								  color="blue"
								  :placeholder="$t('fields.status.placeholder')"
								  hide-details>
				</bod-autocomplete>

				<v-spacer></v-spacer>

				<div>
					<frp-btn @click="resetFilter"
							 :disabled="isFilterEmpty"
							 class="mr-3"
							 color="primary"
							 outlined
							 elevation="0">
						{{ $t("buttons.clearFilter") }}
					</frp-btn>

					<frp-btn @click="applyFilter"
							 :disabled="!isFilterChanged"
							 no-margin
							 color="blue"
							 dark
							 elevation="0">
						{{ $t("buttons.search") }}
					</frp-btn>
				</div>
			</div>
		</v-card>

		<v-card elevation="0"
				class="loan-card pa-0"
				style="border-top-left-radius: 0; border-top-right-radius: 0;">
			<v-row>
				<v-col class="pb-0" style="border-top: 1px solid var(--v-grey-lighten4)">
					<v-data-table :headers="headers"
								  :loading-text="$t('tables.loading')"
								  :loading="isItemsLoading"
								  hide-default-footer
								  item-key="id"
								  :items="formattedItems"
								  :items-per-page="-1"
								  :mobile-breakpoint="mobileBreakpoint"
								  :options="options"
								  class="loan-table bod-table clickable-rows d-flex flex-column">
						<template #item="{ item }">
							<tr class="text-body-2"
								@click="openRequest(item, $event)"
								@mouseup.middle.prevent="openRequestInNewTab(item.id)"
								@mousedown.middle.prevent>
								<td v-for="column in headers">
									<span v-if="column.value === 'createdAt'">
										{{ `${formatDate(item.createdAt, friendlyDateFormatWithSeconds)}` }}
									</span>

									<bod-update-document-type-label v-else-if="column.value === 'type'"
																	:value="item.type">
									</bod-update-document-type-label>

									<template v-else-if="column.value === 'changeWarrant'">
										<div v-if="item.description" style="white-space: pre-line">{{ item.description }}</div>
										<div v-if="item.changeWarrant" class="font-italic mt-1 d-flex align-center">
											<frp-icon class="mr-1" src="ico_report" :color="colors.primary.base"></frp-icon>
											<span>{{ item.changeWarrant }}</span>
										</div>
									</template>

									<span v-else>{{ item[column.value] }}</span>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card>

		<template #footer>
			<bod-footer>
				<frp-pagination v-if="!isItemsLoading"
								v-model="page"
								active-color="blue"
								:length="Math.ceil(paging.total / paging.pageSize)">
				</frp-pagination>
			</bod-footer>
		</template>
	</bod-content-layout>
</template>

<script>
import BodUpdateDocumentTypeLabel from "Components/common/BodUpdateDocumentTypeLabel";
import FrpPagination from "Components/common/FrpPagination";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import FrpIcon from "Components/icon/FrpIcon";
import { assign, cloneDeep, isEqual } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import { RouteNames } from "Router/bod/routes";
import { ChangeRequestStatusType } from "Store/bod/modules/document/types/changeRequestStatusType";
import { defaultFilter } from "Store/bod/modules/requests";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/bod/modules/requests/types";
import { RequestFilterStatusType } from "Store/bod/modules/requests/types/requestFilterStatusType";
import { formatDate } from "Utils/dates";
import { friendlyDateFormatWithSeconds } from "Utils/formats";
import { openRouteInNewTab } from "Utils/router";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpAlerts from "Components/alerts/FrpAlerts";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";
import BodContentLayout from "Components/layouts/BodContentLayout";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	data() {
		return {
			RouteNames,
			formatDate,
			friendlyDateFormatWithSeconds,
			namespace,
			headers: [
				{
					text: this.$t("tables.createdDate"),
					value: "createdAt",
					class: "text-caption",
					sortable: false,
					width: "11%"
				},
				{
					text: this.$t("tables.documentTitle"),
					value: "title",
					class: "text-caption",
					sortable: false,
					width: "25%"
				},
				{
					text: this.$t("tables.requestType"),
					value: "type",
					class: "text-caption",
					sortable: false,
					width: "12%"
				},
				{
					text: this.$t("tables.changeWarrant"),
					value: "changeWarrant",
					class: "text-caption",
					sortable: false,
					width: "52%"
				}
			],
			internalFilterValues: {
				text: "",
				sectionIds: [],
				initiatorIds: [],
				statuses: []
			},
			statusFilterItems: [
				{
					text: this.$t(`aliases.changeRequestFilterStatusType.${ChangeRequestStatusType.Accepted}`),
					value: ChangeRequestStatusType.Accepted
				},
				{
					text: this.$t(`aliases.changeRequestFilterStatusType.${ChangeRequestStatusType.Created}`),
					value: ChangeRequestStatusType.Created
				},
				{
					text: this.$t(`aliases.changeRequestFilterStatusType.${ChangeRequestStatusType.Declined}`),
					value: ChangeRequestStatusType.Declined
				}
			]
		};
	},
	computed: {
		...mapState({
			isSectionsLoading: state => state.isSectionsLoading,
			isUsersLoading: state => state.isUsersLoading,
			sections: state => state.sections,
			users: state => state.users
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty
		}),
		filterSectionIds: {
			get() {
				return this.internalFilterValues.sectionIds.map(x => this.sections.find(y => y.id === x));
			},
			set(value)
			{
				if(value)
					this.internalFilterValues.sectionIds = value.map(x => x.id);
			}
		},
		filterInitiatorIds: {
			get() {
				return this.internalFilterValues.initiatorIds.map(x => this.users.find(y => y.id === x));
			},
			set(value)
			{
				if(value)
					this.internalFilterValues.initiatorIds = value.map(x => x.id);
			}
		},
		// Добавлена обработка значения Empty из-за наличия дефолтного значения массива
		filterStatuses: {
			get() {
				if(this.internalFilterValues.statuses.includes(RequestFilterStatusType.Empty))
					return [];
				else
					return this.internalFilterValues.statuses.map(x => this.statusFilterItems.find(y => y.value === x));
			},
			set(value)
			{
				if(value) {
					if(value.length)
						this.internalFilterValues.statuses = value.map(x => x.value);
					else
						this.internalFilterValues.statuses = [RequestFilterStatusType.Empty];
				}

			}
		},
		filter() {
			return {
				text: this.internalFilterValues.text,
				programIds: this.internalFilterValues.programIds,
				departmentIds: this.internalFilterValues.departmentIds,
				sectionIds: this.internalFilterValues.sectionIds,
				initiatorIds: this.internalFilterValues.initiatorIds,
				statuses: this.internalFilterValues.statuses
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v || ""])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return isEqual(cloneDeep(defaultFilter), this.filterValues);
		}
	},
	methods: {
		...mapMutations({
			setFilterText: mutationTypes.SET_FILTER_TEXT,
			setFilterSectionIds: mutationTypes.SET_FILTER_SECTION_IDS,
			setFilterInitiatorIds: mutationTypes.SET_FILTER_INITIATOR_IDS,
			setFilterStatuses: mutationTypes.SET_FILTER_STATUSES,
			resetFilter: mutationTypes.RESET_FILTER
		}),
		...mapActions({
			fetchInitiatorUsers: actionTypes.fetchInitiatorUsers,
			fetchSections: actionTypes.fetchSections
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		applyFilter() {
			this.setFilterText(this.internalFilterValues.text);
			this.setFilterSectionIds(this.internalFilterValues.sectionIds || []);
			this.setFilterInitiatorIds(this.internalFilterValues.initiatorIds || []);
			this.setFilterStatuses(this.internalFilterValues.statuses);
		},
		async openRequest({ id }, { ctrlKey }) {
			if(ctrlKey)
				this.openRequestInNewTab(id);
			else
				await this.$router.push({ name: RouteNames.REQUEST, params: { requestId: id } });
		},
		openRequestInNewTab(id) {
			openRouteInNewTab(this.$router, { name: RouteNames.REQUEST, params: { requestId: id } });
		},
	},
	created() {
		this.initializeStore();
	},
	components: {
		BodAutocomplete,
		BodUpdateDocumentTypeLabel,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		FrpAutocomplete,
		BodMain,
		FrpAlerts,
		FrpAlert,
		BodFooter,
		BodContentLayout
	}
};
</script>
