export enum DocumentPermissions {
	CREATE_CHANGE_REQUEST = "create-change-request",
	CREATE_UNPUBLISH_REQUEST = "create-unpublish-request",
	CREATE_ROLLBACK_REQUEST = "create-rollback-request",
	EDIT_FINANCE_SCHEMES = "edit-finance-schemes",
	EDIT_SECTION = "edit-section",
	EDIT_RESPONSIBLE_DEPARTMENT = "edit-responsible-department",
	EDIT_RESPONSIBLE_USER = "edit-responsible-user",
	EDIT_IS_WEB = "edit-isweb",
	EDIT_WEB_LINK = "edit-weblink"
}
