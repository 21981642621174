import ApiSection from "@/api/bod/types/apiSection";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import ApiDepartment from "@/api/bod/types/apiDepartment";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import ApiBodFile from "@/api/bod/types/apiBodFile";

export default class Document {
	id: string;
	createdAt: number;
	name: string;
	status: string;
	section: ApiSection;
	financeSchemes: ApiFinanceScheme[];
	responsibleDepartment: ApiDepartment;
	responsibleUser: ApiBodUser;
	editableFile: ApiBodFile;
	nonEditableFile: ApiBodFile;
	linkedToFinanceSchemes: boolean;
	changeWarrant: string;
	changeWarrantFileId: string;
	isWeb: boolean;
	webLink: string;

	constructor(
		id: string = "",
		createdAt: number = 0,
		name: string = "",
		status: string = "",
		section: ApiSection = new ApiSection(),
		financeSchemes: ApiFinanceScheme[] = [],
		responsibleDepartment: ApiDepartment = new ApiDepartment(),
		responsibleUser: ApiBodUser = new ApiBodUser(),
		editableFile: ApiBodFile = new ApiBodFile(),
		nonEditableFile: ApiBodFile = new ApiBodFile(),
		linkedToFinanceSchemes: boolean = true,
		changeWarrant: string = "",
		changeWarrantFileId: string = "",
		isWeb: boolean = true,
		webLink: string = ""
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.name = name;
		this.status = status;
		this.section = section;
		this.financeSchemes = financeSchemes;
		this.responsibleDepartment = responsibleDepartment;
		this.responsibleUser = responsibleUser;
		this.editableFile = editableFile;
		this.nonEditableFile = nonEditableFile;
		this.linkedToFinanceSchemes = linkedToFinanceSchemes;
		this.changeWarrant = changeWarrant;
		this.changeWarrantFileId = changeWarrantFileId;
		this.isWeb = isWeb;
		this.webLink = webLink;
	}
}
