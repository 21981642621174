<template>
	<v-container class="pa-0" fluid>
		<frp-form-card-blocks>
			<frp-form-card-block>
				<template #title>
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4">
							<frp-form-item>
								<frp-form-block-title-loader/>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</template>

				<v-skeleton-loader height="14" max-width="180" type="image" class="mb-6 mt-6 rounded-lg"/>

				<frp-form-card-block-row>
					<frp-form-card-block-col cols="12" sm="6" md="5" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
						<frp-form-item>
							<frp-textarea-loader/>
						</frp-form-item>
					</frp-form-card-block-col>
					<frp-form-card-block-col offset-md="1" cols="12" sm="6" md="5"
											 :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
						<frp-form-item>
							<frp-input-loader/>
						</frp-form-item>
					</frp-form-card-block-col>
				</frp-form-card-block-row>

				<v-skeleton-loader height="14" max-width="180" type="image" class="mb-6 rounded-lg"/>

				<frp-form-card-block-row>
					<frp-form-card-block-col cols="12" sm="6" md="5" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
						<frp-form-item>
							<frp-input-loader/>
						</frp-form-item>
					</frp-form-card-block-col>
					<frp-form-card-block-col offset-md="1" cols="12" sm="6" md="5"
											 :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
						<frp-form-item>
							<frp-input-loader/>
						</frp-form-item>

						<frp-form-item>
							<frp-input-loader/>
						</frp-form-item>
					</frp-form-card-block-col>
				</frp-form-card-block-row>

				<v-skeleton-loader height="14" max-width="180" type="image" class="mb-6 rounded-lg"/>

				<frp-form-card-block-row>
					<frp-form-card-block-col cols="12" sm="6" md="5" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">

						<frp-form-item>
							<frp-textarea-loader/>
						</frp-form-item>
					</frp-form-card-block-col>
					<frp-form-card-block-col offset-md="1" cols="12" sm="6" md="5"
											 :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
						<frp-form-item>
							<frp-textarea-loader/>
						</frp-form-item>
					</frp-form-card-block-col>
				</frp-form-card-block-row>

				<div class="d-flex mb-3" style="gap: 16px">
					<v-spacer></v-spacer>
					<frp-btn-loader width="114" height="40"></frp-btn-loader>
					<frp-btn-loader width="314" height="40"></frp-btn-loader>
				</div>
			</frp-form-card-block>
		</frp-form-card-blocks>
	</v-container>
</template>

<script>
import FrpBackRouteLoader from "@/components/loaders/common/FrpBackRouteLoader";
import FrpTabsLoader from "@/components/loaders/common/FrpTabsLoader";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpSwitchLoader from "Components/loaders/form/FrpSwitchLoader";
import FrpTextareaLoader from "Components/loaders/form/FrpTextareaLoader";
import { RouteNames } from "Router/routes";
import FrpInputLoader from "Components/loaders/form/FrpInputLoader";
import FrpFormBlockTitleLoader from "Components/loaders/form/FrpFormBlockTitleLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";

export default {
	data() {
		return {
			RouteNames
		};
	},
	computed: {
		isGeneralPage() {
			return this.$route.name === this.RouteNames.ACCOUNT_GENERAL;
		},
		isProfilesPage() {
			return this.$route.name === this.RouteNames.ACCOUNT_PROFILES;
		}
	},
	components: {
		FrpCustomTitleLoader,
		FrpTabsLoader,
		FrpBackRouteLoader,
		FrpNestedContentLayout,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpFormCardBlock,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormItem,
		FrpSwitchLoader,
		FrpTextareaLoader,
		FrpInputLoader,
		FrpFormBlockTitleLoader,
		FrpBtnLoader
	}
};
</script>
