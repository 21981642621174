import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";

export const namespace = "bank-account-application-messenger";

export const getterTypes = {};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	fetchMessages: "fetchMessages",
	sendMessage: "sendMessage",
	deleteMessage: "deleteMessage",
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	SET_IS_MESSAGES_FETCHING: "SET_IS_MESSAGES_FETCHING",
	SET_IS_MESSAGE_DELETING: "SET_IS_MESSAGE_DELETING",
	SET_IS_MESSAGE_ITEM_DELETING: "SET_IS_MESSAGE_ITEM_DELETING",
	SET_MESSAGES: "SET_MESSAGES",
	DELETE_MESSAGE_ITEM: "DELETE_MESSAGE_ITEM",
	SET_IS_MESSENGER_FULLSCREEN: "SET_IS_MESSENGER_FULLSCREEN",
	RESET_EDITABLE_MESSAGE_ITEM: "RESET_EDITABLE_MESSAGE_ITEM",
	SET_EDITABLE_MESSAGE_ITEM: "SET_EDITABLE_MESSAGE_ITEM",
	SET_EDITABLE_MESSAGE_ITEM_EMPLOYEE_ID: "SET_EDITABLE_MESSAGE_ITEM_EMPLOYEE_ID",
	RESET_EDITABLE_MESSAGE_ITEM_TO_EMPLOYEES: "RESET_EDITABLE_MESSAGE_ITEM_TO_EMPLOYEES",
	REMOVE_EDITABLE_MESSAGE_ITEM_SELECTED_EMPLOYEE_ID: "REMOVE_EDITABLE_MESSAGE_ITEM_SELECTED_EMPLOYEE_ID",
	SET_EDITABLE_MESSAGE_ITEM_SELECTED_EMPLOYEE_ID: "SET_EDITABLE_MESSAGE_ITEM_SELECTED_EMPLOYEE_ID",
	RESET_EDITABLE_MESSAGE_ITEM_RELATED_MESSAGE_ID: "RESET_EDITABLE_MESSAGE_ITEM_RELATED_MESSAGE_ID",
	SET_EDITABLE_MESSAGE_ITEM_RELATED_MESSAGE_ID: "SET_EDITABLE_MESSAGE_ITEM_RELATED_MESSAGE_ID",
	RESET_EDITABLE_MESSAGE_ITEM_MESSAGE_TEXT: "RESET_EDITABLE_MESSAGE_ITEM_MESSAGE_TEXT",
	SET_EDITABLE_MESSAGE_ITEM_MESSAGE_TEXT: "SET_EDITABLE_MESSAGE_ITEM_MESSAGE_TEXT",
	SET_MESSENGER_MODE: "SET_MESSENGER_MODE"
};

const bankAccountApplicationMessengerTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default bankAccountApplicationMessengerTypes;
