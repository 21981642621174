import { IsString, IsInt, Min, Max, Length, IsArray } from "class-validator";

export default class RequestsRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;

	@IsString()
	@Length(0, 100)
	text: string;

	@IsArray()
	sectionIds: string[];

	@IsArray()
	initiatorIds: string[];

	@IsArray()
	statuses: string[];

	constructor(
		page: number,
		text: string,
		sectionIds: string[],
		initiatorIds: string[],
		statuses: string[]
	)
	{
		this.page = page;
		this.text = text;
		this.sectionIds = sectionIds;
		this.initiatorIds = initiatorIds;
		this.statuses = statuses;
	}
}
