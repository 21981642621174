import IPageState from "@/store/shared/base/types/pageState";
import ApiDepartment from "@/api/bod/types/apiDepartment";
import ApiSection from "@/api/bod/types/apiSection";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import FormState from "@/store/shared/form/models/formState";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import ChangeRequestsListItem from "@/store/bod/modules/requests/types/changeRequestsListItem";
import Document from "@/store/bod/modules/document/types/document";
import ApiChangeWarrant from "@/api/bod/types/apiChangeWarrant";
import ChangeRequest from "@/store/bod/modules/document/types/changeRequest";
import { DocumentPermissions } from "@/store/bod/modules/document/types/documentPermissions";
import { RequestPermissions } from "@/store/bod/modules/document/types/requestPermissions";
import ApiBodFile from "@/api/bod/types/apiBodFile";

export default class DocumentState implements IPageState {
	listing: ListingModel<ChangeRequestsListItem>;
	sorting: SortingModel<String>;
	paging: PagingModel;
	search: SearchModel;
	form: FormState;
	snapshot: object;
	isInitialized: boolean;
	isUsersLoading: boolean;
	isDepartmentsLoading: boolean;
	isSectionsLoading: boolean;
	isFinanceSchemesLoading: boolean;
	isFinanceSchemesLoaded: boolean;
	isAccepting: boolean;
	isRejecting: boolean;
	isAcceptButtonsHidden: boolean;
	isDocumentFormSaving: boolean;
	isRequestFormSaving: boolean;
	departments: ApiDepartment[];
	sections: ApiSection[];
	financeSchemes: ApiFinanceScheme[];
	users: ApiBodUser[];
	document: Document;
	editableFile: FileMeta;
	nonEditableFile: FileMeta;
	requestEditableFile: FileMeta;
	requestNonEditableFile: FileMeta;
	changeWarrantFile: ApiBodFile;
	changeWarrantFileMeta: FileMeta;
	documentChangeWarrantFileMeta: FileMeta;
	history: ChangeRequestsListItem[];
	changeWarrants: ApiChangeWarrant[];
	request: ChangeRequest;
	documentPermissions: DocumentPermissions[];
	requestPermissions: RequestPermissions[];
	declineReason: string;
	savedDocumentIsWeb: boolean;

	constructor(
		listing: ListingModel<ChangeRequestsListItem>,
		sorting: SortingModel<String>,
		paging: PagingModel,
		search: SearchModel,
		form: FormState,
		snapshot: object,
		isInitialized: boolean = false,
		isUsersLoading: boolean = false,
		isDepartmentsLoading: boolean = false,
		isSectionsLoading: boolean = false,
		isFinanceSchemesLoading: boolean = false,
		isFinanceSchemesLoaded: boolean = false,
		isAccepting: boolean = false,
		isRejecting: boolean = false,
		isAcceptButtonsHidden: boolean = false,
		departments: ApiDepartment[] = [],
		sections: ApiSection[] = [],
		financeSchemes: ApiFinanceScheme[] = [],
		users: ApiBodUser[] = [],
		document: Document = new Document(),
		editableFile: FileMeta = new FileMeta(),
		nonEditableFile: FileMeta = new FileMeta(),
		requestEditableFile: FileMeta = new FileMeta(),
		requestNonEditableFile: FileMeta = new FileMeta(),
		changeWarrantFile: ApiBodFile = new ApiBodFile(),
		changeWarrantFileMeta: FileMeta = new FileMeta(),
		documentChangeWarrantFileMeta: FileMeta = new FileMeta(),
		history: ChangeRequestsListItem[] = [],
		changeWarrants: ApiChangeWarrant[] = [],
		request: ChangeRequest = new ChangeRequest(),
		documentPermissions: DocumentPermissions[] = [],
		requestPermissions: RequestPermissions[] = [],
		declineReason: string = "",
		savedDocumentIsWeb: boolean = false
	)
	{
		this.listing = listing;
		this.sorting = sorting;
		this.paging = paging;
		this.search = search;
		this.form = form;
		this.snapshot = snapshot;
		this.isInitialized = isInitialized;
		this.isUsersLoading = isUsersLoading;
		this.isDepartmentsLoading = isDepartmentsLoading;
		this.isSectionsLoading = isSectionsLoading;
		this.isFinanceSchemesLoading = isFinanceSchemesLoading;
		this.isFinanceSchemesLoaded = isFinanceSchemesLoaded;
		this.isAccepting = isAccepting;
		this.isRejecting = isRejecting;
		this.isAcceptButtonsHidden = isAcceptButtonsHidden;
		this.departments = departments;
		this.sections = sections;
		this.financeSchemes = financeSchemes;
		this.users = users;
		this.document = document;
		this.editableFile = editableFile;
		this.nonEditableFile = nonEditableFile;
		this.requestEditableFile = requestEditableFile;
		this.requestNonEditableFile = requestNonEditableFile;
		this.changeWarrantFile = changeWarrantFile;
		this.changeWarrantFileMeta = changeWarrantFileMeta;
		this.documentChangeWarrantFileMeta = documentChangeWarrantFileMeta;
		this.history = history;
		this.changeWarrants = changeWarrants;
		this.request = request;
		this.documentPermissions = documentPermissions;
		this.requestPermissions = requestPermissions;
		this.declineReason = declineReason;
		this.savedDocumentIsWeb = savedDocumentIsWeb;
	}
}
