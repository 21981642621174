<template>
	<div>
		<div class="d-flex align-center">
			<span v-if="acceptorTitle" class="mr-4">{{ acceptorTitle }}</span>
			<span class="d-flex align-center">
			<frp-icon class="mr-2" :src="icon" :color="color"></frp-icon>
			<span :style="{ color }">{{ text }}</span>
		</span>
		</div>
		<frp-label v-if="date" class="mt-1" :label="formatDate(date, dateFormat)"></frp-label>
		<div v-if="declineReason">
			<frp-label class="mt-4" :label="$t('details.titles.declineReason')"></frp-label>
			<span class="text-body-2" :style="{ color: this.colors.secondary.lighten2 }">{{ declineReason }}</span>
		</div>
	</div>
</template>

<script>
import FrpLabel from "Components/fields/FrpLabel";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import { formatDate } from "Utils/dates";
import { dateFormat } from "Utils/formats";

export default {
	components: { FrpLabel, FrpIcon },
	mixins: [colorsMixin],
	props: {
		acceptorTitle: String,
		declineReason: {
			type: String,
			required: true
		},
		declinedAt: {
			type: [Number, String],
			required: true
		},
		acceptedAt: {
			type: [Number, String],
			required: true
		}
	},
	data() {
		return {
			formatDate,
			dateFormat
		};
	},
	computed: {
		color() {
			if(this.declinedAt)
				return this.colors.secondary.lighten2;
			else if(this.acceptedAt)
				return this.colors.green.lighten2;
			else
				return this.colors.orange.base;
		},
		text() {
			if(this.declinedAt)
				return this.$t("common.declined");
			else if(this.acceptedAt)
				return this.$t("common.accepted");
			else
				return this.$t("common.awaitingAccept");
		},
		icon() {
			if(this.declinedAt)
				return "ico_cancel-circle";
			else if(this.acceptedAt)
				return "ico_check-circle";
			else
				return "ico_alarm";
		},
		date() {
			return this.declinedAt || this.acceptedAt;
		}
	}
};
</script>

<style scoped>
</style>
