import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "employee-profile";

export const getterTypes = {
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	fetch: "fetch"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_EMPLOYEE: "SET_EMPLOYEE"
};

const employeeProfileTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default employeeProfileTypes;
