<template>
	<div class="mb-6">
		<vue-dropzone :name="type"
					  id="dropzone"
					  v-if="!file"
					  :options="dropzoneOptions"
					  :useCustomSlot="true"
					  @vdropzone-file-added="$emit('file:add', $event)"
					  class="white rounded-lg pa-2 d-flex align-center flex-wrap justify-center frp-dropzone">
			<div class="d-flex align-center">
				<frp-icon src="ico_upload" :color="colors.grey.base" class="mr-2 mb-1"></frp-icon>
				<span class="grey--text">{{ title }}</span>
			</div>
			<div class="text-overline grey--text mt-1">{{ $t("fields.dropzone.description.maxFileWeight", { maxSize }) }}</div>
		</vue-dropzone>
		<div v-else>
			<v-row class="mt-1">
				<v-col class="d-flex align-start flex-column py-0">
					<div class="d-flex align-center">
						<frp-file class="text-break" :name="filename" @delete="$emit('file:delete')"></frp-file>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import { i18n } from "@/plugins";
import VueDropzone from "vue2-dropzone";
import colorsMixin from "Mixins/colorsMixin";
import FrpIcon from "Components/icon/FrpIcon";
import FrpTextField from "Components/fields/FrpTextField";
import FrpFile from "Components/common/FrpFile";

const DEFAULT_URL = "http://localhost";

export default {
	mixins: [colorsMixin],
	props: {
		type: {
			type: String,
			required: true
		},
		maxSize: [Number, String],
		formats: Array,
		file: [File, Object],
		title: {
			type: String,
			default: i18n.t("fields.dropzone.title.uploadFile")
		}
	},
	data() {
		return {
			dropzoneOptions: {
				autoProcessQueue: false,
				url: DEFAULT_URL,
				maxFilesize: this.maxSize,
				acceptedFiles: "",
				maxFiles: 1
			}
		};
	},
	computed: {
		filename() {
			return this.file.name;
		}
	},
	methods: {
		setAcceptedFiles() {
			const res = [];
			this.formats.forEach(x => {
				switch (x) {
					case "bd":
						res.push(".bd*");
						break;
				}
			});
			this.dropzoneOptions.acceptedFiles = res.join(",");
		}
	},
	watch: {
		formats: {
			handler(formats) {
				this.setAcceptedFiles(formats);
			},
			immediate: true
		}
	},
	components: {
		VueDropzone,
		FrpIcon,
		FrpTextField,
		FrpFile
	}
};
</script>
