import { registerAlertService } from "@/store/modules/alerts/services/alertService";
import Vue from "vue";
import Vuex, { ActionTree, MutationTree } from "vuex";
import alertsModule from "@/store/modules/alerts";
import documentsModule from "@/store/bod/modules/documents";
import documentModule from "@/store/bod/modules/document";
import requestsModule from "@/store/bod/modules/requests";
import userModule from "@/store/bod/modules/user";
import RootState from "@/store/bod/types/rootState";
import { PageModeType } from "@/store/bod/types/pageModeType";
import { mutationTypes } from "@/store/bod/types";

Vue.use(Vuex);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new RootState(PageModeType.OK);
	}
}

const state = (new DefaultStateBuilder()).build();

const actions = <ActionTree<RootState, any>>{};

const mutations = <MutationTree<RootState>>{
	[mutationTypes.SET_IS_APP_LOADING](state, value) {
		state.isAppLoading = value;
	},
	[mutationTypes.SET_PAGE_MODE](state, value) {
		state.pageMode = value;
	},
	[mutationTypes.RESET_PAGE_MODE](state) {
		state.pageMode = PageModeType.OK;
	},
	[mutationTypes.SET_PAGE_MODE_NOT_FOUND](state) {
		state.pageMode = PageModeType.PAGE_NOT_FOUND;
	},
	[mutationTypes.SET_PAGE_MODE_ACCESS_FORBIDDEN](state) {
		state.pageMode = PageModeType.ACCESS_DENIED;
	}
};

const store = new Vuex.Store({
	state,
	mutations,
	actions,
	modules: {
		[userModule.namespace]: {
			...userModule
		},
		[documentsModule.namespace]: {
			...documentsModule
		},
		[documentModule.namespace]: {
			...documentModule
		},
		[requestsModule.namespace]: {
			...requestsModule
		},
		[alertsModule.namespace]: {
			...alertsModule
		}
	}
});

const registerSubscribers = (store: any) => {
	documentsModule.initializeSubscribersManager(store);
	requestsModule.initializeSubscribersManager(store);
};
registerAlertService(store);
registerSubscribers(store);

export default store;
