import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { ApiBarUser } from "@/api/bar/types/apiBarUser";
import { ApiBankAccountInfoPersisted } from "@/api/bar/types/bankAccountInfo/apiBankAccountInfoPersisted";
import { ApiGetBankAccountsParameters } from "@/api/bar/types/apiGetBankAccountsParameters";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { ApiBankAccountItems } from "@/api/bar/types/apiBankAccountItems";
import { ApiBankAccountApplicationItems } from "@/api/bar/types/apiBankAccountApplicaionItems";
import { ApiGetBankAccountApplicationsParameters } from "@/api/bar/types/apiGetBankAccountApplicationsParameters";
import { ApiBankAccountApplicationInfoPersisted } from "@/api/bar/types/bankAccountApplicationInfo/apiBankAccountApplicationInfoPersisted";
import { ApiBankAccountApplicationMessagePersisted } from "@/api/bar/types/apiBankAccountApplicationMessagePersisted";
import { ApiBankAccountApplicationMessage } from "@/api/bar/types/apiBankAccountApplicationMessage";
import { ApiDocumentResponse } from "@/api/bar/types/apiDocumentResponse";
import { ApiApplication } from "@/api/bar/types/apiApplication";
import { ApiStatusHistoryItem } from "@/api/bar/types/apiStatusHistoryItem";
import { ApiBankAccountApplicationLegalDepartmentInfo } from "@/api/bar/types/apiBankAccountApplicationLegalDepartmentInfo";
import { ApiBankAccountApplicationAccount } from "@/api/bar/types/apiBankAccountApplicationAccount";
import { ApiChangeBankAccountApplicationInfoRequest } from "@/api/bar/types/bankAccountApplicationInfo/apiChangeBankAccountApplicationInfoRequest";
import { ApiChangeBankAccountInfoRequest } from "@/api/bar/types/bankAccountInfo/apiChangeBankAccountInfoRequest";
import { ApiBankAccountTypeHistory } from "@/api/bar/types/apiBankAccountTypeHistory";

export class BarController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getPermissions = async (): Promise<string[]> => {
		return await this.client.get<string[]>(urls.bar.permissions.get);
	};
	
	getCurrentUser = async (): Promise<ApiBarUser> => {
		return await this.client.get<ApiBarUser>(urls.bar.user.getCurrentUser);
	};
	
	getBankAccountApplicationsItems = async (parameters: ApiGetBankAccountApplicationsParameters): Promise<ApiBankAccountApplicationItems> => {
		const url = prepareUrl(urls.bar.bankAccountApplications.getBankAccountApplications, parameters);
		return await this.client.get<ApiBankAccountApplicationItems>(url);
	};
	getBankAccountApplicationStatusHistoryItems = async (bankAccountApplicationId: string): Promise<ApiStatusHistoryItem[]> => {
		const url = urls.bar.bankAccountApplications.getBankAccountApplicationStatusHistoryItems.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<ApiStatusHistoryItem[]>(url);
	};
	
	createBankAccountApplication = async (bankAccountApplicationInfo: ApiChangeBankAccountApplicationInfoRequest): Promise<ApiBankAccountApplicationInfoPersisted> => {
		return await this.client.post<ApiBankAccountApplicationInfoPersisted>(urls.bar.bankAccountApplications.createBankAccountApplication, bankAccountApplicationInfo);
	};
	
	updateBankAccountApplication = async (bankAccountApplicationId: string, bankAccountApplicationInfo: ApiChangeBankAccountApplicationInfoRequest): Promise<void> => {
		const url = urls.bar.bankAccountApplications.updateBankAccountApplication.replace(urlTemplateParts.id, bankAccountApplicationId);
		await this.client.put(url, bankAccountApplicationInfo);
	};
	
	generateInformationalLetter = async (bankAccountApplicationId: string): Promise<Blob> => {
		const url = urls.bar.bankAccountApplications.generateInformationalLetter.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<Blob>(url, { responseType: "blob" }, true);
	};
	
	getInformationalLetterDocumentFileInfo = async (bankAccountApplicationId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.getFileInfo.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<ApiDocumentResponse>(url);
	};
	
	setInformationalLetterDocumentFile = async (bankAccountApplicationId: string, tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.setFile.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post<ApiDocumentResponse>(url, { tempFileId });
	};
	
	setAcceptedStatusForInformationalLetterDocument = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.setStatus.accepted.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(url, {});
	};
	
	setNotRequiredStatusForInformationalLetterDocument = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.setStatus.notRequired.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(url, {});
	};
	
	setDeclinedStatusForInformationalLetterDocument = async (bankAccountApplicationId: string, explanation: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.setStatus.declined.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(prepareUrl(url, { explanation }), {});
	};
	
	getDirectWithdrawalAgreementDocumentFileInfo = async (bankAccountApplicationId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.getFileInfo.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<ApiDocumentResponse>(url);
	};
	
	setDirectWithdrawalAgreementDocumentFile = async (bankAccountApplicationId: string, tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.setFile.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post<ApiDocumentResponse>(url, { tempFileId });
	};
	
	setAcceptedStatusForDirectWithdrawalAgreementDocument = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.setStatus.accepted.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(url, {});
	};
	
	setNotRequiredStatusForDirectWithdrawalAgreementDocument = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.setStatus.notRequired.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(url, {});
	};
	
	setDeclinedStatusForDirectWithdrawalAgreementDocument = async (bankAccountApplicationId: string, explanation: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.setStatus.declined.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(prepareUrl(url, { explanation }), {});
	};
	
	getPaymentAcceptAgreementDocumentFileInfo = async (bankAccountApplicationId: string, accountId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.getFileInfo.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, accountId);
		return await this.client.get<ApiDocumentResponse>(url);
	};
	
	setPaymentAcceptAgreementDocumentFile = async (bankAccountApplicationId: string, accountId: string, tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.setFile.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, accountId);
		return await this.client.post<ApiDocumentResponse>(url, { tempFileId });
	};
	
	setAcceptedStatusForPaymentAcceptAgreementDocument = async (bankAccountApplicationId: string, accountId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.setStatus.accepted.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, accountId);
		return await this.client.put(url, {});
	};
	
	setNotRequiredStatusForPaymentAcceptAgreementDocument = async (bankAccountApplicationId: string, accountId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.setStatus.notRequired.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, accountId);
		return await this.client.put(url, {});
	};
	
	setDeclinedStatusForPaymentAcceptAgreementDocument = async (bankAccountApplicationId: string, accountId: string, explanation: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.setStatus.declined.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, accountId);
		return await this.client.put(prepareUrl(url, { explanation }), {});
	};
	
	getBankAccountApplicationLegalDepartmentInfo = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.getLegalDepartmentInfo.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<ApiBankAccountApplicationLegalDepartmentInfo>(url);
	};
	
	getBankAccountApplicationLegalDepartmentRequestDocuments = async (bankAccountApplicationId: string): Promise<ApiDocumentResponse[]> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.getDocuments.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<ApiDocumentResponse[]>(url, {});
	};
	
	sendBankAccountApplicationLegalDepartmentRequest = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.sendLegalDepartmentRequest.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post(url, {});
	};
	
	setBankAccountApplicationLegalDepartmentResponsiblePerson = async (bankAccountApplicationId: string, legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<void> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.setResponsiblePerson.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post(url, legalDepartmentInfo);
	};
	
	acceptBankAccountApplicationLegalDepartment = async (bankAccountApplicationId: string, legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.accept.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post(url, legalDepartmentInfo);
	};
	
	declineBankAccountApplicationLegalDepartment = async (bankAccountApplicationId: string, legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.decline.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post(url, legalDepartmentInfo);
	};
	
	resetStatusesForBankAccountApplicationLegalDepartment = async (bankAccountApplicationId: string, legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.resetStatuses.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post(url, legalDepartmentInfo);
	};
	
	uploadLegalDepartmentDocumentFileInfo = async (bankAccountApplicationId: string, tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.sendDocumentFileInfo.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post(url, { tempFileId });
	};
	
	getMessages = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationMessagePersisted[]> => {
		const url = urls.bar.bankAccountApplications.messenger.getMessages.replace(urlTemplateParts.id, bankAccountApplicationId);
		
		return await this.client.get<ApiBankAccountApplicationMessagePersisted[]>(url);
	};
	
	sendMessage = async (bankAccountApplicationId: string, message: ApiBankAccountApplicationMessage): Promise<ApiBankAccountApplicationMessagePersisted> => {
		const url = urls.bar.bankAccountApplications.messenger.sendMessage.replace(urlTemplateParts.id, bankAccountApplicationId);
		
		return await this.client.post<ApiBankAccountApplicationMessagePersisted>(url, message);
	};
	
	deleteMessage = async (bankAccountApplicationId: string, messageId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.messenger.deleteMessage.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, messageId);
		
		await this.client.delete(url);
	};
	
	deleteBankAccountApplicationDocument = async (bankAccountApplicationId: string, documentId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.deleteBankAccountApplicationDocument.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, documentId);
		return await this.client.delete(url);
	};
	
	getBankAccountItems = async (parameters: ApiGetBankAccountsParameters): Promise<ApiBankAccountItems> => {
		return await this.client.get<ApiBankAccountItems>(prepareUrl(urls.bar.bankAccounts.getBankAccounts, parameters));
	};
	
	getBankAccountApplication = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationInfoPersisted> => {
		const url = urls.bar.bankAccountApplications.getBankAccountApplication.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<ApiBankAccountApplicationInfoPersisted>(url);
	};
	
	getBankAccountApplicationAccounts = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationAccount[]> => {
		const url = urls.bar.bankAccountApplications.getBankAccountApplicationAccounts.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<ApiBankAccountApplicationAccount[]>(url);
	};
	
	getBankAccount = async (bankAccountId: string): Promise<ApiBankAccountInfoPersisted> => {
		const url = urls.bar.bankAccounts.getBankAccount.replace(urlTemplateParts.id, bankAccountId);
		return await this.client.get<ApiBankAccountInfoPersisted>(url);
	};
	
	getApplicationsForBankAccount = async (accountNumber: string): Promise<ApiApplication[]> => {
		const url = urls.bar.bankAccounts.getBankAccountApplications.replace(urlTemplateParts.id, accountNumber);
		return await this.client.get<ApiApplication[]>(url);
	};
	
	getBankAccountAgreementTypeHistory = async (id: string, loanApplicationNumber: string): Promise<ApiBankAccountTypeHistory[]> => {
		const url = urls.bar.bankAccounts.getBankAccountAgreementTypeHistory.replace(urlTemplateParts.id, id).replace(urlTemplateParts.subId, loanApplicationNumber);
		return await this.client.get<ApiBankAccountTypeHistory[]>(url);
	};
	
	createBankAccount = async (bankAccountInfo: ApiChangeBankAccountInfoRequest): Promise<ApiBankAccountInfoPersisted | any> => {
		return await this.client.post<ApiBankAccountInfoPersisted>(urls.bar.bankAccounts.createBankAccount, bankAccountInfo);
	};
	
	updateBankAccount = async (bankAccountId: string, bankAccountInfo: ApiChangeBankAccountInfoRequest): Promise<ApiBankAccountInfoPersisted> => {
		const url = urls.bar.bankAccounts.updateBankAccount.replace(urlTemplateParts.id, bankAccountId);
		return await this.client.put<ApiBankAccountInfoPersisted>(url, bankAccountInfo);
	};
	
	checkBankAccount = async (parameters: { accountNumber: string, loanApplicationNumber?: string }): Promise<boolean> => {
		const url = prepareUrl(urls.bar.bankAccounts.getBankAccounts, parameters);
		const { items } = await this.client.get<ApiBankAccountItems>(url, {});
		
		return Boolean(items.length);
	};
	
	checkBankAccountPayments = async (accountNumber: string): Promise<{ hasPayments: boolean }> => {
		const url = urls.bar.bankAccounts.checkBankAccountPayments.replace(urlTemplateParts.id, accountNumber);
		return await this.client.get<{ hasPayments: boolean }>(url, {});
	};
}
